import Vue from "vue";
import App from "./App.vue";
import router from "./plugins/router";
import store from "./plugins/store";
import Utils from "./plugins/utils";
import axios from "axios";
// import jquery from "jquery";
// import popper from "popper.js";
import "bootstrap";
import _ from "lodash";
import moment from "moment";
import Validation from "./plugins/validation";
import jquery from "jquery";
// import Popper from "popper.js";
import "./plugins/components";
import "./plugins/filters";

import VueI18n from "vue-i18n";
import messages from "./lang/translations";
import dateTimeFormats from "./lang/dateTimeFormats";
import numberFormats from "./lang/numberFormats";
Vue.use(VueI18n);
let locale = store.state.lang;
const i18n = new VueI18n({
  fallbackLocale: "en",
  locale: locale,
  messages,
  dateTimeFormats,
  numberFormats
});

import * as VueGoogleMaps from "vue2-google-maps";
const VueScrollTo = require("vue-scrollto");

var VueCookie = require("vue-cookie");
Vue.use(VueCookie);

const config = {
  server_url: process.env.VUE_APP_BACKEND_URL,
  config_url: process.env.VUE_APP_CONFIG_URL,
  webapp_url: process.env.VUE_APP_WEBAPP_URL, // attention à rajouter dans .env.xxx
  web_url: process.env.VUE_APP_WEB_URL,
  server_url_v1: process.env.VUE_APP_BACKEND_URL_V1,
  web_url_support: process.env.VUE_APP_WEB_URL_SUPPORT,
  web_url_subscription: process.env.VUE_APP_WEB_URL_SUBSCRIPTION,
  web_url_new_subscription: process.env.VUE_APP_WEB_URL_NEW_SUBSCRIPTION,
  web_url_account: process.env.VUE_APP_WEB_URL_ACCOUNT,
  web_v1: process.env.VUE_APP_WEB_V1,
  google_key: process.env.VUE_APP_GOOGLE_KEY
};
Object.defineProperty(Vue.prototype, "$config", {
  value: config
});

// import VueSocketIO from "vue-socket.io";
// import SocketIO from "socket.io-client";
// Vue.use(
//   new VueSocketIO({
//     debug: true,
//     connection: SocketIO(config.server_url, {}),
//     vuex: {
//       store,
//       actionPrefix: "SOCKET_",
//       mutationPrefix: "SOCKET_"
//     },
//     options: {} //{ path: "/my-app/" } //Optional options
//   })
// );

Vue.use(VueScrollTo);
Vue.use(VueGoogleMaps, {
  load: {
    key: config.keyGoogleMap
    //libraries: "places" // necessary for places input
  },
  installComponents: true
});

Vue.config.productionTip = false;
// Popper.Defaults.modifiers.computeStyle.gpuAcceleration = false;

moment.locale("fr");

Object.defineProperty(Vue.prototype, "$_", { value: _ });
Object.defineProperty(Vue.prototype, "$$", { value: jquery });
Object.defineProperty(Vue.prototype, "$moment", { value: moment });
Object.defineProperty(Vue.prototype, "$Utils", { value: Utils });
Object.defineProperty(Vue.prototype, "$Validation", { value: Validation });
// Object.defineProperty(Vue.prototype, "$Popper", { value: Popper });

Object.defineProperty(Vue.prototype, "$axios", { value: axios });
axios.defaults.headers.common["x-auth-accesstoken"] = store.state.accesstoken;
axios.interceptors.request.use(
  function (config2) {
    store.commit("set_mainisloading", true);
    return config2;
  },
  function (error) {
    return Promise.reject(error);
  }
);
axios.interceptors.response.use(
  function (response) {
    store.commit("set_mainisloading", false);
    return response;
  },
  function (error) {
    if (axios.isCancel(error)) {
      console.warn("Request canceled", error.message);
    } else {
      console.error("response error", error);
      return Promise.reject(error);
    }
  }
);

new Vue({
  data: { loading: false },
  router,
  store,
  i18n,
  render: h => h(App),
  methods: {
    findFirstComponent(name) {
      function f(comp) {
        if (comp.$options.name == name) return comp;
        for (let iChild = 0; iChild < comp.$children.length; iChild++) {
          const child = comp.$children[iChild];
          let c = f(child);
          if (c) return c;
        }
      }
      return f(this);
    }
  }
}).$mount("#app");
