<template>
  <div>
    <section>
      <h1 class="mt-3">CONDITIONS GENERALES D'UTILISATION ET DE VENTE</h1>
      <div class="text-center mt-2 mb-5">
        <h4>Nos CGU sont disponibles ici :</h4>
        <a
          class="btn btn-primary m-1"
          target="_blank"
          href="/files/CGU-Emotivi-20201209.pdf"
          >Conditions générales d'utilisation</a
        >
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "cgu",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {}
};
</script>

<style lang="scss" scoped></style>
