<template>
  <div
    class="modal show"
    :style="'display:block;background-color:#3333337a;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      style="max-width: 75%"
      class="modal-dialog modal-dialog-scrollable"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h1>Les options</h1>
          </div>
        </div>
        <div class="modal-body">
          <section class="bg-gray-light">
            <div class="container p-0 pt-3">
              <div class="row mb-5">
                <div
                  class="col-xl-10 col-sm-10 offset-sm-1"
                  style="background-color: #6aceb1"
                >
                  <div
                    class="row justify-content-center align-items-center mt-3 ml-1 mr-1 mb-3"
                  >
                    <div
                      class="col-xl-1 col-lg-1 col-md-1 col-sm-2 text-center"
                    >
                      <icon name="pen" scale="2.5" fill="white"></icon>
                    </div>
                    <div
                      class="col-xl-11 col-lg-11 col-md-11 col-sm-10 text-center"
                    >
                      <h3 class="m-0" style="color: white">
                        Les options de votre kit Emotivi
                        <span class="text-info">{{ webappLogin }}</span>
                      </h3>
                    </div>
                  </div>
                  <div
                    class="row justify-content-center align-items-center mt-3 ml-1 mr-1 mb-3"
                  >
                    <div class="col-12">
                      <div
                        v-if="dialogErrTxt && !dialogErr"
                        class="alert alert-danger"
                      >
                        {{ dialogErrTxt }}
                      </div>
                      <div v-if="dialogOkTxt" class="alert alert-success">
                        {{ dialogOkTxt }}
                      </div>
                      <!-- OPTIONS -->
                      <div class="d-flex flex-column">
                        <h5>Décrochage automatique</h5>

                        <div class="alert alert-info mt-2">
                          <m-form-checkbox
                            label=" Décrochage automatique activé pour les membres de la
                            famille"
                            v-model="hangupOther"
                            :name="$Utils.randomstring('hangupOther')"
                            class="mr-3"
                            :setstyle="'white-space: normal !important'"
                          ></m-form-checkbox>
                        </div>
                        <hr />
                      </div>
                      <div class="d-flex flex-column">
                        <div
                          v-if="
                            isBoxCompatible('options_bistri_display_preview')
                          "
                        >
                          <hr />
                          <h5>Options durant l'appel vidéo</h5>
                          <m-form-checkbox
                            label="Cliquez ici pour activer l'aperçu de la caméra sur le téléviseur de votre ou parent lors d'un appel vidéo"
                            v-model="screenDisplayPreview"
                            :name="
                              $Utils.randomstring(
                                'options_bistri_display_preview'
                              )
                            "
                            class="mr-3"
                            :setstyle="'white-space: normal !important'"
                          ></m-form-checkbox>
                          <div
                            v-show="screenDisplayPreview"
                            class="alert alert-info"
                          >
                            <label for="screenPreview" class="mb-2"
                              >Sélectionner l'emplacement de l'aperçu :</label
                            >
                            <div id="screenpreview" class="screenPreview">
                              <icon
                                class="screenPreviewTv"
                                width="100%"
                                height="100%"
                                name="tv"
                                fill="lightgray"
                              ></icon>
                              <div class="screenPreviewChild mt-3">
                                <m-form-checkbox
                                  v-model="screenPreviewTopLeft"
                                  :name="$Utils.randomstring('topleft')"
                                  class="mr-3"
                                ></m-form-checkbox>
                                <m-form-checkbox
                                  v-model="screenPreviewTopRight"
                                  :name="$Utils.randomstring('topright')"
                                  class="ml-3"
                                ></m-form-checkbox>
                              </div>
                              <div class="screenPreviewChild">
                                <m-form-checkbox
                                  v-model="screenPreviewBottomLeft"
                                  :name="$Utils.randomstring('bottomleft')"
                                  class="mr-3"
                                ></m-form-checkbox>
                                <m-form-checkbox
                                  v-model="screenPreviewBottomRight"
                                  :name="$Utils.randomstring('bottomright')"
                                  class="ml-3"
                                ></m-form-checkbox>
                              </div>
                            </div>
                            <div class="mt-2">
                              <m-form-select
                                label="Taille de l'aperçu"
                                label-position="top"
                                :name="$Utils.randomstring('screenPreviewSize')"
                                v-model="screenPreviewSize"
                                :items="screenPreviewSizes"
                              ></m-form-select>
                            </div>
                          </div>
                        </div>
                        <div class="d-flex flex-column">
                          <h5>Gestion des notifications</h5>

                          <div class="alert alert-info mt-2">
                            <p class="section-subtitle">Pour les messages</p>
                            <m-form-checkbox
                              label="Désactiver la notification lumineuse"
                              v-model="ledsDisableDuration"
                              :name="$Utils.randomstring('ledsDisableDuration')"
                              class="mr-3"
                              :setstyle="'white-space: normal !important'"
                            ></m-form-checkbox>
                            <m-form-hour
                              class="mx-1"
                              style="overflow-wrap: normal"
                              label="Durée maximale de la notification lumineuse (heures:minutes)"
                              :name="$Utils.randomstring('ledsDuration')"
                              v-model="ledsDurationFormatted"
                            ></m-form-hour>
                          </div>
                          <div
                            v-if="
                              isBoxCompatible('options_leds_disabled') &&
                                isBoxCompatible('options_value_ringing_number')
                            "
                            class="alert alert-info mt-2"
                          >
                            <p class="section-subtitle">Pour les appels</p>
                            <m-form-checkbox
                              label="Désactiver la notification lumineuse"
                              v-model="ledsDisabled"
                              :name="$Utils.randomstring('ledsDisabled')"
                              class="mr-3"
                              :setstyle="'white-space: normal !important'"
                            ></m-form-checkbox>
                            <m-form-checkbox
                              label="Désactiver la sonnerie"
                              v-model="ringingDisable"
                              :name="$Utils.randomstring('ringingDisable')"
                              class="mr-3"
                              :setstyle="'white-space: normal !important'"
                            ></m-form-checkbox>
                            <m-form-text
                              label="Nombre de sonnerie"
                              type="number"
                              v-model="ringingNumber"
                              :name="$Utils.randomstring('ringingNumber')"
                              class="mr-3"
                            ></m-form-text>
                          </div>
                        </div>
                        <!-- disable button functionality access from account for now -->
                        <div
                          class="d-flex flex-column"
                          v-if="
                            isBoxCompatible(
                              'options_value_button_functionality'
                            ) && this.enableButtonFunctionality
                          "
                        >
                          <h5>Gestion du bouton</h5>
                          <p>
                            Lors d'un clic simple sur le bouton, vous pouvez
                            choisir ici l'action qui sera réalisée.
                          </p>
                          <div class="col-12">
                            <div v-if="dialogErr" class="alert alert-danger">
                              {{ dialogErrTxt }}
                            </div>
                          </div>
                          <div class="alert alert-info mt-2">
                            <p class="section-subtitle">
                              Comportement par défaut
                            </p>
                            <m-form-combobox
                              class="flex-grow-1 basis-50"
                              label="Comportement par défaut"
                              label-position="top"
                              :name="$Utils.randomstring('bf_id')"
                              v-model="row_bf"
                              :autoSelectOnFocus="true"
                              :store-url="
                                $config.server_url +
                                  '/web/2.0/buttonFunctionalities/' +
                                  this.row_en.en_id
                              "
                              :store-params="{ bf_id: row_bf.bf_id }"
                              item-value="bf_name"
                              item-text="bf_name"
                            ></m-form-combobox>
                            <div class="col-12" v-if="row_bf.pc_id != 'none'">
                              <label for="">Message qui sera envoyé:</label>
                              <div class="alert alert-success col-12">
                                <span style="word-break: break-word">{{
                                  decryptMessage(row_bf.bf_message)
                                }}</span>
                              </div>
                            </div>
                            <m-form-text
                              v-if="row_bf.pc_id != 'none'"
                              :label="label"
                              v-model="row_bfd.communication"
                              type="text"
                              :placeholder="placeholder"
                              :name="$Utils.randomstring('communication')"
                            ></m-form-text>
                          </div>
                        </div>
                      </div>

                      <!-- OPTIONS_END -->
                      <!-- <div class="d-flex">
												<button
													type="button"
													class="btn btn-primary mb-2"
													@click="save()"
												>
													Enregistrer
												</button>
												<button
													type="button"
													class="btn btn-secondary mb-2 m-auto"
													@click="close"
												>
													Fermer
												</button>
											</div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="modal-footer d-flex flex-row justify-content-between">
          <div></div>
          <div>
            <button type="button" class="btn btn-secondary" @click="close">
              FERMER
            </button>
            <button type="button" class="btn btn-primary ml-2" @click="save()">
              ENREGISTRER
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { phone } from "phone";

export default {
  name: "boxoptions",
  props: {
    row_en: {
      default: function() {
        return {};
      },
      type: Object
    }
  },
  computed: {
    webappLogin() {
      return this.row_en.en_webapp_login;
    },
    boxe() {
      return this.row_en.bo_id;
    },
    ledsDurationFormatted: {
      get() {
        return this.$moment.utc(this.ledsDuration * 1000).format("HH:mm");
      },
      set(value) {
        this.boxe.bo_config_options.options_value_leds_duration = this.$moment(
          value,
          "HH:mm:ss"
        ).diff(this.$moment().startOf("day"), "seconds");
      }
    },
    screenDisplayPreview: {
      get() {
        return this.boxe.bo_config_options &&
          this.boxe.bo_config_options.hasOwnProperty(
            "options_bistri_display_preview"
          )
          ? this.boxe.bo_config_options.options_bistri_display_preview
          : false;
      },
      set(value) {
        if (this.boxe.bo_config_options)
          this.boxe.bo_config_options.options_bistri_display_preview = value;
      }
    },
    screenPreviewTopLeft: {
      get() {
        if (
          this.boxe.bo_config_options.options_value_bistri_preview_valign ===
            "top" &&
          this.boxe.bo_config_options.options_value_bistri_preview_halign ===
            "left"
        )
          return true;
        else return false;
      },
      set(value) {
        if (this.boxe.bo_config_options && value) {
          this.boxe.bo_config_options.options_value_bistri_preview_valign =
            "top";
          this.boxe.bo_config_options.options_value_bistri_preview_halign =
            "left";
        }
      }
    },
    screenPreviewTopRight: {
      get() {
        if (
          this.boxe.bo_config_options.options_value_bistri_preview_valign ===
            "top" &&
          this.boxe.bo_config_options.options_value_bistri_preview_halign ===
            "right"
        )
          return true;
        else return false;
      },
      set(value) {
        if (this.boxe.bo_config_options && value) {
          this.boxe.bo_config_options.options_value_bistri_preview_valign =
            "top";
          this.boxe.bo_config_options.options_value_bistri_preview_halign =
            "right";
        }
      }
    },
    screenPreviewBottomLeft: {
      get() {
        if (
          this.boxe.bo_config_options.options_value_bistri_preview_valign ===
            "bottom" &&
          this.boxe.bo_config_options.options_value_bistri_preview_halign ===
            "left"
        )
          return true;
        else return false;
      },
      set(value) {
        if (this.boxe.bo_config_options && value) {
          this.boxe.bo_config_options.options_value_bistri_preview_valign =
            "bottom";
          this.boxe.bo_config_options.options_value_bistri_preview_halign =
            "left";
        }
      }
    },
    screenPreviewBottomRight: {
      get() {
        if (
          this.boxe.bo_config_options.options_value_bistri_preview_valign ===
            "bottom" &&
          this.boxe.bo_config_options.options_value_bistri_preview_halign ===
            "right"
        )
          return true;
        else return false;
      },
      set(value) {
        if (this.boxe.bo_config_options && value) {
          this.boxe.bo_config_options.options_value_bistri_preview_valign =
            "bottom";
          this.boxe.bo_config_options.options_value_bistri_preview_halign =
            "right";
        }
      }
    },
    screenPreviewSize: {
      get() {
        return this.boxe.bo_config_options &&
          this.boxe.bo_config_options.options_value_bistri_preview_size
          ? this.boxe.bo_config_options.options_value_bistri_preview_size
          : 0.25;
      },
      set(value) {
        if (
          this.boxe.bo_config_options &&
          this.boxe.bo_config_options.hasOwnProperty(
            "options_value_bistri_preview_size"
          )
        ) {
          this.boxe.bo_config_options.options_value_bistri_preview_size = value;
        }
      }
    },
    ledsDuration() {
      return this.boxe.bo_config_options &&
        (this.boxe.bo_config_options.options_value_leds_duration ||
          this.boxe.bo_config_options.options_value_leds_duration === 0)
        ? this.boxe.bo_config_options.options_value_leds_duration
        : 7200;
    },
    ringingNumber: {
      get() {
        return this.boxe.bo_config_options &&
          (this.boxe.bo_config_options.options_value_ringing_number ||
            this.boxe.bo_config_options.options_value_ringing_number === 0)
          ? this.boxe.bo_config_options.options_value_ringing_number
          : 5;
      },
      set(value) {
        this.boxe.bo_config_options.options_value_ringing_number = value;
      }
    },
    ledsDisabled: {
      get() {
        return this.boxe.bo_config_options &&
          this.boxe.bo_config_options.options_leds_disabled
          ? this.boxe.bo_config_options.options_leds_disabled
          : false;
      },
      set(value) {
        this.boxe.bo_config_options.options_leds_disabled = value;
      }
    },
    hangupOther: {
      get() {
        return this.boxe.bo_autoresponse_forall;
      },
      set(value) {
        this.boxe.bo_autoresponse_forall = value;
      }
    }
  },
  watch: {
    ledsDisableDuration(value) {
      if (value) {
        this.boxe.bo_config_options.options_value_leds_duration = 0;
      } else if (this.boxe.bo_config_options.options_value_leds_duration === 0)
        this.boxe.bo_config_options.options_value_leds_duration = 7200;
    },
    ledsDuration(value) {
      if (!value) {
        this.ledsDisableDuration = true;
      } else this.ledsDisableDuration = false;
    },
    ringingDisable(value) {
      if (value) {
        this.boxe.bo_config_options.options_value_ringing_number = 0;
      } else if (this.boxe.bo_config_options.options_value_ringing_number === 0)
        this.boxe.bo_config_options.options_value_ringing_number = 5;
    },
    ringingNumber(value) {
      if (!value) {
        this.ringingDisable = true;
      } else this.ringingDisable = false;
    },
    row_bf(value) {
      if (value) {
        if (this.row_bf.pc_id === "sms") {
          if (!this.checkPhone(this.row_bfd.communication).isValid) {
            this.row_bfd.communication = "";
          }
          this.label = "Téléphone";
          this.placeholder = "ex: 123456789";
        } else if (this.row_bf.pc_id === "email") {
          if (!this.matchEmail(this.row_bfd.communication)) {
            this.row_bfd.communication = "";
          }
          this.label = "Email";
          this.placeholder = "ex: monemail@email.fr";
        }
      }
    }
  },
  data() {
    return {
      dialogErrTxt: "",
      dialogOkTxt: "",
      ledsDisableDuration: false,
      ringingDisable: false,
      screenPreviewSizes: [
        { text: "Grande", value: 0.35 },
        { text: "Moyenne", value: 0.25 },
        { text: "Petite", value: 0.15 }
      ],
      row_bf: {
        pc_id: {
          pc_name: ""
        }
      },
      label: "",
      placeholder: "",
      row_bfd: { communication: "" },
      dialogErr: false,
      enableButtonFunctionality: false
    };
  },
  async mounted() {
    if (
      this.boxe.bo_config_options &&
      this.boxe.bo_config_options.options_value_leds_duration === 0
    )
      this.ledsDisableDuration = true;
    if (
      this.boxe.bo_config_options &&
      this.boxe.bo_config_options.options_value_ringing_number === 0
    )
      this.ringingDisable = true;
    await this.loadDefaultconf();
    if (this.isBoxCompatible("options_value_button_functionality"))
      await this.hasOnlyDiaporamaFunctionality();
  },
  methods: {
    close() {
      this.$emit("closeBoxOptions");
    },
    async loadDefaultconf() {
      let response = await this.$axios.get(
        this.$config.server_url +
          "/web/2.0/buttonFunctionalities/" +
          this.row_en.en_id
      );
      if (response.data.defaultFunctionality) {
        this.row_bf = response.data.defaultFunctionality;
        this.row_bfd.communication =
          response.data.defaultFunctionality.moyenCommunication;
      } else if (
        !response.data.defaultFunctionality &&
        response.data.data.length > 0
      ) {
        for (let i = 0; i < response.data.data.length; i++) {
          let row = response.data.data[i];
          let index = row.bf_key.indexOf("diaporama");
          if (index !== -1) {
            this.row_bf = row;
            break;
          }
        }
      }
    },
    async save() {
      let err = [];
      let checkPhone;
      if (this.row_bfd.communication === "" && this.row_bf.pc_id != "none") {
        err.push("Veuillez remplir le moyen de communication");
        this.dialogErrTxt =
          "Vous devez bien remplir le champs : " + this.label + ".";
      } else {
        checkPhone = this.checkPhone(this.row_bfd.communication);
        if (this.row_bf.pc_id === "sms") {
          if (!checkPhone.isValid) {
            err.push(
              "Le numéro de téléphone doit être un numéro de téléphone valide"
            );
            this.dialogErrTxt =
              "Le numéro de téléphone doit être du territoire français et valide!";
          } else {
            this.row_bf.communication = checkPhone.phoneNumber;
          }
        } else if (this.row_bf.pc_id === "email") {
          this.row_bf.communication = this.row_bfd.communication;
          if (!this.matchEmail(this.row_bfd.communication)) {
            err.push("L'adresse email n'est pas valide");
            this.dialogErrTxt = "L'adresse email n'est pas valide!";
          }
        } else {
          this.row_bf.communication = "none";
        }
      }
      if (err.length) {
        // this.dialogErrTxt =
        //   "Vous devez bien remplir le champs : " + this.label + ".";
        this.dialogErr = true;
      } else {
        this.row_bf.en_id = this.row_en.en_id;
        let response2 = await this.$axios.post(
          this.$config.server_url + "/web/2.0/buttonFunctionalities",
          this.row_bf
        );
        if (response2.data.data) {
          this.boxe.bo_config_options.options_value_button_functionality =
            response2.data.data.bf_id;
        } else {
          this.boxe.bo_config_options.options_value_button_functionality = 0;
        }
        let response = await this.$axios.put(
          this.$config.server_url + "/web/2.0/boxes/" + this.boxe.bo_id,
          this.boxe
        );
        if (response.data.err || response2.data.err) {
          console.error(response.data.err);
          this.dialogOkTxt = "";
          this.dialogErrTxt =
            "Une erreur est survenue, les options n'ont pas pu être sauvegardées.";
          return;
        }
        this.dialogOkTxt = "Options sauvegardées avec succès.";
        this.dialogErrTxt = "";
        this.$emit("closeBoxOptions");
      }
    },
    /**
     * isBoxCompatible - check if the current box version is compatible with new options
     * @param {Object} row_en
     * @returns {Boolean}
     */
    isBoxCompatible(withWhat) {
      try {
        return (
          this.boxe &&
          this.boxe.bo_config_options &&
          this.boxe.bo_config_options.hasOwnProperty(withWhat)
        );
      } catch (error) {
        return false;
      }
    },
    matchEmail(email) {
      return email.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
    },
    checkPhone(telephone) {
      let result = phone(telephone, { country: "FRA" });
      return result;
    },
    decryptMessage(message) {
      if (message && message.indexOf("%ENDUSER_NAME") !== -1) {
        message = message.replace("%ENDUSER_NAME", this.row_en.en_fullname);
      }
      return message;
    },
    /**
     * hasOnlyDiaporamaFunctionality - check if the list of button functionalities have more than diaporama, enable the option if it's the case
     */
    async hasOnlyDiaporamaFunctionality() {
      let response = await this.$axios.get(
        this.$config.server_url +
          "/web/2.0/buttonFunctionalities/" +
          this.row_en.en_id
      );
      if (
        !response ||
        !response.data ||
        !response.data.data ||
        !Array.isArray(response.data.data)
      )
        return;
      let otherFunctionalities = response.data.data.filter(functionality => {
        return functionality.bf_key.indexOf("diaporama") === -1;
      });
      if (otherFunctionalities.length > 0)
        this.enableButtonFunctionality = true;
    }
  }
};
</script>

<style lang="scss" scoped>
.screenPreview {
  position: relative;
  width: 50%;
  display: flex;
  flex-direction: column;
  height: 100px;
}
.screenPreviewChild {
  display: flex;
  margin-left: 17px;
  margin-bottom: -5px;
  width: 100px;
  justify-content: space-around;
  z-index: 1;
  height: 35px;
}
.screenPreviewTv {
  position: absolute;
  top: 0;
  left: 0;
  width: auto;
  font-weight: 100;
  z-index: 0;
}
.section-subtitle {
  color: #199aaa;
  font-weight: bold;
}
h5 {
  color: #91295a;
  margin-bottom: 1rem;
  font-weight: bold;
}
</style>
