var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "bg-gray-light" }, [
    _c("div", { staticClass: "container bg-info p-5 text-white" }, [
      _c("div", { staticClass: "row my-3 justify-content-center" }, [
        _vm.ok && !_vm.next && _vm.account
          ? _c("div", { staticClass: "col" }, [
              _c(
                "div",
                { staticClass: "row flex-column align-items-center pl-5 pr-5" },
                [
                  _c("div", { staticClass: "col mb-5" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "row align-items-center justify-content-center"
                      },
                      [
                        _c("icon", {
                          staticClass: "mr-3",
                          staticStyle: { fill: "white" },
                          attrs: { name: "money-check-alt", scale: "4" }
                        }),
                        _c(
                          "h4",
                          {
                            staticClass: "ml-3 text-uppercase font-weight-bold"
                          },
                          [
                            _vm._v(
                              " Définir mon mode de paiement pour l'abonnement Emotivi "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]),
                  _vm.$store.state.authenticated
                    ? _c("div", { staticClass: "col" }, [
                        _c(
                          "h6",
                          {
                            staticClass: "font-weight-bold text-justify m-auto"
                          },
                          [
                            _vm._v(
                              " Vous pouvez désormais renseigner l’IBAN du compte client à utiliser pour l'abonnement Emotivi qui démarrera un mois après la première installation de son kit. "
                            )
                          ]
                        )
                      ])
                    : _c("div", { staticClass: "col" }, [
                        _c(
                          "h6",
                          {
                            staticClass: "font-weight-bold text-justify m-auto"
                          },
                          [
                            _vm._v(
                              " Vous pouvez désormais renseigner l’IBAN du compte que vous souhaitez utiliser pour l'abonnement Emotivi qui démarrera un mois après la première installation de votre kit. "
                            )
                          ]
                        )
                      ]),
                  _vm._m(0),
                  _c("div", { staticClass: "col" }, [
                    _c(
                      "div",
                      { staticClass: "row flex-column align-items-center" },
                      [
                        _c("div", { staticClass: "col mb-3" }, [
                          _c(
                            "div",
                            {
                              staticClass:
                                "row flex-column align-items-center justify-content-end"
                            },
                            [
                              _c("h6", { staticClass: "col text-center" }, [
                                _vm._v(
                                  " En appuyant sur le bouton 'ENREGISTRER L'IBAN' vous serez redirigé vers la page de notre partenaire GOCARDLESS. "
                                )
                              ]),
                              _c(
                                "button",
                                {
                                  staticClass: "col btn btn-primary",
                                  on: {
                                    click: function($event) {
                                      return _vm.goCardLess()
                                    }
                                  }
                                },
                                [_vm._v(" ENREGISTRER L'IBAN ")]
                              )
                            ]
                          )
                        ]),
                        _c("div", { staticClass: "col mt-3" }, [
                          _c("div", { staticClass: "row flex-column" }, [
                            _c("h6", { staticClass: "col text-center" }, [
                              _vm._v(
                                " Si vous ne connaissez pas votre IBAN, vous pourrez le renseigner ultérieurement dans votre espace Emotivi, rubrique 'Mon compte' "
                              )
                            ]),
                            _c(
                              "button",
                              {
                                staticClass: "col btn btn-secondary",
                                on: {
                                  click: function($event) {
                                    return _vm.nextStep()
                                  }
                                }
                              },
                              [_vm._v(" ENREGISTRER L'IBAN ULTERIEUREMENT ")]
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                ]
              )
            ])
          : (_vm.ok && _vm.next) || (_vm.ok && !_vm.account)
          ? _c(
              "div",
              {
                staticClass:
                  "col-xl-4 col-md-5 col-sm-8 col-8 tile-success text-center p-3"
              },
              [
                _c(
                  "div",
                  [
                    _c("icon", {
                      staticClass: "mb-2",
                      staticStyle: { fill: "white" },
                      attrs: { name: "check-circle", scale: "4" }
                    }),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-3",
                        staticStyle: { "font-weight": "bold" }
                      },
                      [_vm._v("Paiement validé")]
                    ),
                    _vm.$store.state.authenticated
                      ? _c("p", [
                          _vm._v(
                            " Un email contenant toutes les informations et identifiants a été envoyé au client. "
                          )
                        ])
                      : !_vm.account
                      ? _c("p", [
                          _vm._v(
                            " Féliciations, vous pouvez désormais utiliser le service Emotivi et communiquer avec votre proche en cliquant sur le bouton suivant. "
                          )
                        ])
                      : _vm.router4g
                      ? _c("p", [
                          _vm._v(
                            " Félicitations, vous pouvez retrouver votre achat dans la liste de vos paiements depuis votre compte client. "
                          )
                        ])
                      : _c("p", [
                          _vm._v(
                            " Un email contenant toutes vos informations et identifiants vous a été envoyé, vous pouvez maintenant accéder à votre compte. "
                          )
                        ]),
                    _vm.$store.state.authenticated
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.redirect("affiliate")
                              }
                            }
                          },
                          [_vm._v(" PASSER UNE NOUVELLE COMMANDE ")]
                        )
                      : !_vm.account
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.redirect("noaccount")
                              }
                            }
                          },
                          [_vm._v(" ACCÉDER À EMOTIVI ")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.redirect()
                              }
                            }
                          },
                          [_vm._v(" VOTRE COMPTE ")]
                        )
                  ],
                  1
                )
              ]
            )
          : _c(
              "div",
              {
                staticClass:
                  "col-xl-4 col-md-5 col-sm-8 col-8 tile-failed text-center p-3"
              },
              [
                _c("icon", {
                  staticClass: "mb-2",
                  attrs: { name: "exclamation-circle", scale: "4" }
                }),
                _c(
                  "h3",
                  {
                    staticClass: "mb-3",
                    staticStyle: { "font-weight": "bold" }
                  },
                  [_vm._v("Paiement échoué.")]
                ),
                _c("p", [
                  _vm._v("Votre paiement a eu un problème, merci de réessayer")
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn-retry",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.$router.push("/subscription")
                      }
                    }
                  },
                  [_vm._v(" RÉESSAYER ")]
                )
              ],
              1
            )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col" }, [
      _c("hr", { staticClass: "border border-light m-5" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }