<template>
  <div class="d-flex">
    <m-form-date
      class="flex-fill"
      v-model="date"
      :label="label"
      :name="nameDate"
      formatInput="DD/MM/YYYY"
      :disabled="disabled"
    ></m-form-date>
    <m-form-hour
      class="flex-fill"
      v-model="time"
      :name="nameHour"
      :disabled="disabled"
    ></m-form-hour>
  </div>
</template>

<script>
import MFormBase from "./MFormBase";

export default {
  name: "mformdatehour",
  extends: MFormBase,
  props: {
    id: {
      default: "",
      type: String
    },
    label: {
      default: "",
      type: String
    },
    name: {
      default: "",
      type: String
    },
    value: [String, Object],
    prefix: String,
    autocomplete: {
      default: "new-password",
      type: String
    },
    readonly: {
      default: true,
      type: Boolean
    },
    hidedate: {
      default: false,
      type: Boolean
    },
    usemoment: {
      default: false,
      type: Boolean
    },
    disabled: {
      default: false,
      type: Boolean
    },
    rules: {
      default: function() {
        return [];
      },
      type: Array
    }
  },
  mounted() {
    if (!this.id2) this.id2 = this.$Utils.randomstring();
    if (this.value) this.setValue(this.value);
  },
  data() {
    //let date = this.$moment().format("YYYY-MM-DD");
    // let time = "00:00:00";
    let nameDate = this.$Utils.randomstring("nameDate");
    let nameHour = this.$Utils.randomstring("nameDate");
    if (this.name) {
      nameDate = this.name + "_date";
      nameHour = this.name + "_hour";
    }
    return {
      nameDate,
      nameHour,
      id2: this.id,
      errormsg2: "",
      rawdate: "",
      rawtime: "",
      date: "0000-00-00",
      time: "00:00:00",
      menudate: false,
      menutime: false
    };
  },
  watch: {
    date: function(val) {
      // console.log("watch date", val);
      let d = this.date + " " + this.time;
      // console.warn("d1", d);
      // console.warn("d", d);
      if (this.usemoment) d = this.$moment(d);
      this.$emit("input", d);
    },
    time: function(val) {
      // console.log("watch time", val);
      let d = this.date + " " + this.time;
      if (this.usemoment) d = this.$moment(d);
      // console.log("d2", d);
      this.$emit("input", d);
    },
    value: function(val) {
      // console.log("val", val, this.name);
      this.setValue(val);
    }
  },
  components: {},
  methods: {
    forceDate(val) {
      this.setValue(val);
    },
    setValue(val) {
      if (this.$_.isString(val) && val == "0000-00-00 00:00:00") {
        this.rawdate = "";
        this.rawtime = "";
        //this.date = this.$moment().format("YYYY-MM-DD");
        //this.time = this.$moment().format("HH:mm:[00]");
      } else {
        let d = this.$moment(val, "YYYY-MM-DD HH:mm:ss");
        this.rawdate = d.format("DD/MM/YYYY");
        // console.log("this.rawdate", this.name, this.rawdate);
        this.rawtime = d.format("HH:mm");
        this.date = d.format("YYYY-MM-DD");
        this.time = d.format("HH:mm:[00]");
        // console.log("this.time", this.time);
      }
    },
    informValid() {
      this.errormsg2 = "";
    },
    informInvalid(txt) {
      this.errormsg2 = txt;
    },
    validate() {
      let oks = [];
      for (let iRule = 0; iRule < this.rules.length; iRule++) {
        const rule = this.rules[iRule];
        let ok = rule(this.value2);
        if (this.$_.isString(ok)) oks.push(ok);
      }
      if (oks.length == 0) {
        this.informValid();
        return true;
      }
      this.informInvalid(oks.join(","));
      return false;
    },
    onfocus(evt) {
      this.$emit("focus", evt);
    },
    onfocus2(evt) {
      this.$emit("focus", evt);
    }
  }
};
</script>
