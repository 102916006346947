<template>
  <section class="bg-gray-light">
    <div class="container bg-info p-5 text-white">
      <div class="row my-3 justify-content-center">
        <div class="col" v-if="ok && !next && account">
          <div class="row flex-column align-items-center pl-5 pr-5">
            <div class="col mb-5">
              <div class="row align-items-center justify-content-center">
                <icon
                  name="money-check-alt"
                  style="fill: white"
                  scale="4"
                  class="mr-3"
                ></icon>
                <h4 class="ml-3 text-uppercase font-weight-bold">
                  Définir mon mode de paiement pour l'abonnement Emotivi
                </h4>
              </div>
            </div>
            <div class="col" v-if="$store.state.authenticated">
              <h6 class="font-weight-bold text-justify m-auto">
                Vous pouvez désormais renseigner l’IBAN du compte client à
                utiliser pour l'abonnement Emotivi qui démarrera un mois après
                la première installation de son kit.
              </h6>
            </div>
            <div class="col" v-else>
              <h6 class="font-weight-bold text-justify m-auto">
                Vous pouvez désormais renseigner l’IBAN du compte que vous
                souhaitez utiliser pour l'abonnement Emotivi qui démarrera un
                mois après la première installation de votre kit.
              </h6>
            </div>
            <div class="col">
              <hr class="border border-light m-5" />
            </div>

            <div class="col">
              <div class="row flex-column align-items-center">
                <div class="col mb-3">
                  <div
                    class="row flex-column align-items-center justify-content-end"
                  >
                    <h6 class="col text-center">
                      En appuyant sur le bouton 'ENREGISTRER L'IBAN' vous serez
                      redirigé vers la page de notre partenaire GOCARDLESS.
                    </h6>
                    <button class="col btn btn-primary" @click="goCardLess()">
                      ENREGISTRER L'IBAN
                    </button>
                  </div>
                </div>

                <div class="col mt-3">
                  <div class="row flex-column">
                    <h6 class="col text-center">
                      Si vous ne connaissez pas votre IBAN, vous pourrez le
                      renseigner ultérieurement dans votre espace Emotivi,
                      rubrique 'Mon compte'
                    </h6>
                    <button class="col btn btn-secondary" @click="nextStep()">
                      ENREGISTRER L'IBAN ULTERIEUREMENT
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="col-xl-4 col-md-5 col-sm-8 col-8 tile-success text-center p-3"
          v-else-if="(ok && next) || (ok && !account)"
        >
          <div>
            <icon
              name="check-circle"
              style="fill: white"
              scale="4"
              class="mb-2"
            ></icon>
            <h3 style="font-weight: bold" class="mb-3">Paiement validé</h3>
            <p v-if="$store.state.authenticated">
              Un email contenant toutes les informations et identifiants a été
              envoyé au client.
            </p>
            <p v-else-if="!account">
              Féliciations, vous pouvez désormais utiliser le service Emotivi et
              communiquer avec votre proche en cliquant sur le bouton suivant.
            </p>
            <p v-else-if="router4g">
              Félicitations, vous pouvez retrouver votre achat dans la liste de
              vos paiements depuis votre compte client.
            </p>
            <p v-else>
              Un email contenant toutes vos informations et identifiants vous a
              été envoyé, vous pouvez maintenant accéder à votre compte.
            </p>
            <button
              v-if="$store.state.authenticated"
              type="button"
              @click="redirect('affiliate')"
              class="btn btn-primary"
            >
              PASSER UNE NOUVELLE COMMANDE
            </button>
            <button
              v-else-if="!account"
              type="button"
              @click="redirect('noaccount')"
              class="btn btn-primary"
            >
              ACCÉDER À EMOTIVI
            </button>
            <button
              v-else
              type="button"
              @click="redirect()"
              class="btn btn-primary"
            >
              VOTRE COMPTE
            </button>
          </div>
        </div>

        <div
          class="col-xl-4 col-md-5 col-sm-8 col-8 tile-failed text-center p-3"
          v-else
        >
          <icon name="exclamation-circle" scale="4" class="mb-2"></icon>
          <h3 style="font-weight: bold" class="mb-3">Paiement échoué.</h3>
          <p>Votre paiement a eu un problème, merci de réessayer</p>
          <button
            type="button"
            @click="$router.push('/subscription')"
            class="btn-retry"
          >
            RÉESSAYER
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
//import PrivateInfoBancaireVue from "./PrivateInfoBancaire.vue";
export default {
  name: "fromstripe",
  // components: { PrivateInfoBancaireVue },
  props: {
    account: {
      default: function () {
        return true;
      },
      type: Boolean,
    },
  },
  data() {
    return {
      ok: true,
      next: false,
      router4g: false,
      key4g: false,
    };
  },
  mounted() {
    if (this.$route.params.ok == "ko") this.ok = false;
    else {
      this.ok = true;
      if (this.$route.query.product && this.$route.query.product === "key4g") {
        this.next = true;
        this.key4g = true;
      }
      if (
        this.$route.query.product &&
        this.$route.query.product === "router4g"
      ) {
        this.next = true;
        this.router4g = true;
      }
      if (this.$store.state.authenticated)
        this.$store.commit("set_co_webaccesstoken_temp", "");
    }
  },
  methods: {
    redirect(where) {
      if (where === "affiliate") {
        // return to the subscription page with empty contact form
        this.$store.commit("set_co_webaccesstoken_temp", "");
        // this.$router.push("/subscription/affiliate/");
        window.open(
          this.$config.web_url_subscription + "/subscription/affiliate",
          "_self"
        );
      } else if (where == "noaccount") {
        window.open(this.$config.webapp_url, "_self");
      } else {
        this.$router.push(
          "/private/autolog/" +
            this.$route.params.co_id +
            "/" +
            this.$route.params.challenge
        );
      }
    },
    nextStep() {
      this.next = true;
    },
    async goCardLess() {
      try {
        let response = await this.$axios.put(
          this.$config.server_url + "/web/2.1/subscription/step4",
          {
            en_id: this.$route.query.en_id,
            co_id: this.$route.params.co_id,
            challenge: this.$route.params.challenge,
          }
        );
        if (response.data.err) {
          if (response.data.err.key === "mandate_exist") {
            /** customer has already save his IBAN on gocardless */
            alert(
              "Vous avez déjà enregistré vos coordonées bancaires auprès d'Emotivi."
            );
            let ok = confirm(
              "Voulez vous mettre à jour vos coordonnées bancaires ?"
            );
            if (ok) {
              /** update customer payment method */
              let response2 = await this.$axios.put(
                this.$config.server_url + "/web/2.1/subscription/step4",
                {
                  en_id: this.en_id,
                  co_id: this.$store.state.user.co_id,
                  updatePaymentMethod: true,
                }
              );
              if (response2.data.err) {
                alert(response2.data.err.message);
                return;
              }
              if (response2.data.data && response2.data.data.billingData)
                window.open(
                  response2.data.data.billingData.authorisation_url,
                  "_SELF"
                );
            }
            return;
          }
          alert(response.data.err.message);
          return;
        }
        if (response.data.data && response.data.data.billingData)
          window.open(
            response.data.data.billingData.authorisation_url,
            "_SELF"
          );
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 50px;
  padding-top: 40px;
}
.tile-success {
  background-color: #79d394;
  color: white;
  border-radius: 15px;
}
.tile-failed {
  background-color: #e75356;
  color: white;
  border-radius: 15px;
}
.btn-retry {
  background-color: #c74648;
  color: white;
  border: none;
  height: 40px;
  width: 120px;
  border-radius: 10px;
  &:hover {
    background-color: #cf4b4e;
  }
}
.btn-account {
  background-color: #64af7b;
  color: white;
  border: none;
  height: 40px;
  width: 170px;
  border-radius: 10px;
  &:hover {
    background-color: #57976a;
  }
}
</style>
