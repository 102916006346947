import Vue from "vue";
import Vuex from "vuex";

import _ from "lodash";
import axios from "axios";
// import config from "../config";
// import moment from "moment";

Vue.use(Vuex);

let co_webaccesstoken_temp = window.localStorage.getItem(
  "co_webaccesstoken_temp"
)
  ? window.localStorage.getItem("co_webaccesstoken_temp")
  : "";
let accesstoken = window.localStorage.getItem("accesstoken")
  ? window.localStorage.getItem("accesstoken")
  : "";
// console.log("accesstoken", accesstoken);
let refreshtoken = window.localStorage.getItem("refreshtoken")
  ? window.localStorage.getItem("refreshtoken")
  : "";

let defaultUser = {
  co_id: "",
  co_invoice_name: "",
  co_invoice_firstname: ""
};
let user = window.localStorage.getItem("user")
  ? JSON.parse(window.localStorage.getItem("user"))
  : _.cloneDeep(defaultUser);
let defaultAffiliatedUser = {
  us_id: "",
  us_login: ""
};
let affiliatedUser = window.localStorage.getItem("affiliatedUser")
  ? JSON.parse(window.localStorage.getItem("affiliatedUser"))
  : _.cloneDeep(affiliatedUser);
let items_civility = [
  {
    value: "Mme",
    text: "Mme"
  },
  {
    value: "M",
    text: "M"
  }
];
// console.log("user", user);

/* let items_0A10 = [
  { value: 0, text: "0" },
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
  { value: 9, text: "9" },
  { value: 10, text: "10" }
]; */

/* let items_civility_simple = [
  {
    value: "mme",
    text: "Madame",
    sex: 2
  },
  {
    value: "m",
    text: "Monsieur",
    sex: 1
  }
]; */

export default new Vuex.Store({
  state: {
    co_webaccesstoken_temp,
    traductions_fr: { translation: {} },
    traductions_en: { translation: {} },
    traductions_sp: { translation: {} },
    traductions_ru: { translation: {} },
    traductions_it: { translation: {} },
    items_civility: items_civility,
    // lang,
    items: {},
    itemsloaded: false,
    traductionsloaded: false,
    myactions: [],
    isMainLoading: false,
    currentcontact: null,
    // lastcoid: "",
    mysocket: null,
    connected: false,
    accesstoken,
    refreshtoken,
    user,
    preferences: {
      personal: {},
      shared: {},
      rights: {}
    },
    reload_actions: 0,
    inputOpened: null,
    dialogErr: false,
    dialogErrTxt: "",
    socketmessage: {},
    affiliatedUser,
    authenticated: false,
    paymentMethodsStatusText: {
      fulfilled: "Le mode de paiement a été créé et finalisé.",
      flow_created:
        "L'interface d'enregistrement du mode de paiement a été créée.",
      flow_visited:
        "L'interface d'enregistrement du mode de paiement a été consultée",
      flow_exited:
        "L'utilisateur a quitté l'interface d'enregistrement du mode de paiement.",
      collect_customer_details: "Les informations du client ont été rassemblées.",
      collect_bank_account:
        "Les coordonnées bancaires ont été collectées pour cette demande de facturation.",
      payer_details_confirmed:
        "Le payeur a confirmé toutes ses coordonnées pour cette demande de facturation.",
      bank_authorisation_visited:
        "Un lien d'autorisation bancaire pour cette demande de facturation a été consulté.",
      bank_authorisation_authorised:
        "Une autorisation bancaire pour cette demande de facturation a été autorisée par le payeur.",
      bank_authorisation_denied:
        "Une autorisation bancaire pour cette demande de facturation a expiré",
      bank_authorisation_expired:
        "Une autorisation bancaire pour cette demande de facturation a été refusée par le payeur.",
      bank_authorisation_failed:
        "Une autorisation bancaire pour cette demande de facturation a échoué en raison d'une incompatibilité de compte bancaire.",
      cancelled: "Ce mode de paiement a été annulé.",
      created: "Le mode de paiement a été créé",
      failed: "Problème de paiement",
      submitted: "Le paiement a été soumis aux banques"
    },
    subscriptionStatusText: {
      created: "L'abonnement a été créé.",
      customer_approval_granted:
        "L'abonnement nécessitait une approbation supplémentaire de la part du client avant de pouvoir devenir actif, et cette approbation a été accordée.",
      customer_approval_denied:
        "L'abonnement nécessitait une approbation supplémentaire de la part du client avant de pouvoir devenir actif, et cette approbation a été refusée.",
      payment_created: "Un paiement a été créé par cet abonnement.",
      cancelled:
        "Cet abonnement a été annulé. Aucun autre paiement ne sera créé.",
      finished:
        "Cet abonnement est terminé. Aucun autre paiement ne sera créé.",
      paused: "Cet abonnement a été suspendu.",
      resumed: "Cet abonnement a redémarré."
    },
    paymentsStatusText: {
      pending_submission:
        "Le paiement a été créé, mais pas encore soumis aux banques",
      pending_customer_approval:
        "Nous attendons que le client approuve ce paiement",
      submitted: "Le paiement a été soumis à la banque du client",
      confirmed: "Le paiement a été validé par la banque du client",
      paid_out: "Le paiement a été envoyé sur le compte bancaire du créancier",
      cancelled: "Le paiement a été annulé",
      customer_approval_denied:
        "Le client a refusé l'approbation du paiement. Vous devez contacter directement le client",
      failed: "Le paiement n'a pas pu être traité.",
      charged_back: "Le paiement a été remboursé",
      succeeded: "Paiement réussi"
    }
  },
  mutations: {
    set_co_webaccesstoken_temp(state, co_webaccesstoken_temp) {
      state.co_webaccesstoken_temp = co_webaccesstoken_temp;
      window.localStorage.setItem(
        "co_webaccesstoken_temp",
        co_webaccesstoken_temp
      );
    },
    set_lang(state, lang) {
      window.localStorage.setItem("pvn_lang", lang);
      state.lang = lang;
    },
    set_traductions(state, data) {
      for (let itrad = 0; itrad < data.traductions.length; itrad++) {
        const trad = data.traductions[itrad];
        state.traductions_fr.translation[trad.tr_key] = trad.tr_fr;
        state.traductions_en.translation[trad.tr_key] = trad.tr_en;
        state.traductions_sp.translation[trad.tr_key] = trad.tr_sp;
        state.traductions_ru.translation[trad.tr_key] = trad.tr_ru;
        state.traductions_it.translation[trad.tr_key] = trad.tr_it;
      }
      state.traductionsloaded = true;
    },
    set_myactions(state, data) {
      state.myactions = data;
    },
    set_socketmessage(state, data) {
      state.socketmessage = data;
    },
    set_inputOpened(state, input) {
      if (input) {
        if (state.inputOpened && input.name !== state.inputOpened.name) {
          state.inputOpened.hideDropdown();
        }
        state.inputOpened = input;
      } else {
        state.inputOpened = null;
      }
    },
    set_mainisloading(state, ok) {
      state.isMainLoading = ok;
    },
    // set_lastcoid(state, co_id) {
    //   state.lastcoid = co_id;
    // },
    set_mysocket(state, socket) {
      state.mysocket = socket;
    },
    set_reload_actions(state) {
      state.reload_actions = Math.random();
    },
    // utiliser simplement pour mettre à jour les infos essentielles
    set_user_simple(state, user) {
      state.user.co_name = user.co_name;
      state.user.co_firstname = user.co_firstname;
      state.user.co_type = user.co_type;
      state.user.updatedAt = user.updatedAt;
    },
    set_connexion(state, what) {
      if (!what) {
        axios.defaults.headers.common["x-auth-accesstoken"] = "";
        window.localStorage.removeItem("accesstoken");
        state.accesstoken = null;
        window.localStorage.removeItem("refreshtoken");
        state.refreshtoken = null;
        window.localStorage.removeItem("user");
        state.user = _.cloneDeep(defaultUser);
        //console.log("state.user", state.user);
        state.connected = false;
        // this.dispatch("socketunjoinroom");
        return;
      }
      // console.log("what", what);
      // console.log("token, refreshtoken, user", what.accesstoken, what.refreshtoken, what.user);
      axios.defaults.headers.common["x-auth-accesstoken"] = what.accesstoken;
      window.localStorage.setItem("accesstoken", what.accesstoken);
      state.accesstoken = what.accesstoken;
      //window.localStorage.setItem("refreshtoken", what.refreshtoken);
      //state.refreshtoken = what.refreshtoken;
      what.user.co_password = "";
      window.localStorage.setItem("user", JSON.stringify(what.user));
      state.user = _.cloneDeep(what.user);
      if (state.affiliatedUser) {
        state.affiliatedUser = {};
        state.authenticated = false;
        window.localStorage.removeItem("affiliatedUser");
      }
      // state.preferences = _.cloneDeep(what.preferences);
      // console.log("what.preferences", what.preferences);
      /*       _.each(what.preferences.items, (val, key) => {
        state[key] = what.preferences.items[key];
      }); */

      state.connected = true;
      // this.dispatch("load_nbclientsuivi");
    },
    set_dialog_error(state, what) {
      // console.log("what", what);
      state.dialogErr = what.dialogErr;
      state.dialogErrTxt = what.dialogErrTxt;
    },
    set_authentification(state, what) {
      if (!what) {
        axios.defaults.headers.common["x-auth-accesstoken"] = "";
        state.accesstoken = null;
        window.localStorage.removeItem("accesstoken");
        state.affiliatedUser = _.cloneDeep(defaultAffiliatedUser);
        state.authenticated = false;
        return;
      }
      axios.defaults.headers.common["x-auth-accesstoken"] = what.accesstoken;
      window.localStorage.setItem("accesstoken", what.accesstoken);
      state.accesstoken = what.accesstoken;
      what.affiliatedUser.us_password = "";
      window.localStorage.setItem(
        "affiliatedUser",
        JSON.stringify(what.affiliatedUser)
      );
      state.affiliatedUser = _.cloneDeep(what.affiliatedUser);

      state.authenticated = true;

      if (state.user) {
        state.user = {};
        state.connected = false;
        window.localStorage.removeItem("user");
      }
      // this.dispatch("load_nbclientsuivi");
    }
  },
  actions: {
    showDialogError(ctx, err) {
      ctx.commit("set_dialog_error", {
        dialogErr: err ? true : false,
        dialogErrTxt: err ? err : ""
      });
    }
    // SOCKET_connect(ctx) {
    //   // if (ctx.state.user && ctx.state.user.co_id && ctx.state.mysocket)
    //   //   ctx.state.mysocket.emit("join", ctx.state.user.co_id);
    // },
    // SOCKET_message(ctx, data) {
    //   ctx.commit("set_socketmessage", data);
    //   ctx.dispatch("updateNbNewMessages");
    // },

    // socketjoinroom(ctx) {
    //   if (ctx.state.user && ctx.state.user.co_id)
    //     this._vm.$socket.emit("join", ctx.state.user.co_id);
    // },
    // socketunjoinroom(ctx) {
    //   this._vm.$socket.emit("unjoin", ctx.state.user.co_id);
    // }
  },
  modules: {},
  getters: {
    get_inputOpened(state) {
      return state.inputOpened;
    }
  }
});
