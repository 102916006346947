<template>
  <section>
    <div
      class="container pb-5 containercolored"
      style="background-color: rgb(24, 155, 176)"
    >
      <div class="row mb-3">
        <div class="col">
          <div class="row align-items-end">
            <div class="col-md-12 col-lg-5 pt-4">
              <div class="mb-3">
                <img
                  src="/images/ico-interface.png"
                  alt=""
                  style="width: 70px"
                />
              </div>
              <div>
                <h2>MON COMPTE</h2>
              </div>
            </div>

            <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/payments')"
              v-if="hasPayment"
            >
              <h4 class="part-subtitle-private">MES<br />PAIEMENTS</h4>
            </div>
            <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/box')"
              v-if="hasBoxes"
            >
              <h4 class="part-subtitle-private">MES KITS<br />EMOTIVI</h4>
            </div>
            <!-- <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/purchasekey4g')"
            >
              <h4 class="part-subtitle-private">COMMANDER<br />UNE CLE 4G</h4>
            </div> -->
            <!--div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/purchaserouter4g')"
            >
              <h4 class="part-subtitle-private">
                COMMANDER<br />UN ROUTEUR 4G
              </h4>
            </div-->
            <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/tv')"
              v-if="hasTvs"
            >
              <h4 class="part-subtitle-private">MES TVs<br />EMOTIVI</h4>
            </div>
            <!-- <div
              id="documentation"
              class="d-flex pt-4 flex-wrap col"
              style="top: 0; right: 0"
            >
              <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="/files/Mode_d_emploi_v2.15_8.12.21.pdf"
                  >Documentation installation
                </a>
              </div>
              <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="/files/Aide_utilisateur-Emotivi-20200220.pdf?v=1"
                  >Documentation utilisateur
                </a>
              </div>
              <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="https://www.youtube.com/watch?v=I3KLLo-sQzo"
                  >Configurer Emotivi
                </a>
              </div>
              <div v-if="depositNotPaid" class="m-1">
                <button
                  class="btn btn-danger ml-4 uppercase"
                  type="button"
                  @click="payDeposit()"
                >
                  Vous n'avez pas encore payé la caution
                </button>
              </div>
            </div> -->
            <!-- <div class="col-sm-4 col-lg-2 text-center mb-3">
              <h4
                class="part-subtitle-private"
                @click="$Utils.openConfigurationSite()"
              >
                CONFIGURATION<br />DE LA BOX
              </h4>
            </div> -->
          </div>
        </div>
      </div>

      <!-- ACCOUNT TILE -->
      <div style="display: block">
        <div>
          <form-contact
            v-if="row_co.co_id"
            :row_co="row_co"
            :disableLogin="false"
            @checkphone="checkPhone"
          ></form-contact>
          <div class="mt-3">
            <div v-if="dialogErrTxt" class="alert alert-danger">
              {{ dialogErrTxt }}
            </div>
            <div v-if="dialogOkTxt" class="alert alert-success">
              {{ dialogOkTxt }}
            </div>
            <button class="btn btn-primary" type="button" @click="saveAccount">
              ENREGISTRER MES COORDONNÉES
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FormContact from "./FormContact";

export default {
  name: "privateaccount",
  components: { FormContact },
  data() {
    return {
      shippingPhoneCheck: false,
      billingPhoneCheck: false,
      dialogErrTxt: "",
      dialogOkTxt: "",
      row_co: {},
      endusers: [],
      parcels: [],
      depositNotPaid: false
    };
  },
  async mounted() {
    this.loadAccount();
    //this.loadOrders();
    // efface les données de paiement !!!
    this.$store.commit("set_co_webaccesstoken_temp", "");
  },
  computed: {
    hasPayment() {
      return this.$store.state.user.endusers &&
        Array.isArray(this.$store.state.user.endusers) &&
        this.$store.state.user.endusers.length
        ? true
        : false;
    },
    hasBoxes() {
      return this.$store.state.user.endusers &&
        Array.isArray(this.$store.state.user.endusers) &&
        this.$store.state.user.endusers.length
        ? this.$store.state.user.endusers.filter(enduser => {
            return enduser.bo_id;
          }).length
        : 0;
    },
    hasTvs() {
      return this.$store.state.user.endusers &&
        Array.isArray(this.$store.state.user.endusers) &&
        this.$store.state.user.endusers.length
        ? this.$store.state.user.endusers.filter(enduser => {
            return enduser.de_id;
          }).length
        : 0;
    }
  },
  methods: {
    async loadOrders() {
      let response = await this.$axios.get(
        this.$config.server_url + "/web/2.0/orders"
      );
      // this.row_co = response.data.data.row_co;
      this.stripe_data = response.data.data.stripe_data;
      if (this.stripe_data.charges.length) this.depositNotPaid = false;
      else this.depositNotPaid = true;
    },
    async saveAccount() {
      this.dialogErrTxt = "";
      this.dialogOkTxt = "";
      // Vérif téléphone
      if (!this.row_co.co_invoice_tel)
        this.dialogErrTxt = "Veuillez renseigner un numéro de téléphone";
      else if (this.row_co.co_invoice_tel && !this.billingPhoneCheck)
        this.dialogErrTxt = "Veuillez renseigner un numéro de téléphone valide";
      else if (this.row_co.co_shipping_tel && !this.shippingPhoneCheck)
        this.dialogErrTxt =
          "Veuillez renseigner un numéro de téléphone de livraison valide";
      if (this.dialogErrTxt) return;

      let response = await this.$axios.put(
        this.$config.server_url + "/web/2.0/account",
        {
          row_co: this.row_co
        }
      );
      if (response.data.err) {
        if (response.data.err.key == "contact_login_exists_already")
          this.dialogErrTxt = "L'email existe déjà";
        else if (response.data.err.key == "contact_affiliation_code_invalid")
          this.dialogErrTxt =
            "Le code d'affiliation est invalide. Laissez vide si vous êtes un particulier.";
        else this.dialogErrTxt = response.data.err.message;
        return;
      }
      this.dialogOkTxt = "Le compte a été enregistré";
      window.setTimeout(() => {
        this.dialogOkTxt = "";
      }, 3000);
    },
    async loadAccount() {
      let response = await this.$axios.get(
        this.$config.server_url + "/web/2.0/account"
      );
      // console.log("response.data.data", response.data.data);
      this.row_co = response.data.data.row_co;
      this.parcels = response.data.data.parcels;
      this.endusers = response.data.data.endusers;
    },
    checkPhone(valide, co_phoneField) {
      if (co_phoneField === "co_shipping_tel") {
        this.shippingPhoneCheck = valide;
      } else if (co_phoneField === "co_invoice_tel") {
        this.billingPhoneCheck = valide;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 50px;
  // padding-top: 40px;
}
</style>
