import { render, staticRenderFns } from "./AttestationFiscale.vue?vue&type=template&id=643229b1&scoped=true&"
import script from "./AttestationFiscale.vue?vue&type=script&lang=js&"
export * from "./AttestationFiscale.vue?vue&type=script&lang=js&"
import style0 from "./AttestationFiscale.vue?vue&type=style&index=0&id=643229b1&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "643229b1",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/emotivi-v2-web/node_modules/@vue/cli-service/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('643229b1')) {
      api.createRecord('643229b1', component.options)
    } else {
      api.reload('643229b1', component.options)
    }
    module.hot.accept("./AttestationFiscale.vue?vue&type=template&id=643229b1&scoped=true&", function () {
      api.rerender('643229b1', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/AttestationFiscale.vue"
export default component.exports