<template>
  <transition name="fade">
    <div
      class="modal"
      :class="value ? 'show' : 'hide'"
      :style="
        value ? 'display:block;background-color:#3333337a;' : 'display:none;'
      "
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-scrollable" role="document">
        <div class="modal-content">
          <div class="modal-header d-flex flex-row align-items-center">
            <h1>
              Attestation Fiscale pour l'année
              {{ contactFiscale.selectedYear }}
            </h1>
          </div>
          <div class="modal-body">

              <m-form-select
                      label="Année :"
                      v-model="contactFiscale.selectedYear"
                      name="year"
                      :items="contactFiscale.years"
                      @input="verifyExistRequest"
              ></m-form-select>
              <m-form-select
              label="Boxe concernée :"
              v-model="contactFiscale.selectedUser"
              name="utilisateur"
              :items="contactFiscale.users"
              @input="verifyExistRequest"
            ></m-form-select>
            <div v-if="existRequest" :class="typeAlert">
              {{ dialogExistTxt }}
            </div>
            <div v-if="!existRequest">
              <m-form-text
                label="Adresse mail d’envoi de l'attestation : "
                type="text"
                v-model="contactFiscale.email"
                :name="$Utils.randomstring('email')"
                :rules="[$Validation.mandatory]"
              ></m-form-text>
              <div class="col-12">
                <div v-if="dialogErr" class="alert alert-danger">
                  {{ dialogTxt }}
                </div>
              </div>
              <h5 class="ml-2">
                Informations à faire figurer sur l’attestation
              </h5>
              <div class="row">
                <div class="col-3">
                  <m-form-select
                    label="Civilité : "
                    v-model="contactFiscale.civility"
                    :name="$Utils.randomstring('us_civility')"
                    :rules="[$Validation.mandatory]"
                    :items="$store.state.items_civility"
                  ></m-form-select>
                </div>
                <div class="col-9">
                  <m-form-text
                    label=" Prénom et Nom : "
                    type="text"
                    v-model="contactFiscale.name"
                    :name="$Utils.randomstring('name')"
                    :rules="[$Validation.mandatory]"
                  ></m-form-text>
                </div>
              </div>
              <m-form-text
                label="Adresse postale: "
                type="textarea"
                v-model="contactFiscale.address"
                :name="$Utils.randomstring('address')"
                :rules="[$Validation.mandatory]"
              ></m-form-text>
            </div>
          </div>
          <div class="modal-footer d-flex flex-row">
            <div class="d-flex flex-row align-items-end">
              <button
                type="button"
                class="btn btn-secondary mr-2"
                @click="cancelWin"
              >
                Annuler
              </button>
              <button
                v-if="!existRequest"
                type="button"
                class="btn btn-primary btn-mouveal"
                @click="requestAttestation"
              >
                Envoyer la demande d'attestation
              </button>
            </div>
          </div>
        </div>
      </div>
      <m-message-dialog
        v-model="dialog"
        :title="title"
        :text="dialogTxt"
        :classBody="typeAlertModalBody"
      ></m-message-dialog>
    </div>
  </transition>
</template>
<script>
import MMessageDialog from "../components/MMessageDialog.vue";
import moment from "moment";
export default {
  name: "AttestationFiscale",
  components: { MMessageDialog },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
    en_id: {
      default: 0,
      type: Number,
    },
  },
  data() {
    return {
      contactFiscale: {
        address: "",
        email: "",
        civility: "M",
        users: [],
        selectedUser: "",
        selectedYear: moment().subtract(1, "year").format("YYYY"),
      },
      dialogTxt: "",
      dialogErr: false,
      dialog: false,
      title: "",
      existRequest: false,
      dialogExistTxt: "",
      typeAlert: "row alert alert-success ",
      typeAlertModalBody: "modal-body",
    };
  },
  watch: {},
  async mounted() {
    let utilisateurs = [];
    for (let index = 0; index < this.getEndusers.length; index++) {
      const user = this.getEndusers[index];
      utilisateurs.push({
        text: user.en_fullname,
        value: user.en_id,
        nom: user.en_fullname,
      });
    }
    this.contactFiscale.users = utilisateurs;
    this.contactFiscale.years = [];
    let currentYear = moment().year();
    this.contactFiscale.selectedYear = currentYear - 1;
    for(let index = 0; index < 3; index++){
        this.contactFiscale.years.push({
            text: currentYear,
            value: currentYear,
            nom: currentYear,
        });
        currentYear--;
    }


      if (this.en_id) {
      for (const utilisateur of utilisateurs) {
        if (utilisateur.value === this.en_id)
          this.contactFiscale.selectedUser = utilisateur.value;
      }
    } else {
      this.contactFiscale.selectedUser = utilisateurs[0].value;
    }
    this.verifyExistRequest();
    this.showContactName(this.$store.state.user);
    this.showContactAdress(this.$store.state.user);
    this.contactFiscale.email = this.$store.state.user.co_invoice_email;
  },
  computed: {
    getEndusers() {
      return this.$store.state.user.endusers &&
        Array.isArray(this.$store.state.user.endusers) &&
        this.$store.state.user.endusers.length
        ? this.$store.state.user.endusers
        : [];
    },
  },
  methods: {
    matchEmail(email) {
      return email.match(
        /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
      );
    },
    showContactName(val) {
      let txt = "";
      if (val.co_invoice_firstname)
        txt +=
          val.co_invoice_firstname.charAt(0).toUpperCase() +
          val.co_invoice_firstname.substr(1);
      if (val.co_invoice_name) txt += " " + val.co_invoice_name.toUpperCase();
      this.contactFiscale.name = txt;
      this.contactFiscale.civility = val.co_invoice_civility;
    },
    showContactAdress(val) {
      let txt = "";
      if (val.co_invoice_address1) txt += val.co_invoice_address1;
      if (val.co_invoice_address2) txt += ", " + val.co_invoice_address2;
      if (val.co_invoice_city) txt += " " + val.co_invoice_city;
      if (val.co_invoice_zip) txt += " " + val.co_invoice_zip;
      this.contactFiscale.address = txt;
    },
    async requestAttestation() {
      let err = [];
      if (!this.matchEmail(this.contactFiscale.email)) {
        err.push("L'adresse mail n'est pas valide");
        this.dialogTxt = "L'adresse mail n'est pas valide!";
      }
      if (err.length) {
        this.dialogErr = true;
      } else {
        let response = await this.$axios.post(
          this.$config.server_url + "/web/2.0/attestationFiscale",
          this.contactFiscale
        );
        if (response.data.data == "ok") {
          this.title = "Succès";
          this.dialogTxt = "La demande a été envoyée avec succès.";
          this.dialog = true;
          // timer
          setTimeout(() => {
            this.dialog = false;
            this.$emit("winRequestAttestationCanceled");
          }, 5000);
        } else if (response.data.data == "irrecevable") {
          this.title = "Impossible de vous envoyer une attestation fiscale";
          this.typeAlert = "row alert alert-danger";
          this.typeAlertModalBody = "modal-body alert alert-danger";
          this.dialogTxt =
            "<b>Nous ne pouvons pas vous délivrer cette attestation.</b> En effet, les sommes à déclarer sont celles versées dans l’année écoulée. Votre abonnement ayant commencé dans l’année en cours, les sommes versées à Emotivi seront à afficher sur la prochaine déclaration fiscale.";
          this.dialog = true;
        } else {
          this.title = "Erreur";
          this.dialogTxt =
            "Une erreur est survenue lors de l'envoi de la demande. Veuillez réessayez ";
          this.dialog = true;
        }
      }
    },
    cancelWin() {
      this.$emit("winRequestAttestationCanceled");
    },
    async verifyExistRequest() {
      let response = await this.$axios.get(
        this.$config.server_url +
          "/extranet/2.0/attestationFiscale/" +
          this.contactFiscale.selectedUser +
          "/" +
          this.contactFiscale.selectedYear
      );
      if (response.data.data) {
        this.existRequest = true;
        if (response.data.data.fisc_status == 0) {
          this.dialogExistTxt =
            "Vous avez déjà effectué une demande pour cet utilisateur. Elle est en cours de traitement.";
        } else if (response.data.data.fisc_status == 1) {
          this.dialogExistTxt =
            "Vous avez déjà effectué une demande pour cet utilisateur. Veuillez vérifier votre boite de réception ou de spam.";
        } else if (response.data.data.fisc_status == 2) {
          this.typeAlert = "row alert alert-danger";

          this.dialogExistTxt =
            "Vous avez déjà effectué une demande pour cet utilisateur. Votre demande a été refusée. Vous pouvez contacter le service client Emotivi au 04 93 33 38 87. ";
        }
      } else {
        this.existRequest = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../scss/styles.scss";
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 700px;
    margin: 1.75rem auto;
  }
}
@import "../scss/styles.scss";

.winedit-container-header {
  width: 100%;
  justify-content: space-between;
}
.input-avatar {
  padding: 0;
}
.modal {
  transition: all 0.5s cubic-bezier(1, 0.5, 0.8, 1);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
.active {
  min-width: 150px;
  input {
    width: auto;
  }
}
/*************** table droits **************/
tr {
  border-bottom: 0.5px solid #ccc9c9;
}
td:first-child {
  text-align: left;
}
td,
th {
  border-right: 0.5px solid #ccc9c9;
  border-left: 0.5px solid #ccc9c9;
  padding: 5px 10px;
  text-align: center;
}
th {
  background-color: #013c62;
  color: #fff;
  text-transform: uppercase;
}
th.groupe {
  color: black;
}
.groupe {
  background-color: #b5b2b2;
}
h5 {
  color: #1498ad;
}
</style>
