var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("section", [
        _c("h1", { staticClass: "mt-3" }, [
          _vm._v("CONDITIONS GENERALES D'UTILISATION ET DE VENTE")
        ]),
        _c("div", { staticClass: "text-center mt-2 mb-5" }, [
          _c("h4", [_vm._v("Nos CGU sont disponibles ici :")]),
          _c(
            "a",
            {
              staticClass: "btn btn-primary m-1",
              attrs: {
                target: "_blank",
                href: "/files/CGU-Emotivi-20201209.pdf"
              }
            },
            [_vm._v("Conditions générales d'utilisation")]
          )
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }