<template>
  <section class="bg-gray-light">
    <div class="container">
      <div class="row my-3 justify-content-center">
        <div
          class="col-xl-4 col-md-5 col-sm-8 col-8 tile-success text-center p-3"
          v-if="ok"
        >
          <div>
            <icon
              name="check-circle"
              style="fill:white"
              scale="4"
              class="mb-2"
            ></icon>
            <h3 style="font-weight: bold" class="mb-3">Paiement validé</h3>
            <p v-if="$store.state.authenticated">
              Un email contenant toutes les informations et identifiants a été
              envoyé au client.
            </p>
            <p v-else-if="!account">
              Féliciation, vous pouvez désormais utiliser le service Emotivi et
              communiquer avec votre proche en cliquant sur le bouton suivant.
            </p>
            <p v-else>
              Un email contenant toutes vos informations et identifiants vous a
              été envoyé, vous pouvez maintenant accéder à votre compte.
            </p>
            <button
              v-if="$store.state.authenticated"
              type="button"
              @click="redirect('affiliate')"
              class="btn btn-primary"
            >
              PASSER UNE NOUVELLE COMMANDE
            </button>
            <button
              v-else-if="!account"
              type="button"
              @click="redirect('noaccount')"
              class="btn btn-primary"
            >
              ACCÉDER À EMOTIVI
            </button>
            <button
              v-else
              type="button"
              @click="redirect()"
              class="btn btn-primary"
            >
              VOTRE COMPTE
            </button>
          </div>
        </div>

        <div
          class="col-xl-4 col-md-5 col-sm-8 col-8 tile-failed text-center p-3"
          v-else
        >
          <icon name="exclamation-circle" scale="4" class="mb-2"></icon>
          <h3 style="font-weight: bold" class="mb-3">
            L'enregistrement de l'IBAN a échoué.
          </h3>
          <p>
            Vous pouvez ignorer cette étape et enregistrer votre IBAN
            ultérieurement depuis votre espace personnel
          </p>
          <button type="button" @click="goCardLess()" class="btn-retry">
            RÉESSAYER
          </button>
          <button type="button" @click="redirect()" class="btn btn-primary">
            VOTRE COMPTE
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// import PrivateInfoBancaireVue from "./PrivateInfoBancaire.vue";
export default {
  name: "fromgocardless",
  //components: { PrivateInfoBancaireVue },
  props: {
    account: {
      default: function() {
        return true;
      },
      type: Boolean
    }
  },
  data() {
    return {
      ok: true,
      next: false
    };
  },
  mounted() {
    if (this.$route.params.ok == "ko") this.ok = false;
    else {
      this.ok = true;
      if (this.$store.state.authenticated)
        this.$store.commit("set_co_webaccesstoken_temp", "");
    }
  },
  methods: {
    redirect(where) {
      if (where === "affiliate") {
        // return to the subscription page with empty contact form
        this.$store.commit("set_co_webaccesstoken_temp", "");
        // this.$router.push("/subscription/affiliate/");
        window.open(
          this.$config.web_url_subscription + "/subscription/affiliate",
          "_self"
        );
      } else if (where == "noaccount") {
        window.open(this.$config.webapp_url, "_self");
      } else {
        this.$router.push(
          "/private/autolog/" +
            this.$route.params.co_id +
            "/" +
            this.$route.params.challenge
        );
      }
    },
    nextStep() {
      this.next = true;
    },
    async goCardLess() {
      try {
        let response = await this.$axios.put(
          this.$config.server_url + "/web/2.1/subscription/step4",
          {
            en_id: this.$route.query.en_id
          }
        );
        if (response.data.err) {
          if (response.data.err.key === "mandate_exist") {
            /** customer has already save his IBAN on gocardless */
            alert(
              "Vous avez déjà enregistré vos coordonées bancaires auprès d'Emotivi."
            );
            let ok = confirm(
              "Voulez vous mettre à jour vos coordonnées bancaires ?"
            );
            if (ok) {
              /** update customer payment method */
              let response2 = await this.$axios.put(
                this.$config.server_url + "/web/2.1/subscription/step4",
                {
                  en_id: this.en_id,
                  co_id: this.$store.state.user.co_id,
                  updatePaymentMethod: true
                }
              );
              if (response2.data.err) {
                alert(response2.data.err.message);
                return;
              }
              if (response2.data.data && response2.data.data.billingData)
                window.open(
                  response2.data.data.billingData.authorisation_url,
                  "_SELF"
                );
            }
            return;
          }
          alert(response.data.err.message);
          return;
        }
        if (response.data.data && response.data.data.billingData)
          window.open(
            response.data.data.billingData.authorisation_url,
            "_SELF"
          );
      } catch (error) {
        console.error(error);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 50px;
  padding-top: 40px;
}
.tile-success {
  background-color: #79d394;
  color: white;
  border-radius: 15px;
}
.tile-failed {
  background-color: #e75356;
  color: white;
  border-radius: 15px;
}
.btn-retry {
  background-color: #c74648;
  color: white;
  border: none;
  height: 40px;
  width: 120px;
  border-radius: 10px;
  &:hover {
    background-color: #cf4b4e;
  }
}
.btn-account {
  background-color: #64af7b;
  color: white;
  border: none;
  height: 40px;
  width: 170px;
  border-radius: 10px;
  &:hover {
    background-color: #57976a;
  }
}
</style>
