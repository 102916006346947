var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      {
        class: _vm.dialog.display ? "modal fade show" : "modal fade",
        style: _vm.dialog.display ? "display:block" : "display:none",
        attrs: { tabindex: "-1", role: "dialog" }
      },
      [
        _c(
          "div",
          { staticClass: "modal-dialog", attrs: { role: "document" } },
          [
            _c("div", { staticClass: "modal-content" }, [
              _c("div", { staticClass: "modal-header" }, [
                _c("h5", { staticClass: "modal-title" }, [
                  _vm._v(_vm._s(_vm.dialog.title))
                ]),
                _vm._m(0)
              ]),
              _c("div", { staticClass: "modal-body" }, [
                _c("p", [_vm._v(_vm._s(_vm.dialog.body))])
              ]),
              _c("div", { staticClass: "modal-footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.dialogAction("confirm")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.dialog.confirm) + " ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-secondary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.dialogAction("close")
                      }
                    }
                  },
                  [_vm._v(" " + _vm._s(_vm.dialog.close) + " ")]
                )
              ])
            ])
          ]
        )
      ]
    ),
    _c(
      "div",
      {
        staticClass: "container pb-1",
        staticStyle: { "background-color": "#6ccdb2" },
        attrs: { id: "page_subscription" }
      },
      [
        _c("div", { staticClass: "row mb-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "row align-items-end" }, [
              _c("div", { staticClass: "col-md-12 col-lg-4 pt-4" }, [
                _c("div", { staticClass: "mb-3" }, [
                  _c("img", {
                    staticStyle: { width: "70px" },
                    attrs: { src: "/images/ico-interface.png", alt: "" }
                  }),
                  _c(
                    "button",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.$store.state.co_webaccesstoken_temp,
                          expression: "$store.state.co_webaccesstoken_temp"
                        }
                      ],
                      staticClass: "btn btn-primary ml-5",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.resetLocalStorage()
                        }
                      }
                    },
                    [_vm._v(" ABANDONNER LA COMMANDE ")]
                  )
                ]),
                _vm._m(1)
              ]),
              _c(
                "div",
                {
                  staticClass:
                    "\n              offset-lg-2\n              col-sm-4 col-lg-2\n              text-center\n              align-items-end\n              d-flex\n              justify-content-center\n              mb-3\n              mt-5\n            "
                },
                [
                  _c("div", [
                    _c(
                      "h3",
                      {
                        class: {
                          "mb-3": true,
                          "part-subtitle": true,
                          over: _vm.part == "part1"
                        }
                      },
                      [_vm._v(" 1 ")]
                    ),
                    _c(
                      "h4",
                      {
                        class: {
                          "part-subtitle": true,
                          over: _vm.part == "part1"
                        }
                      },
                      [_vm._v(" COMPTE"), _c("br"), _vm._v("CLIENT ")]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "\n              col-sm-4 col-lg-2\n              text-center\n              align-items-end\n              d-flex\n              justify-content-center\n              mb-3\n            "
                },
                [
                  _c("div", { attrs: { id: "contact_selected" } }, [
                    _c(
                      "h3",
                      {
                        class: {
                          "mb-3": true,
                          "part-subtitle": true,
                          over: _vm.part == "part2"
                        }
                      },
                      [_vm._v(" 2 ")]
                    ),
                    _c(
                      "h4",
                      {
                        class: {
                          "part-subtitle": true,
                          over: _vm.part == "part2"
                        }
                      },
                      [_vm._v(" COORDONNEES"), _c("br"), _vm._v(" CLIENT ")]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "\n              col-sm-4 col-lg-2\n              text-center\n              align-items-end\n              d-flex\n              justify-content-center\n              mb-3\n            "
                },
                [
                  _c("div", { attrs: { id: "confirm_selected" } }, [
                    _c(
                      "h3",
                      {
                        class: {
                          "mb-3": true,
                          "part-subtitle": true,
                          over: _vm.part == "part3"
                        }
                      },
                      [_vm._v(" 3 ")]
                    ),
                    _c(
                      "h4",
                      {
                        class: {
                          "part-subtitle": true,
                          over: _vm.part == "part3"
                        }
                      },
                      [
                        _vm._v(" CONFIRMATION"),
                        _c("br"),
                        _vm._v("DES DONNEES ")
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        ]),
        _vm.part == "part1"
          ? _c("div", { staticStyle: { display: "block" } }, [
              _vm._m(2),
              _c("form", { attrs: { name: "account", id: "form_account" } }, [
                _c("div", { staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "co_email_family" } }, [
                        _vm._v("EMAIL DU CLIENT")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.co_login,
                            expression: "co_login"
                          }
                        ],
                        staticClass: "form-control",
                        staticStyle: { border: "none", "border-radius": "0" },
                        attrs: {
                          type: "email",
                          id: "co_login",
                          name: "co_login"
                        },
                        domProps: { value: _vm.co_login },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.co_login = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "co_password1" } }, [
                        _vm._v("MOT DE PASSE DU CLIENT")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.co_password1,
                            expression: "co_password1"
                          }
                        ],
                        staticClass: "form-control",
                        staticStyle: { border: "none", "border-radius": "0" },
                        attrs: {
                          type: "password",
                          id: "co_password1",
                          name: "co_password"
                        },
                        domProps: { value: _vm.co_password1 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.co_password1 = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "co_password2" } }, [
                        _vm._v("CONFIRMEZ LE MOT DE PASSE")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.co_password2,
                            expression: "co_password2"
                          }
                        ],
                        staticClass: "form-control",
                        staticStyle: { border: "none", "border-radius": "0" },
                        attrs: { type: "password", id: "co_password2" },
                        domProps: { value: _vm.co_password2 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.co_password2 = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm.dialogErrTxt
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(" " + _vm._s(_vm.dialogErrTxt) + " ")
                        ])
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button", id: "btstep1" },
                        on: { click: _vm.post_step1 }
                      },
                      [_vm._v(" ETAPE SUIVANTE ")]
                    )
                  ]),
                  _vm._m(3)
                ])
              ])
            ])
          : _vm._e(),
        _vm.part == "part2"
          ? _c(
              "div",
              { staticStyle: { display: "block" } },
              [
                _c("form-contact-affiliate", {
                  attrs: { row_co: _vm.row_co },
                  on: { checkphone: _vm.checkPhone }
                }),
                _c("div", { staticClass: "row mt-4 mb-5" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _vm.dialogErrTxt
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(" " + _vm._s(_vm.dialogErrTxt) + " ")
                        ])
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.post_step2 }
                      },
                      [_vm._v(" ETAPE SUIVANTE")]
                    ),
                    _c("br"),
                    _c("br")
                  ]),
                  _vm._m(4)
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.part == "part4"
          ? _c(
              "div",
              {
                staticStyle: { display: "block" },
                attrs: { id: "sante_form" }
              },
              [
                _c("div", { staticClass: "row text-left" }, [
                  _c("div", { staticClass: "col-md" }, [
                    _vm._m(5),
                    _c("hr"),
                    _c("h4", [
                      _vm._v(
                        " Le client souhaite installer le kit Emotivi dans un établissement de santé (Ehpad, maison de retraite) pour son sénior ? "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " L'installation dans un établissement de santé est plus complexe qu'une installation chez un particulier car il faut des autorisations accordées par l'établissement. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " Merci de dire au client de demander au responsable de l’établissement de santé de nous contacter par email (contact@emotivi.io) en précisant : le nom et l’adresse de l’établissement, le nom du directeur ou responsable, son numéro de téléphone et le nom du sénior. "
                      )
                    ]),
                    _c("br"),
                    _c("br"),
                    _c("br"),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.get_step3 }
                      },
                      [_vm._v(" ETAPE SUIVANTE ")]
                    )
                  ])
                ])
              ]
            )
          : _vm._e(),
        _vm.part == "part3"
          ? _c("div", { staticStyle: { display: "block" } }, [
              _c("div", { staticClass: "row text-left" }, [
                _c("div", { staticClass: "col-md" }, [
                  _vm._m(6),
                  _c("hr"),
                  _vm._m(7),
                  _c("h4", [
                    _vm._v("Voici un récapitulatif de ses informations :")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("h5", [
                        _vm._v(" Adresse de facturation "),
                        !_vm.row_co.co_adresses_different
                          ? _c("span", [_vm._v(" et de livraison")])
                          : _vm._e()
                      ]),
                      _vm._v(
                        " Prénom : " + _vm._s(_vm.row_co.co_invoice_firstname)
                      ),
                      _c("br"),
                      _vm._v(" Nom : " + _vm._s(_vm.row_co.co_invoice_name)),
                      _c("br"),
                      _vm._v(
                        " Téléphone : " + _vm._s(_vm.row_co.co_invoice_tel)
                      ),
                      _c("br"),
                      _vm._v(" Adresse :"),
                      _c("br"),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.adressfacturation) }
                      })
                    ]),
                    _vm.row_co.co_adresses_different
                      ? _c(
                          "div",
                          {
                            staticClass: "col",
                            attrs: { id: "confirm_livraison" }
                          },
                          [
                            _c("h5", [_vm._v("Adresse de livraison")]),
                            _vm._v(
                              " Prénom livraison : " +
                                _vm._s(_vm.row_co.co_shipping_firstname)
                            ),
                            _c("br"),
                            _vm._v(
                              " Nom livraison : " +
                                _vm._s(_vm.row_co.co_shipping_name)
                            ),
                            _c("br"),
                            _vm._v(
                              " Téléphone livraison : " +
                                _vm._s(_vm.row_co.co_shipping_tel)
                            ),
                            _c("br"),
                            _vm._v(" Adresse livraison :"),
                            _c("br"),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.adresslivraison)
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "a",
                    {
                      staticStyle: { "text-transform": "none" },
                      attrs: { href: "#" },
                      on: { click: _vm.get_step2 }
                    },
                    [_vm._v("Modifier les coordonnées")]
                  ),
                  _c("hr"),
                  _c("div", {
                    staticClass: "row",
                    staticStyle: { display: "block" },
                    attrs: { id: "recapChart" }
                  }),
                  _c("div"),
                  _c("div", { staticClass: "row mt-4 mb-5" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", {}, [
                        _c("input", {
                          staticStyle: {
                            "margin-top": "-10px",
                            width: "auto",
                            height: "auto"
                          },
                          attrs: { type: "checkbox", id: "cguid" },
                          on: { change: _vm.cguAccept }
                        }),
                        _vm._v("  "),
                        _c(
                          "label",
                          {
                            staticClass: "mb-0",
                            staticStyle: { "font-size": "1rem" },
                            attrs: { for: "cguid" }
                          },
                          [_vm._v(" Accepter les CGU ")]
                        ),
                        _vm._m(8)
                      ]),
                      _c("br"),
                      _c("div", { staticClass: "alert alert-light" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.kitCarriedAway,
                              expression: "kitCarriedAway"
                            }
                          ],
                          staticStyle: {
                            "margin-top": "-10px",
                            width: "auto",
                            height: "auto"
                          },
                          attrs: { type: "checkbox", id: "kitCarriedAway" },
                          domProps: {
                            checked: Array.isArray(_vm.kitCarriedAway)
                              ? _vm._i(_vm.kitCarriedAway, null) > -1
                              : _vm.kitCarriedAway
                          },
                          on: {
                            change: function($event) {
                              var $$a = _vm.kitCarriedAway,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = null,
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    (_vm.kitCarriedAway = $$a.concat([$$v]))
                                } else {
                                  $$i > -1 &&
                                    (_vm.kitCarriedAway = $$a
                                      .slice(0, $$i)
                                      .concat($$a.slice($$i + 1)))
                                }
                              } else {
                                _vm.kitCarriedAway = $$c
                              }
                            }
                          }
                        }),
                        _vm._v("  "),
                        _c(
                          "label",
                          {
                            staticClass: "mb-0",
                            staticStyle: { "font-size": "1rem" },
                            attrs: { for: "kitCarriedAway" }
                          },
                          [
                            _vm._v(
                              " Le kit est-il emporté directement par le client ? "
                            )
                          ]
                        )
                      ]),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          staticStyle: { width: "auto" },
                          attrs: {
                            type: "button",
                            id: "btnpay",
                            disabled: !_vm.cguAccepted
                          },
                          on: { click: _vm.post_step3 }
                        },
                        [
                          _vm._v(
                            " PROCÉDER AU PAIEMENT DE LA CAUTION - " +
                              _vm._s(
                                _vm.offerproduct.ofpr_initial_price_ht / 100
                              ) +
                              " € "
                          )
                        ]
                      ),
                      _c("br"),
                      _c("br"),
                      _c("p", {
                        staticClass: "alert alert-info col-7",
                        domProps: {
                          innerHTML: _vm._s(
                            _vm.$options.filters.nl2br(
                              _vm.offer.of_web_description
                            )
                          )
                        }
                      }),
                      _c("br"),
                      _c("br"),
                      _c("p")
                    ])
                  ]),
                  _c("div")
                ])
              ])
            ])
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "button",
      {
        staticClass: "close",
        attrs: {
          type: "button",
          "data-dismiss": "modal",
          "aria-label": "Close"
        }
      },
      [_c("span", { attrs: { "aria-hidden": "true" } }, [_vm._v("×")])]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h2", [_vm._v("COMMANDER"), _c("br"), _vm._v("UN KIT EMOTIVI")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h5", { staticStyle: { "max-width": "600px" } }, [
        _vm._v(
          " Créez le compte de votre client qui lui permettra de gérer son abonnement et ses options "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-9" }, [
      _c("p", {
        staticStyle: { color: "#a4145a" },
        attrs: { id: "email_already_exist" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-9 align-items-end d-flex justify-content-start" },
      [
        _c("p", {
          staticStyle: { color: "#a4145a" },
          attrs: { id: "error_msg_contact" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row text-center" }, [
      _c("div", { staticClass: "col" }, [_c("h2", [_vm._v("IMPORTANT")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row text-center" }, [
      _c("div", { staticClass: "col" }, [_c("h2", [_vm._v("CONFIRMATION")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "mb-0" }, [
      _vm._v(" Le compte client Emotivi a été créé avec succès."),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-0" }, [
      _c(
        "a",
        {
          staticClass: "ml-4 small-text pointer",
          attrs: { href: "/files/CGU-Emotivi-20201209.pdf" }
        },
        [_vm._v("Voir les CGU")]
      )
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }