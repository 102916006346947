// JavaScript Object for country specific iban data.
function Country(name, code, bank_form, acc_form) {
  // Constructor for Country objects.
  //
  // Arguments:
  //   name      - Name of the country
  //   code      - Country Code from ISO 3166
  //   bank_form - Format of bank/branch code part (e.g. "0 4a 0 ")
  //   acc_form  - Format of account number part (e.g. "0  11  2n")

  this.name = name;
  this.code = code;
  this.bank = Country_decode_format(bank_form);
  this.acc = Country_decode_format(acc_form);
  this.bank_lng = Country_calc_length(this.bank);
  this.acc_lng = Country_calc_length(this.acc);
  this.total_lng = 4 + this.bank_lng + this.acc_lng;
}

function Country_decode_format(form) {
  var form_list = new Array();
  var parts = form.split(" ");
  for (var i = 0; i < parts.length; ++i) {
    var part = parts[i];
    if (part != "") {
      var typ = part.charAt(part.length - 1);
      if (typ == "a" || typ == "n") part = part.substring(0, part.length - 1);
      else typ = "c";
      var lng = parseInt(part);
      form_list[form_list.length] = new Array(lng, typ);
    }
  }
  return form_list;
}

function Country_calc_length(form_list) {
  var sum = 0;
  for (var i = 0; i < form_list.length; ++i) sum += form_list[i][0];
  return sum;
}

// BBAN data from ISO 13616, Country codes from ISO 3166 (www.iso.org).
var iban_data = new Array(
  new Country("Andorra", "AD", "0  4n 4n", "0  12   0 "),
  new Country("Albania", "AL", "0  8n 0 ", "0  16   0 "),
  new Country("Austria", "AT", "0  5n 0 ", "0  11n  0 "),
  new Country("Bosnia and Herzegovina", "BA", "0  3n 3n", "0   8n  2n"),
  new Country("Belgium", "BE", "0  3n 0 ", "0   7n  2n"),
  new Country("Bulgaria", "BG", "0  4a 4n", "2n  8   0 "),
  new Country("Switzerland", "CH", "0  5n 0 ", "0  12   0 "),
  new Country("Cyprus", "CY", "0  3n 5n", "0  16   0 "),
  new Country("Czech Republic", "CZ", "0  4n 0 ", "0  16n  0 "),
  new Country("Germany", "DE", "0  8n 0 ", "0  10n  0 "),
  new Country("Denmark", "DK", "0  4n 0 ", "0   9n  1n"),
  new Country("Estonia", "EE", "0  2n 0 ", "2n 11n  1n"),
  new Country("Spain", "ES", "0  4n 4n", "2n 10n  0 "),
  new Country("Finland", "FI", "0  6n 0 ", "0   7n  1n"),
  new Country("Faroe Islands", "FO", "0  4n 0 ", "0   9n  1n"),
  new Country("France", "FR", "0  5n 5n", "0  11   2n"),
  new Country("United Kingdom", "GB", "0  4a 6n", "0   8n  0 "),
  new Country("Georgia", "GE", "0  2a 0 ", "0  16n  0 "),
  new Country("Gibraltar", "GI", "0  4a 0 ", "0  15   0 "),
  new Country("Greenland", "GL", "0  4n 0 ", "0   9n  1n"),
  new Country("Greece", "GR", "0  3n 4n", "0  16   0 "),
  new Country("Croatia", "HR", "0  7n 0 ", "0  10n  0 "),
  new Country("Hungary", "HU", "0  3n 4n", "1n 15n  1n"),
  new Country("Ireland", "IE", "0  4a 6n", "0   8n  0 "),
  new Country("Israel", "IL", "0  3n 3n", "0  13n  0 "),
  new Country("Iceland", "IS", "0  4n 0 ", "2n 16n  0 "),
  new Country("Italy", "IT", "1a 5n 5n", "0  12   0 "),
  new Country("Kuwait", "KW", "0  4a 0 ", "0  22   0 "),
  new Country("Kazakhstan", "KZ", "0  3n 0 ", "0  13   0 "),
  new Country("Lebanon", "LB", "0  4n 0 ", "0  20   0 "),
  new Country("Liechtenstein", "LI", "0  5n 0 ", "0  12   0 "),
  new Country("Lithuania", "LT", "0  5n 0 ", "0  11n  0 "),
  new Country("Luxembourg", "LU", "0  3n 0 ", "0  13   0 "),
  new Country("Latvia", "LV", "0  4a 0 ", "0  13   0 "),
  new Country("Monaco", "MC", "0  5n 5n", "0  11   2n"),
  new Country("Montenegro", "ME", "0  3n 0 ", "0  13n  2n"),
  new Country(
    "Macedonia, Former Yugoslav Republic of",
    "MK",
    "0  3n 0 ",
    "0  10   2n"
  ),
  new Country("Mauritania", "MR", "0  5n 5n", "0  11n  2n"),
  new Country("Malta", "MT", "0  4a 5n", "0  18   0 "),
  new Country("Mauritius", "MU", "0  4a 4n", "0  15n  3a"),
  new Country("Netherlands", "NL", "0  4a 0 ", "0  10n  0 "),
  new Country("Norway", "NO", "0  4n 0 ", "0   6n  1n"),
  new Country("Poland", "PL", "0  8n 0 ", "0  16n  0 "),
  new Country("Portugal", "PT", "0  4n 4n", "0  11n  2n"),
  new Country("Romania", "RO", "0  4a 0 ", "0  16   0 "),
  new Country("Serbia", "RS", "0  3n 0 ", "0  13n  2n"),
  new Country("Saudi Arabia", "SA", "0  2n 0 ", "0  18   0 "),
  new Country("Sweden", "SE", "0  3n 0 ", "0  16n  1n"),
  new Country("Slovenia", "SI", "0  5n 0 ", "0   8n  2n"),
  new Country("Slovak Republic", "SK", "0  4n 0 ", "0  16n  0 "),
  new Country("San Marino", "SM", "1a 5n 5n", "0  12   0 "),
  new Country("Tunisia", "TN", "0  2n 3n", "0  13n  2n"),
  new Country("Turkey", "TR", "0  5n 0 ", "1  16   0 ")
);

// Search the country code in the iban_data list.
function CountryData(code) {
  for (var i = 0; i < iban_data.length; ++i)
    if (iban_data[i].code == code) return iban_data[i];
  return null;
}

// Modulo 97 for huge numbers given as digit strings.
function mod97(digit_string) {
  var m = 0;
  for (var i = 0; i < digit_string.length; ++i)
    m = (m * 10 + parseInt(digit_string.charAt(i))) % 97;
  return m;
}

// Convert a capital letter into digits: A -> 10 ... Z -> 35 (ISO 13616).
function capital2digits(ch) {
  var capitals = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  for (var i = 0; i < capitals.length; ++i) if (ch == capitals.charAt(i)) break;
  return i + 10;
}

// Fill the string with leading zeros until length is reached.
function fill0(s, l) {
  while (s.length < l) s = "0" + s;
  return s;
}

// Calculate 2-digit checksum of an IBAN.
function ChecksumIBAN(iban) {
  var code = iban.substring(0, 2);
  var checksum = iban.substring(2, 4);
  var bban = iban.substring(4);

  // Assemble digit string
  var digits = "";
  for (var i = 0; i < bban.length; ++i) {
    var ch = bban.charAt(i).toUpperCase();
    if ("0" <= ch && ch <= "9") digits += ch;
    else digits += capital2digits(ch);
  }
  for (var i = 0; i < code.length; ++i) {
    var ch = code.charAt(i);
    digits += capital2digits(ch);
  }
  digits += checksum;

  // Calculate checksum
  checksum = 98 - mod97(digits);
  return fill0("" + checksum, 2);
}

// Fill the account number part of IBAN with leading zeros.
function FillAccount(country, account) {
  return fill0(account, country.acc_lng);
}

// Check if syntax of the part of IBAN is invalid.
function InvalidPart(form_list, iban_part) {
  for (var f = 0; f < form_list.length; ++f) {
    var lng = form_list[f][0],
      typ = form_list[f][1];
    if (lng > iban_part.length) lng = iban_part.length;
    for (var i = 0; i < lng; ++i) {
      var ch = iban_part.charAt(i);
      var a = "A" <= ch && ch <= "Z";
      var n = "0" <= ch && ch <= "9";
      var c = n || a || ("a" <= ch && ch <= "z");
      if ((!c && typ == "c") || (!a && typ == "a") || (!n && typ == "n"))
        return true;
    }
    iban_part = iban_part.substring(lng);
  }
  return false;
}

// Check if length of the bank/branch code part of IBAN is invalid.
function InvalidBankLength(country, bank) {
  return bank.length != country.bank_lng;
}

// Check if syntax of the bank/branch code part of IBAN is invalid.
function InvalidBank(country, bank) {
  return InvalidBankLength(country, bank) || InvalidPart(country.bank, bank);
}

// Check if length of the account number part of IBAN is invalid.
function InvalidAccountLength(country, account) {
  return account.length < 1 || account.length > country.acc_lng;
}

// Check if syntax of the account number part of IBAN is invalid.
function InvalidAccount(country, account) {
  return (
    InvalidAccountLength(country, account) ||
    InvalidPart(country.acc, FillAccount(country, account))
  );
}

// Check if length of IBAN is invalid.
function InvalidIBANlength(country, iban) {
  return iban.length != country.total_lng;
}

// Convert iban from string format to intern value.
function intern(extern) {
  if (extern.substring(0, 4) == "IBAN") extern = extern.substring(4);
  var s = "";
  for (var i = 0; i < extern.length; ++i)
    if (extern.charAt(i) != " ") s += extern.charAt(i);
  return s;
}

// Check the checksum of an IBAN.
function IBANokay(iban) {
  return ChecksumIBAN(iban) == "97";
}

// Check the syntax and the checksum of the IBAN.
function CheckIBAN(iban) {
  var iban = intern(iban);

  var code = iban.substring(0, 2);

  var bban = iban.substring(4);
  var country = CountryData(code);

  var err = null;
  if (country == null) err = _("Code du pays inconnu: ") + code;
  else if (InvalidIBANlength(country, iban))
    err =
      _("La longueur de l'IBAN est invalide pour le pays : ") +
      country.name +
      " (" +
      country.total_lng +
      ")";
  else {
    var bank_lng = country.bank_lng;
    var bank = bban.substring(0, bank_lng);
    var account = bban.substring(bank_lng);

    if (InvalidBank(country, bank))
      err =
        _("Le code de la banque ") +
        bank +
        _(" est invalide pour le pays : ") +
        country.name;
    else if (InvalidAccount(country, account))
      err =
        _("Le numéro de compte ") +
        account +
        _(" est invalide pour le pays : ") +
        country.name;
    else if (!IBANokay(iban)) err = _("");
  }

  if (err) {
    return err;
  } else {
    return;
  }
}
exports.checkIBAN = CheckIBAN;

// Examples of IBANs for each country.

// Translation table and translation function for localized versions
var trans_tab = new Array();

function _(s) {
  var t = trans_tab[s];
  if (t) s = t;
  return s;
}

// Set debug_output = true if location ends with a hash or a quotation mark
/** 
var debug_output =
  location.href.charAt(location.href.length - 1) == "#" ||
  location.href.charAt(location.href.length - 1) == "?";

 if (debug_output) debug_iban_data();

function debug_iban_data() {
  var s = "";
  for (var i = 0; i < iban_data.length; ++i) {
    var country = iban_data[i];
    s += country.name + " / " + country.code + " / ";
    for (var f = 0; f < country.bank.length; ++f)
      s += country.bank[f][0] + country.bank[f][1];
    s += " = " + country.bank_lng + " / ";
    for (var f = 0; f < country.acc.length; ++f)
      s += country.acc[f][0] + country.acc[f][1];
    s += " = " + country.acc_lng + " / " + country.total_lng + "\n";
  }
  alert(s);
}
*/

/**
 * BIC is the business identifier code (ISO 9362). This BIC check is not a guarantee for authenticity.
 *
 * BIC pattern: BBBBCCLLbbb (8 or 11 characters long; bbb is optional)
 *
 * BIC definition in detail:
 * - First 4 characters - bank code (only letters)
 * - Next 2 characters - ISO 3166-1 alpha-2 country code (only letters)
 * - Next 2 characters - location code (letters and digits)
 *   a. shall not start with '0' or '1'
 *   b. second character must be a letter ('O' is not allowed) or one of the following digits ('0' for test (therefore not allowed), '1' for passive participant and '2' for active participant)
 * - Last 3 characters - branch code, optional (shall not start with 'X' except in case of 'XXX' for primary office) (letters and digits)
 */
function checkBIC(value) {
  return /^([A-Z]{6}[A-Z2-9][A-NP-Z1-9])(X{3}|[A-WY-Z0-9][A-Z0-9]{2})?$/.test(
    value.toUpperCase()
  );
}
exports.checkBIC = checkBIC;
