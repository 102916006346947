<template>
  <section>
    <div
      :style="dialog.display ? 'display:block' : 'display:none'"
      :class="dialog.display ? 'modal fade show' : 'modal fade'"
      tabindex="-1"
      role="dialog"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">{{ dialog.title }}</h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{ dialog.body }}</p>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-primary"
              @click="dialogAction('confirm')"
            >
              {{ dialog.confirm }}
            </button>
            <button
              type="button"
              class="btn btn-secondary"
              @click="dialogAction('close')"
            >
              {{ dialog.close }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div
      class="container pb-1"
      style="background-color: #6ccdb2"
      id="page_subscription"
    >
      <div class="row mb-5">
        <div class="col">
          <div class="row align-items-end">
            <div class="col-md-12 col-lg-4 pt-4">
              <div class="mb-3">
                <img
                  src="/images/ico-interface.png"
                  alt=""
                  style="width: 70px"
                />
                <button
                  v-show="$store.state.co_webaccesstoken_temp"
                  type="button"
                  class="btn btn-primary ml-5"
                  @click="resetLocalStorage()"
                >
                  ABANDONNER LA COMMANDE
                </button>
              </div>
              <div>
                <h2>COMMANDER<br />UN KIT EMOTIVI</h2>
              </div>
            </div>

            <div
              class="
                offset-lg-2
                col-sm-4 col-lg-2
                text-center
                align-items-end
                d-flex
                justify-content-center
                mb-3
                mt-5
              "
            >
              <div>
                <h3
                  :class="{
                    'mb-3': true,
                    'part-subtitle': true,
                    over: part == 'part1'
                  }"
                >
                  1
                </h3>
                <h4
                  :class="{
                    'part-subtitle': true,
                    over: part == 'part1'
                  }"
                >
                  COMPTE<br />CLIENT
                </h4>
              </div>
            </div>
            <div
              class="
                col-sm-4 col-lg-2
                text-center
                align-items-end
                d-flex
                justify-content-center
                mb-3
              "
            >
              <div id="contact_selected">
                <h3
                  :class="{
                    'mb-3': true,
                    'part-subtitle': true,
                    over: part == 'part2'
                  }"
                >
                  2
                </h3>
                <h4
                  :class="{
                    'part-subtitle': true,
                    over: part == 'part2'
                  }"
                >
                  COORDONNEES<br />
                  CLIENT
                </h4>
              </div>
            </div>
            <div
              class="
                col-sm-4 col-lg-2
                text-center
                align-items-end
                d-flex
                justify-content-center
                mb-3
              "
            >
              <div id="confirm_selected">
                <h3
                  :class="{
                    'mb-3': true,
                    'part-subtitle': true,
                    over: part == 'part3'
                  }"
                >
                  3
                </h3>
                <h4
                  :class="{
                    'part-subtitle': true,
                    over: part == 'part3'
                  }"
                >
                  CONFIRMATION<br />DES DONNEES
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- ACCOUNT TILE -->
      <div style="display: block" v-if="part == 'part1'">
        <div>
          <h5 style="max-width: 600px">
            Créez le compte de votre client qui lui permettra de gérer son
            abonnement et ses options
          </h5>
        </div>
        <form name="account" id="form_account">
          <div class="row mt-3">
            <div class="col-md-4">
              <div class="form-group">
                <label for="co_email_family">EMAIL DU CLIENT</label>
                <input
                  type="email"
                  class="form-control"
                  id="co_login"
                  name="co_login"
                  v-model="co_login"
                  style="border: none; border-radius: 0"
                />
              </div>
              <div class="form-group">
                <label for="co_password1">MOT DE PASSE DU CLIENT</label>
                <input
                  type="password"
                  class="form-control"
                  id="co_password1"
                  name="co_password"
                  v-model="co_password1"
                  style="border: none; border-radius: 0"
                />
              </div>
              <div class="form-group">
                <label for="co_password2">CONFIRMEZ LE MOT DE PASSE</label>
                <input
                  type="password"
                  class="form-control"
                  id="co_password2"
                  v-model="co_password2"
                  style="border: none; border-radius: 0"
                />
              </div>
              <div v-if="dialogErrTxt" class="alert alert-danger">
                {{ dialogErrTxt }}
              </div>
              <button
                class="btn btn-primary"
                type="button"
                id="btstep1"
                @click="post_step1"
              >
                ETAPE SUIVANTE
              </button>
            </div>
            <div class="col-md-9">
              <p id="email_already_exist" style="color: #a4145a"></p>
            </div>
          </div>
        </form>
      </div>

      <!-- CONTACT TILE -->
      <div style="display: block" v-if="part == 'part2'">
        <form-contact-affiliate
          :row_co="row_co"
          @checkphone="checkPhone"
        ></form-contact-affiliate>
        <div class="row mt-4 mb-5">
          <div class="col-md-4">
            <div v-if="dialogErrTxt" class="alert alert-danger">
              {{ dialogErrTxt }}
            </div>
            <button type="button" class="btn btn-primary" @click="post_step2">
              ETAPE SUIVANTE</button
            ><br /><br />
            <!-- <a @click="resetLocalStorage()" href="javascript:void(0);"
              >Passer une autre commande</a
            > -->
          </div>
          <div class="col-md-9 align-items-end d-flex justify-content-start">
            <p id="error_msg_contact" style="color: #a4145a"></p>
          </div>
        </div>
      </div>

      <div id="sante_form" style="display: block" v-if="part == 'part4'">
        <div class="row text-left">
          <div class="col-md">
            <div class="row text-center">
              <div class="col">
                <h2>IMPORTANT</h2>
              </div>
            </div>
            <hr />
            <h4>
              Le client souhaite installer le kit Emotivi dans un établissement
              de santé (Ehpad, maison de retraite) pour son sénior ?
            </h4>
            <p>
              L'installation dans un établissement de santé est plus complexe
              qu'une installation chez un particulier car il faut des
              autorisations accordées par l'établissement.
            </p>
            <p>
              Merci de dire au client de demander au responsable de
              l’établissement de santé de nous contacter par email
              (contact@emotivi.io) en précisant : le nom et l’adresse de
              l’établissement, le nom du directeur ou responsable, son numéro de
              téléphone et le nom du sénior.
            </p>
            <br /><br /><br /><br />
            <button type="button" class="btn btn-primary" @click="get_step3">
              ETAPE SUIVANTE
            </button>
          </div>
        </div>
      </div>

      <!-- CONFIRM TILE -->
      <div style="display: block" v-if="part == 'part3'">
        <div class="row text-left">
          <div class="col-md">
            <div class="row text-center">
              <div class="col">
                <h2>CONFIRMATION</h2>
              </div>
            </div>
            <hr />
            <h4 class="mb-0">
              Le compte client Emotivi a été créé avec succès.<br />
              <!-- Un mail vous a été envoyé sur votre adresse email pour activer le compte. -->
            </h4>

            <!-- <div style="float:right;">
            <button type="button" class="btn-next"
              style="width:auto; background-color:lightgray;" onclick="switchForm('contact');">
              Modifier les coordonnées
            </button>
          </div> -->
            <h4>Voici un récapitulatif de ses informations :</h4>
            <div class="row">
              <div class="col">
                <h5>
                  Adresse de facturation
                  <span v-if="!row_co.co_adresses_different">
                    et de livraison</span
                  >
                </h5>
                Prénom : {{ row_co.co_invoice_firstname }}<br />
                Nom : {{ row_co.co_invoice_name }}<br />
                Téléphone : {{ row_co.co_invoice_tel }}<br />
                Adresse :<br />
                <span v-html="adressfacturation"></span>
              </div>
              <div
                class="col"
                id="confirm_livraison"
                v-if="row_co.co_adresses_different"
              >
                <h5>Adresse de livraison</h5>
                Prénom livraison : {{ row_co.co_shipping_firstname }}<br />
                Nom livraison : {{ row_co.co_shipping_name }}<br />
                Téléphone livraison : {{ row_co.co_shipping_tel }}<br />
                Adresse livraison :<br />
                <span v-html="adresslivraison"></span>
              </div>
            </div>
            <a href="#" @click="get_step2" style="text-transform: none"
              >Modifier les coordonnées</a
            >
            <hr />
            <div id="recapChart" style="display: block" class="row"></div>
            <div></div>
            <div class="row mt-4 mb-5">
              <div class="col">
                <!-- <p>Premier paiement de 90€ incluant la location du boitier pour une période de 4 mois dont 1 mois
                offert et les frais de port.<br>La période de
                location commencera dès votre première connexion.</p>
              <p>Après 4 mois d'utilisation, vous serez débité mensuellement de 29,99€/mois. Résiliable à tout moment
                passé ce délai.</p> -->

                <!-- <p>Premier paiement de 99€ incluant :
                <ul>
                  <li>La location du kit Emotivi (boitier+camera+bouton+cables) pour une période de 4 mois (dont 1
                    mois offert)
                    soit 3x30€</li>
                  <li>Les frais de port</li>
                  <li>Si vous n’êtes pas satisfaits durant les 14 premiers jours suivant la réception du kit Emotivi,
                    vous serez remboursé intégralement après nous avoir retourné le matériel</li>
                </ul>
              </p> -->

                <div class="">
                  <input
                    type="checkbox"
                    class=""
                    id="cguid"
                    style="margin-top: -10px; width: auto; height: auto"
                    @change="cguAccept"
                  />&nbsp;
                  <label class="mb-0" for="cguid" style="font-size: 1rem">
                    Accepter les CGU
                  </label>
                  <div class="mt-0">
                    <a
                      class="ml-4 small-text pointer"
                      href="/files/CGU-Emotivi-20201209.pdf"
                      >Voir les CGU</a
                    >
                  </div>
                </div>

                <br />
                <div class="alert alert-light">
                  <input
                    type="checkbox"
                    class=""
                    id="kitCarriedAway"
                    style="margin-top: -10px; width: auto; height: auto"
                    v-model="kitCarriedAway"
                  />&nbsp;
                  <label
                    class="mb-0"
                    for="kitCarriedAway"
                    style="font-size: 1rem"
                  >
                    Le kit est-il emporté directement par le client ?
                  </label>
                </div>
                <button
                  type="button"
                  class="btn btn-primary"
                  style="width: auto"
                  id="btnpay"
                  :disabled="!cguAccepted"
                  @click="post_step3"
                >
                  PROCÉDER AU PAIEMENT DE LA CAUTION -
                  {{ offerproduct.ofpr_initial_price_ht / 100 }} €
                </button>
                <br /><br />
                <p
                  class="alert alert-info col-7"
                  v-html="$options.filters.nl2br(offer.of_web_description)"
                >
                  <!-- Le paiement de 100€ correspond à la caution pour le kit
                  Emotivi
                  <br />Cette somme vous sera remboursé au retour du kit.<br />
                  L'abonnement de 30€ débutera lorsque le kit sera installé et
                  fonctionnel. -->
                </p>
                <br /><br />
                <!-- <p class="">
                  Attention, dû à l'actualité de la covid-19 qui touche
                  également nos fournisseurs les délais de livraison peuvent
                  être rallongés jusqu'à 2 semaines
                </p> -->
                <p></p>
              </div>
            </div>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import FormContactAffiliate from "./FormContactAffiliate";
import { loadStripe } from "@stripe/stripe-js";

// Vue.component("v-select", vSelect);

export default {
  name: "subscriptionaffiliate",
  components: { FormContactAffiliate },
  metaInfo() {
    return {
      title: "Emotivi - inscription",
      meta: [
        { name: "description", content: "Subscription for affiliate users" },
        { property: "og:title", content: "Subscription for affiliate users" },
        { property: "og:site_name", content: "Emotivi" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "noindex" }
      ]
    };
  },
  data() {
    return {
      shippingPhoneCheck: false,
      billingPhoneCheck: false,
      dialogErrTxt: "",
      cguAccepted: false,
      part: "part1",
      kitCarriedAway: false,
      co_login: "",
      co_password1: "",
      co_password2: "",
      row_co: {},
      endusers: [],
      parcels: [],
      offer: {},
      offerproduct: {},
      dialog: {
        title: "",
        body: "",
        confirm: "Confirmer",
        close: "Annuler",
        display: false
      },
      resolveDialog: null
    };
  },
  mounted() {
    if (this.$store.state.co_webaccesstoken_temp) {
      this.get_step2();
    }
    if (this.$route.query.part && this.$route.query.part === "part3") {
      this.get_step3();
    }
  },
  computed: {
    adresslivraison() {
      let ad = "";
      ad += this.row_co.co_shipping_address1 + "<br>";
      if (this.row_co.co_shipping_address2)
        ad += this.row_co.co_shipping_address2 + "<br>";
      ad +=
        this.row_co.co_shipping_zip +
        " " +
        this.row_co.co_shipping_city +
        " - " +
        this.row_co.co_shipping_country +
        "<br>";
      return ad;
    },
    adressfacturation() {
      let ad = "";
      ad += this.row_co.co_invoice_address1 + "<br>";
      if (this.row_co.co_invoice_address2)
        ad += this.row_co.co_invoice_address2 + "<br>";
      ad +=
        this.row_co.co_invoice_zip +
        " " +
        this.row_co.co_invoice_city +
        " - " +
        this.row_co.co_invoice_country +
        "<br>";
      return ad;
    }
  },
  methods: {
    resetLocalStorage() {
      if (
        window.confirm(
          "Vous allez devoir créer un nouveau compte avec une nouvelle adresse Email. Souhaitez-vous continuer ??"
        )
      ) {
        this.$store.commit("set_co_webaccesstoken_temp", "");
        this.resetData();
      }
    },
    resetData() {
      this.shippingPhoneCheck = false;
      this.billingPhoneCheck = false;
      this.dialogErrTxt = "";
      this.cguAccepted = false;
      this.part = "part1";
      this.co_login = "";
      this.co_password1 = "";
      this.co_password2 = "";
      this.row_co = {};
      this.endusers = [];
      this.parcels = [];
      this.offer = {};
      this.offerproduct = {};
    },
    cguAccept() {
      this.cguAccepted = !this.cguAccepted;
    },
    // openPdfCgu() {
    //   window.open(this.$config.server_url + "/web/2.0/cgu", "_blank");
    // },
    async post_step3() {
      if (!this.kitCarriedAway) {
        if (
          await this.confirmDialog(
            "Kit emporté",
            "Le kit est-il emporté directement par le client ? Si oui, veuillez cocher la case au dessus du bouton PROCÉDER AU PAIEMENT DE LA CAUTION",
            "Oui",
            "Non"
          )
        )
          return;
      }

      let cguResponse = await this.$axios.post(
        this.$config.server_url + "/web/2.0/subscription/cgu",
        {
          co_webaccesstoken_temp: this.row_co.co_webaccesstoken_temp,
          co_cgu: "v2.1.5"
        }
      );

      if (!cguResponse.data.ok) return;
      let step3Body = {
        row_co: this.row_co,
        parcels: this.parcels,
        endusers: this.endusers
      };
      if (this.kitCarriedAway) step3Body.pa_state = "CARRIED_AWAY";
      let response = await this.$axios.post(
        this.$config.server_url + "/web/2.0/subscription/step3",
        step3Body
      );

      // console.log("response.data", response.data);
      if (response.data.error) return this.$router.push("/fromstripe/ko");

      const stripe = await loadStripe(response.data.data.stripe_apikeypublic);
      stripe
        .redirectToCheckout({
          // Make the id field from the Checkout Session creation API response
          // available to this file, so you can provide it as parameter here
          // instead of the {{CHECKOUT_SESSION_ID}} placeholder.
          sessionId: response.data.data.CHECKOUT_SESSION_ID
        })
        .then(function(result) {
          console.warn(result);
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
          this.$router.push("/private/autolog/" + this.row_co.co_id + "/abcde");
        });
      // console.log("response.data", response.data);

      /*// autolog après paiement, avec challenge
      // @David je ne sais pas si c'est ici le bon endroit
      let challenge = md5(
        this.row_co.co_id + process.env.VUE_APP_CHALLENGE_KEY
      );
      console.log("challenge", challenge);
      this.$router.push(
        "/private/autolog/" + this.row_co.co_id + "/" + challenge
      ); */
      // http://localhost:4018/fromstripe/ok/cs_test_a13nXgrk9Q0yYLTZak0CukwxFsa6fzH5unc0ZgLvQA0mgDxtY3WiAsKsUa
    },
    async post_step2() {
      this.dialogErrTxt = "";
      // verifier les champs type de connexion et établissement
      if (!this.row_co.co_box_connection_type)
        this.dialogErrTxt = "Type de connexion à internet obligatoire";
      if (
        !this.row_co.co_box_tvbrand ||
        (this.row_co.co_box_tvbrand == "other" &&
          this.row_co.co_box_tvbrand_other == "")
      )
        this.dialogErrTxt = "Marque de votre télévision obligatoire";
      if (this.row_co.co_invoice_civility == "")
        this.dialogErrTxt = "Veuillez sélectionner votre civilité.";
      if (!this.row_co.co_box_inestablishment)
        this.dialogErrTxt =
          "Est-ce que le kit Emotivi sera installé dans un Établissement de santé, champ obligatoire";
      if (!this.row_co.co_invoice_name)
        this.dialogErrTxt = "Veuillez renseigner votre nom.";
      if (!this.row_co.co_invoice_firstname)
        this.dialogErrTxt = "Veuillez renseigner votre prénom.";
      if (
        !this.row_co.co_invoice_address1 ||
        !this.row_co.co_invoice_zip ||
        !this.row_co.co_invoice_country ||
        !this.row_co.co_invoice_city
      )
        this.dialogErrTxt = "Veuillez renseigner votre adresse complète.";

      // Vérif téléphone
      if (!this.row_co.co_invoice_tel)
        this.dialogErrTxt = "Veuillez renseigner un numéro de téléphone";
      else if (this.row_co.co_invoice_tel && !this.billingPhoneCheck)
        this.dialogErrTxt = "Veuillez renseigner un numéro de téléphone valide";
      else if (this.row_co.co_shipping_tel && !this.shippingPhoneCheck)
        this.dialogErrTxt =
          "Veuillez renseigner un numéro de téléphone de livraison valide";

      // check affiliation code
      if (!this.row_co.af_code)
        this.dialogErrTxt = "Veuillez saisir votre code d'affiliation.";

      if (this.dialogErrTxt) return;
      // let response =
      // let parcel = { pa_id: this.parcels.length ? this.parcels[0].pa_id : "" };
      // let enduser = {
      //   en_id: this.endusers.length ? this.endusers[0].en_id : "",
      //   en_fullname:
      //     this.row_co.co_invoice_name + " " + this.row_co.co_invoice_firstname
      // };
      // let tabFields = [
      //   "civility",
      //   "name",
      //   "firstname",
      //   "address1",
      //   "address2",
      //   "zip",
      //   "city",
      //   "country",
      //   "tel"
      // ];
      // for (let iField = 0; iField < tabFields.length; iField++) {
      //   const field = tabFields[iField];
      //   if (this.row_co.co_adresses_different)
      //     parcel["pa_shipping_" + field] = this.row_co["co_shipping_" + field];
      //   else
      //     parcel["pa_shipping_" + field] = this.row_co["co_invoice_" + field];
      // }
      // this.endusers = [enduser];
      // this.parcels = [parcel];
      // console.log("this.endusers,this.parcels", this.endusers, this.parcels);
      let response = await this.$axios.post(
        this.$config.server_url + "/web/2.0/subscription/step2",
        { row_co: this.row_co } //, parcels: this.parcels, endusers: this.endusers
      );
      if (response.data.err) {
        console.warn("errrr ::", response.data.err);
        if (response.data.err.key == "name_mandatory")
          this.dialogErrTxt = "Nom et prénom obligatoire";
        else if (response.data.err.key == "address_mandatory")
          this.dialogErrTxt = "Adresse, code postal et ville obligatoire";
        else if (response.data.err.key == "contact_affiliation_code_invalid")
          this.dialogErrTxt = "Le code d'affiliation est invalide.";
        else if (response.data.err.key == "shipping_address_mandatory")
          this.dialogErrTxt =
            "Adresse, code postal et ville de livraison obligatoire";
        else this.dialogErrTxt = response.data.err.message;
        return;
      }
      if (this.row_co.co_box_inestablishment == "yes") this.get_step4();
      else this.get_step3();
    },
    async get_step4() {
      this.part = "part4";
    },
    async get_step3() {
      let response = await this.$axios.get(
        this.$config.server_url + "/web/2.0/subscription/step3",
        {
          params: {
            co_webaccesstoken_temp: this.$store.state.co_webaccesstoken_temp
          }
        }
      );
      // console.log("response.data.data", response.data.data);
      this.row_co = response.data.data;
      this.parcels = response.data.parcels;
      this.endusers = response.data.endusers;
      this.offer = response.data.offer;
      this.offerproduct = response.data.offerproduct;
      // console.log("offer, offerproduct", this.offer, this.offerproduct);
      this.part = "part3";
      this.toTop();
    },
    toTop() {
      document.getElementById("page_subscription").scrollIntoView();
    },
    async get_step2() {
      let response = await this.$axios.get(
        this.$config.server_url + "/web/2.0/subscription/step2",
        {
          params: {
            co_webaccesstoken_temp: this.$store.state.co_webaccesstoken_temp
          }
        }
      );
      // console.log("response.data.data", response.data.data);
      this.row_co = response.data.data;
      this.parcels = response.data.parcels;
      this.endusers = response.data.endusers;
      this.part = "part2";
      this.toTop();
    },
    async post_step1() {
      this.dialogErrTxt = "";
      if (this.co_password1 != this.co_password2) {
        this.dialogErrTxt = "Les mots de passe doivent être identique";
        return;
      }
      let response = await this.$axios.post(
        this.$config.server_url + "/web/2.0/subscription/step1",
        {
          co_login: this.co_login,
          co_password: this.co_password1
        },
        {
          headers: {
            // "x-auth-accesstoken": this.$store.state.user.co_accesstoken
          }
        }
      );
      // console.log("response.data.data", response.data);
      if (response.data.err) {
        if (response.data.err.key == "login_mandatory")
          this.dialogErrTxt = "Identifiant obligatoire";
        else if (response.data.err.key == "password_mandatory")
          this.dialogErrTxt = "Mot de passe obligatoire";
        else if (response.data.err.key == "email_format_error")
          this.dialogErrTxt = "Format d'email non valide";
        else if (response.data.err.key == "user_login_exists_already")
          this.dialogErrTxt = "Votre identifiant existe déjà";
        else if (response.data.err.key == "password_format_error")
          this.dialogErrTxt =
            "Le mot de passe nécessite au moins 8 caractères, une minuscule, une majuscule et un chiffre";
        else this.dialogErrTxt = response.data.err.message;
        return;
      }
      this.$store.commit(
        "set_co_webaccesstoken_temp",
        response.data.data.co_webaccesstoken_temp
      );
      this.get_step2();
      // response.data.data.
    },
    checkPhone(valide, co_phoneField) {
      if (co_phoneField === "co_shipping_tel") {
        this.shippingPhoneCheck = valide;
      } else if (co_phoneField === "co_invoice_tel") {
        this.billingPhoneCheck = valide;
      }
      console.log("SUBSCRIPTION :", co_phoneField, valide);
    },
    confirmDialog(title, body, confirm, close) {
      this.resolveDialog = new this.Deferred();
      this.dialog.title = title;
      this.dialog.body = body;
      this.dialog.confirm = confirm;
      this.dialog.close = close;
      this.dialog.display = true;
      return this.resolveDialog.promise;
    },
    Deferred() {
      var self = this;
      this.promise = new Promise(function(resolve, reject) {
        self.reject = reject;
        self.resolve = resolve;
      });
    },
    dialogAction(action) {
      if (action === "close") {
        this.dialog.display = false;
        this.resolveDialog.resolve(false);
      }
      if (action === "confirm") {
        this.dialog.display = false;
        this.resolveDialog.resolve(true);
      }
      this.resolveDialog = null;
    }
  }
};
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 50px;
  // padding-top: 40px;
}
</style>
