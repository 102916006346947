<template>
  <form name="contact" class="text-center" style="color: white">
    <div class="row">
      <div class="col-md-6 text-left">
        <h2 class="mb-3">Adresse de facturation</h2>
        <h5>Indiquer les coordonnées et l'adresse de facturation du client</h5>
        <div class="form-group">
          <label for="co_login">EMAIL</label>
          <input
            :disabled="disableLogin"
            name="co_invoice_email"
            type="text"
            class="form-control"
            id="co_invoice_email"
            v-model="row_co.co_invoice_email"
            style="border: none; border-radius: 0"
            @input="fillLoginInput"
          />
          <input
            name="co_invoice_email"
            type="hidden"
            id="co_login"
            v-model="row_co.co_login"
          />
        </div>
        <div class="form-group">
          <label for="co_invoice_civility">CIVILITÉ</label>
          <select
            name="co_invoice_civility"
            id="co_invoice_civility"
            v-model="row_co.co_invoice_civility"
            class="form-control"
            style="border: none; border-radius: 0"
            required
          >
            <option value="" selected disabled>Choissisez sa civilité</option>
            <option value="M">M</option>
            <option value="Mme">Mme</option>
          </select>
        </div>
        <div class="form-group">
          <label for="co_invoice_firstname">PRÉNOM</label>
          <input
            name="co_invoice_firstname"
            type="text"
            class="form-control"
            id="co_invoice_firstname"
            v-model="row_co.co_invoice_firstname"
            style="border: none; border-radius: 0"
          />
        </div>
        <div class="form-group">
          <label for="co_invoice_name">NOM</label>
          <input
            name="co_invoice_name"
            type="text"
            class="form-control"
            id="co_invoice_name"
            v-model="row_co.co_invoice_name"
            style="border: none; border-radius: 0"
          />
        </div>
        <div class="form-group">
          <label for="co_invoice_tel">TÉLÉPHONE PORTABLE</label>
          <input
            name="co_invoice_tel"
            type="text"
            class="form-control"
            id="co_invoice_tel"
            v-model="row_co.co_invoice_tel"
            style="border: none; border-radius: 0"
            @change="checkPhone($event.target.value, 'co_invoice_tel')"
          />
        </div>
        <div class="form-group">
          <label :for="`${$Utils.randomstring()}`">RECHERCHER L'ADRESSE</label>
          <input
            autocomplete="new-password"
            :name="`${$Utils.randomstring()}`"
            type="text"
            class="form-control"
            ref="address_research_invoice"
            placeholder="Tapez l'adresse complète..."
            style="border: none; border-radius: 0"
            v-on:input="checkAddress('invoice')"
            :disabled="billingAbroad"
          />
          <div class="form-check d-flex" style="margin: 15px 0">
            <input
              class="form-check-input col-1"
              type="checkbox"
              name="billing_abroad_checkbox"
              v-model="billingAbroad"
              style="height: auto"
              @click="row_co.co_invoice_country = 'FRA'"
            />
            <label
              class="form-check-label col-11 ml-2"
              for="billing_abroad_checkbox"
            >
              L'adresse est à l'étranger
            </label>
          </div>
          <div v-if="addressesList1" class="addresses-list mb-2">
            <div
              class="addresses-list-element"
              v-for="address in addresses1"
              :key="address.id"
              @click="applyAddress(address, 'invoice')"
            >
              {{ address.label }}
            </div>
          </div>
          <div>
            <input
              name="co_invoice_address1"
              type="text"
              class="form-control mb-2 mt-3"
              id="co_invoice_address1"
              v-model="row_co.co_invoice_address1"
              placeholder="Adresse"
              style="border: none; border-radius: 0"
              :disabled="!billingAbroad"
            />
            <input
              name="co_invoice_address2"
              type="text"
              class="form-control mb-2"
              id="co_invoice_address2"
              v-model="row_co.co_invoice_address2"
              placeholder="Complément d'adresse"
              style="border: none; border-radius: 0"
              :disabled="!row_co.co_invoice_address1"
            />
            <!-- <input name="co_country" type="text" class="form-control mb-2" id="country" placeholder="Pays"
                                                            value="France"> -->
            <select
              id="co_invoice_country"
              name="co_invoice_country"
              v-model="row_co.co_invoice_country"
              class="form-control mb-2"
              style="border: none; border-radius: 0"
              :disabled="!billingAbroad"
              ref="selectBilling"
              @change="
                row_co.co_invoice_country == 'FRA'
                  ? (billingAbroad = false)
                  : ''
              "
            >
              <option
                value="FRA"
                selected
                @click="row_co.co_invoice_country = 'FRA'"
              >
                France
              </option>
              <option
                :value="country.code"
                v-for="country in countries"
                v-bind:key="country.code"
              >
                {{ country.name }}
              </option>
            </select>
            <!-- <select id="co_us_states" style="display: none"></select> -->
            <div class="form-row">
              <div class="form-group col-md-4">
                <input
                  name="co_invoice_zip"
                  type="text"
                  class="form-control"
                  id="co_invoice_zip"
                  v-model="row_co.co_invoice_zip"
                  placeholder="Code postal"
                  style="border: none; border-radius: 0"
                  :disabled="!billingAbroad"
                />
              </div>
              <div class="form-group col-md-8">
                <input
                  name="co_invoice_city"
                  type="text"
                  class="form-control"
                  id="co_invoice_city"
                  placeholder="Ville"
                  v-model="row_co.co_invoice_city"
                  style="border: none; border-radius: 0"
                  :disabled="!billingAbroad"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-6 text-left">
        <h2 class="mb-3">Adresse de livraison</h2>
        <h5>Indiquer les coordonnées et l'adresse de livraison du client</h5>
        <div class="form-check d-flex mt-xl-4 mt-lg-5" style="margin: 15px 0">
          <input
            class="form-check-input col-1"
            type="checkbox"
            name="co_adresses_different"
            id="co_adresses_different"
            v-model="row_co.co_adresses_different"
            style="height: auto"
          />
          <label class="form-check-label col-11" for="co_adresses_different">
            ADRESSE DE LIVRAISON DIFFÉRENTE DE L'ADRESSE DE FACTURATION DU
            CLIENT
          </label>
        </div>
        <div class="form-group">
          <label for="co_shipping_civility">CIVILITÉ LIVRAISON</label>
          <select
            name="co_shipping_civility"
            id="co_shipping_civility"
            v-model="row_co.co_shipping_civility"
            class="form-control"
            style="border: none; border-radius: 0"
            :disabled="!row_co.co_adresses_different"
          >
            <option value="M">M</option>
            <option value="Mme">Mme</option>
          </select>
        </div>
        <div class="form-group">
          <p>
            Veuillez renseigner le nom de la société, si le client souhaite se
            faire livrer à son bureau, sinon laissez le champ "NOM DE LA
            SOCIÉTÉ" vide.
          </p>
          <label for="co_shipping_society_name">NOM DE LA SOCIÉTÉ</label>
          <input
            name="co_shipping_society_name"
            type="text"
            class="form-control"
            id="co_shipping_society_name"
            v-model="row_co.co_shipping_society_name"
            style="border: none; border-radius: 0"
            :disabled="!row_co.co_adresses_different"
          />
        </div>
        <div class="form-group">
          <label for="co_shipping_firstname">PRÉNOM LIVRAISON</label>
          <input
            name="co_shipping_firstname"
            type="text"
            class="form-control"
            id="co_shipping_firstname"
            v-model="row_co.co_shipping_firstname"
            style="border: none; border-radius: 0"
            :disabled="!row_co.co_adresses_different"
          />
        </div>
        <div class="form-group">
          <label for="co_shipping_name">NOM LIVRAISON</label>
          <input
            name="co_shipping_name"
            type="text"
            class="form-control"
            id="co_shipping_name"
            v-model="row_co.co_shipping_name"
            style="border: none; border-radius: 0"
            :disabled="!row_co.co_adresses_different"
          />
        </div>
        <div class="form-group">
          <label for="co_shipping_tel">TÉLÉPHONE PORTABLE LIVRAISON</label>
          <input
            name="co_shipping_tel"
            type="text"
            class="form-control"
            id="co_shipping_tel"
            v-model="row_co.co_shipping_tel"
            style="border: none; border-radius: 0"
            :disabled="!row_co.co_adresses_different"
            @change="checkPhone($event.target.value, 'co_shipping_tel')"
          />
        </div>
        <div class="form-group">
          <label for="address_research_shipping">ADRESSE LIVRAISON</label>
          <input
            autocomplete="new-password"
            :name="`${$Utils.randomstring()}`"
            type="text"
            class="form-control"
            ref="address_research_shipping"
            placeholder="Tapez l'adresse complète..."
            style="border: none; border-radius: 0"
            :disabled="checkCondition(this.shippingAbroad)"
            v-on:input="checkAddress('shipping')"
          />
          <div v-if="addressesList2" class="addresses-list mb-2">
            <div
              class="addresses-list-element"
              v-for="address in addresses2"
              :key="address.id"
              @click="applyAddress(address, 'shipping')"
            >
              {{ address.label }}
            </div>
          </div>
          <div class="form-check d-flex" style="margin: 15px 0">
            <input
              class="form-check-input col-1"
              type="checkbox"
              name="shipping_abroad_checkbox"
              v-model="shippingAbroad"
              style="height: auto"
              :disabled="!row_co.co_adresses_different"
              @click="row_co.co_shipping_country = 'FRA'"
            />
            <label
              class="form-check-label col-11 ml-2"
              for="shipping_abroad_checkbox"
            >
              L'adresse est à l'étranger
            </label>
          </div>
          <div>
            <input
              name="co_shipping_address1"
              type="text"
              class="form-control mb-2 mt-3"
              id="co_shipping_address1"
              v-model="row_co.co_shipping_address1"
              placeholder="Adresse 1"
              style="border: none; border-radius: 0"
              :disabled="checkCondition(!this.shippingAbroad)"
              v-on:input="checkAddress('shipping')"
            />
            <input
              name="co_shipping_address2"
              type="text"
              class="form-control mb-2"
              id="co_shipping_address2"
              v-model="row_co.co_shipping_address2"
              placeholder="Adresse 2 (optionnelle)"
              style="border: none; border-radius: 0"
              :disabled="!row_co.co_adresses_different"
            />
            <!-- <input name="co_country_family" type="text" class="form-control mb-2" id="family_country"
                                                            value="France" placeholder="Pays"> -->
            <select
              name="co_shipping_country"
              id="co_shipping_country"
              class="form-control mb-2"
              style="border: none; border-radius: 0"
              v-model="row_co.co_shipping_country"
              :disabled="checkCondition(!this.shippingAbroad)"
              ref="selectShipping"
              @change="
                row_co.co_shipping_country == 'FRA'
                  ? (shippingAbroad = false)
                  : ''
              "
            >
              <option value="FRA" selected>France</option>
              <option
                :value="country.code"
                v-for="country in countries"
                v-bind:key="country.code"
              >
                {{ country.name }}
              </option>
            </select>
            <!-- <select id="co_us_states_family" style="display: none"></select> -->
            <div class="form-row">
              <div class="form-group col-md-4">
                <input
                  name="co_shipping_zip"
                  type="text"
                  class="form-control"
                  id="co_shipping_zip"
                  v-model="row_co.co_shipping_zip"
                  placeholder="Code postal"
                  style="border: none; border-radius: 0"
                  :disabled="checkCondition(!this.shippingAbroad)"
                />
              </div>
              <div class="form-group col-md-8">
                <input
                  name="co_shipping_city"
                  type="text"
                  class="form-control"
                  id="co_shipping_city"
                  v-model="row_co.co_shipping_city"
                  placeholder="Ville"
                  style="border: none; border-radius: 0"
                  :disabled="checkCondition(!this.shippingAbroad)"
                />
              </div>
              <!-- <input
                  name="co_password"
                  type="text"
                  class="form-control"
                  id="co_password"
                  v-model="row_co.co_password"
                  placeholder="Pass"
                  style="border: none; border-radius: 0;"
                /> -->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-5 text-left">
        <h4 class="">
          Quel type de connexion à internet compte utiliser le client ?
        </h4>
        <select
          id="co_box_connection_type"
          name="co_box_connection_type"
          v-model="row_co.co_box_connection_type"
          class="form-control"
          style="border: none; border-radius: 0"
        >
          <option value="" disabled hidden>Choisir obligatoirement</option>
          <option value="-">Il ne sait pas encore</option>
          <option value="ETHERNET">Câble ethernet</option>
          <option value="WIFI">WiFi</option>
          <option value="CLE4G">Clé 4G</option>
          <!-- <option value="OTHER">Autre</option> -->
        </select>

        <h4 class="mt-4">
          Installe-t-il le kit Emotivi dans un établissement de santé ?
        </h4>
        <select
          id="co_box_inestablishment"
          name="co_box_inestablishment"
          v-model="row_co.co_box_inestablishment"
          class="form-control"
          style="border: none; border-radius: 0"
        >
          <option value="" disabled hidden>Choisir obligatoirement</option>
          <option value="no">Non, chez un particulier</option>
          <option value="yes">Oui, dans un établissement de santé</option>
        </select>

        <h4 class="mt-4">
          Sur quelle marque de télévision sera installé le kit Emotivi ?
        </h4>
        <select
          id="co_box_tvbrand"
          name="co_box_tvbrand"
          v-model="row_co.co_box_tvbrand"
          class="form-control"
          style="border: none; border-radius: 0"
        >
          <option value="" disabled hidden>Choisir obligatoirement</option>
          <option value="other">Autre</option>
          <option value="-">-------------</option>
          <option
            v-for="tvbrand in tvbrands"
            :key="tvbrand.key"
            :value="tvbrand.key"
          >
            {{ tvbrand.group }}
          </option>
        </select>
        <div class="form-group" v-if="row_co.co_box_tvbrand == 'other'">
          <input
            type="text"
            class="form-control mt-1"
            v-model="row_co.co_box_tvbrand_other"
            name="co_box_tvbrand_other"
            placeholder="Indiquez ici le modèle de télévision"
          />
          <small class="form-text"
            >Nous ne pouvons pas assurer la compatibilité parfaite de la
            télévision avec le kit Emotivi (vous serez intégralement remboursé
            en cas d'incompatibilité).</small
          >
        </div>

        <h4 class="mt-4">Comment le client a découvert Emotivi ?</h4>
        <select
          id="co_box_discover"
          name="co_box_discover"
          v-model="row_co.co_box_discover"
          class="form-control"
          style="border: none; border-radius: 0"
        >
          <option value="" disabled hidden>Choisir obligatoirement</option>
          <option value="GOOGLE">Google</option>
          <option value="BING">Bing</option>
          <option value="YAHOO">Yahoo</option>
          <option value="FACEBOOK">Facebook</option>
          <option value="LINKEDIN">Linkedin</option>
          <option value="PRESSE">Presse</option>
          <option value="BOUCHE">Bouche à oreille</option>
          <option value="AUTRE">Autre</option>
        </select>
        <div class="form-group" v-if="row_co.co_box_discover == 'AUTRE'">
          <input
            type="text"
            class="form-control mt-1"
            v-model="row_co.co_box_discover_other"
            name="co_box_discover_other"
            placeholder="Indiquez ici comment avez-vous découvert Emotivi"
          />
        </div>
        <h4 class="mt-4">Votre code d'affiliation</h4>
        <div class="form-group col-md-8">
          <div class="row">
            <input
              name="af_code"
              type="text"
              class="form-control"
              id="af_code"
              v-model="row_co.af_code"
              :placeholder="`Code d'affiliation ${affiliateCode}`"
              style="border: none; border-radius: 0"
              :readonly="true"
            />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
import { phone } from "phone";
export default {
  name: "formcontactaffiliate",
  components: {},
  props: {
    disableLogin: {
      default: function() {
        return true;
      },
      type: Boolean
    },
    row_co: {
      default: function() {
        return {};
      },
      type: Object
    }
  },
  data() {
    return {
      // adressesDifferent: false
      addresses1: [],
      addresses2: [],
      tvbrands: [],
      addressesList1: false,
      addressesList2: false,
      billingAbroad: false,
      shippingAbroad: false,
      countries: [
        { name: "Afghanistan", code: "AF" },
        { name: "Åland Islands", code: "AX" },
        { name: "Albania", code: "AL" },
        { name: "Algeria", code: "DZ" },
        { name: "American Samoa", code: "AS" },
        { name: "AndorrA", code: "AD" },
        { name: "Angola", code: "AO" },
        { name: "Anguilla", code: "AI" },
        { name: "Antarctica", code: "AQ" },
        { name: "Antigua and Barbuda", code: "AG" },
        { name: "Argentina", code: "AR" },
        { name: "Armenia", code: "AM" },
        { name: "Aruba", code: "AW" },
        { name: "Australia", code: "AU" },
        { name: "Austria", code: "AT" },
        { name: "Azerbaijan", code: "AZ" },
        { name: "Bahamas", code: "BS" },
        { name: "Bahrain", code: "BH" },
        { name: "Bangladesh", code: "BD" },
        { name: "Barbados", code: "BB" },
        { name: "Belarus", code: "BY" },
        { name: "Belgium", code: "BE" },
        { name: "Belize", code: "BZ" },
        { name: "Benin", code: "BJ" },
        { name: "Bermuda", code: "BM" },
        { name: "Bhutan", code: "BT" },
        { name: "Bolivia", code: "BO" },
        { name: "Bosnia and Herzegovina", code: "BA" },
        { name: "Botswana", code: "BW" },
        { name: "Bouvet Island", code: "BV" },
        { name: "Brazil", code: "BR" },
        { name: "British Indian Ocean Territory", code: "IO" },
        { name: "Brunei Darussalam", code: "BN" },
        { name: "Bulgaria", code: "BG" },
        { name: "Burkina Faso", code: "BF" },
        { name: "Burundi", code: "BI" },
        { name: "Cambodia", code: "KH" },
        { name: "Cameroon", code: "CM" },
        { name: "Canada", code: "CA" },
        { name: "Cape Verde", code: "CV" },
        { name: "Cayman Islands", code: "KY" },
        { name: "Central African Republic", code: "CF" },
        { name: "Chad", code: "TD" },
        { name: "Chile", code: "CL" },
        { name: "China", code: "CN" },
        { name: "Christmas Island", code: "CX" },
        { name: "Cocos (Keeling) Islands", code: "CC" },
        { name: "Colombia", code: "CO" },
        { name: "Comoros", code: "KM" },
        { name: "Congo", code: "CG" },
        { name: "Congo, The Democratic Republic of the", code: "CD" },
        { name: "Cook Islands", code: "CK" },
        { name: "Costa Rica", code: "CR" },
        { name: "Cote D'Ivoire", code: "CI" },
        { name: "Croatia", code: "HR" },
        { name: "Cuba", code: "CU" },
        { name: "Cyprus", code: "CY" },
        { name: "Czech Republic", code: "CZ" },
        { name: "Denmark", code: "DK" },
        { name: "Djibouti", code: "DJ" },
        { name: "Dominica", code: "DM" },
        { name: "Dominican Republic", code: "DO" },
        { name: "Ecuador", code: "EC" },
        { name: "Egypt", code: "EG" },
        { name: "El Salvador", code: "SV" },
        { name: "Equatorial Guinea", code: "GQ" },
        { name: "Eritrea", code: "ER" },
        { name: "Estonia", code: "EE" },
        { name: "Ethiopia", code: "ET" },
        { name: "Falkland Islands (Malvinas)", code: "FK" },
        { name: "Faroe Islands", code: "FO" },
        { name: "Fiji", code: "FJ" },
        { name: "Finland", code: "FI" },
        { name: "French Guiana", code: "GF" },
        { name: "French Polynesia", code: "PF" },
        { name: "French Southern Territories", code: "TF" },
        { name: "Gabon", code: "GA" },
        { name: "Gambia", code: "GM" },
        { name: "Georgia", code: "GE" },
        { name: "Germany", code: "DE" },
        { name: "Ghana", code: "GH" },
        { name: "Gibraltar", code: "GI" },
        { name: "Greece", code: "GR" },
        { name: "Greenland", code: "GL" },
        { name: "Grenada", code: "GD" },
        { name: "Guadeloupe", code: "GP" },
        { name: "Guam", code: "GU" },
        { name: "Guatemala", code: "GT" },
        { name: "Guernsey", code: "GG" },
        { name: "Guinea", code: "GN" },
        { name: "Guinea-Bissau", code: "GW" },
        { name: "Guyana", code: "GY" },
        { name: "Haiti", code: "HT" },
        { name: "Heard Island and Mcdonald Islands", code: "HM" },
        { name: "Holy See (Vatican City State)", code: "VA" },
        { name: "Honduras", code: "HN" },
        { name: "Hong Kong", code: "HK" },
        { name: "Hungary", code: "HU" },
        { name: "Iceland", code: "IS" },
        { name: "India", code: "IN" },
        { name: "Indonesia", code: "ID" },
        { name: "Iran, Islamic Republic Of", code: "IR" },
        { name: "Iraq", code: "IQ" },
        { name: "Ireland", code: "IE" },
        { name: "Isle of Man", code: "IM" },
        { name: "Israel", code: "IL" },
        { name: "Italy", code: "IT" },
        { name: "Jamaica", code: "JM" },
        { name: "Japan", code: "JP" },
        { name: "Jersey", code: "JE" },
        { name: "Jordan", code: "JO" },
        { name: "Kazakhstan", code: "KZ" },
        { name: "Kenya", code: "KE" },
        { name: "Kiribati", code: "KI" },
        { name: "Korea, Democratic People'S Republic of", code: "KP" },
        { name: "Korea, Republic of", code: "KR" },
        { name: "Kuwait", code: "KW" },
        { name: "Kyrgyzstan", code: "KG" },
        { name: "Lao People'S Democratic Republic", code: "LA" },
        { name: "Latvia", code: "LV" },
        { name: "Lebanon", code: "LB" },
        { name: "Lesotho", code: "LS" },
        { name: "Liberia", code: "LR" },
        { name: "Libyan Arab Jamahiriya", code: "LY" },
        { name: "Liechtenstein", code: "LI" },
        { name: "Lithuania", code: "LT" },
        { name: "Luxembourg", code: "LU" },
        { name: "Macao", code: "MO" },
        { name: "Macedonia, The Former Yugoslav Republic of", code: "MK" },
        { name: "Madagascar", code: "MG" },
        { name: "Malawi", code: "MW" },
        { name: "Malaysia", code: "MY" },
        { name: "Maldives", code: "MV" },
        { name: "Mali", code: "ML" },
        { name: "Malta", code: "MT" },
        { name: "Marshall Islands", code: "MH" },
        { name: "Martinique", code: "MQ" },
        { name: "Mauritania", code: "MR" },
        { name: "Mauritius", code: "MU" },
        { name: "Mayotte", code: "YT" },
        { name: "Mexico", code: "MX" },
        { name: "Micronesia, Federated States of", code: "FM" },
        { name: "Moldova, Republic of", code: "MD" },
        { name: "Monaco", code: "MC" },
        { name: "Mongolia", code: "MN" },
        { name: "Montserrat", code: "MS" },
        { name: "Morocco", code: "MA" },
        { name: "Mozambique", code: "MZ" },
        { name: "Myanmar", code: "MM" },
        { name: "Namibia", code: "NA" },
        { name: "Nauru", code: "NR" },
        { name: "Nepal", code: "NP" },
        { name: "Netherlands", code: "NL" },
        { name: "Netherlands Antilles", code: "AN" },
        { name: "New Caledonia", code: "NC" },
        { name: "New Zealand", code: "NZ" },
        { name: "Nicaragua", code: "NI" },
        { name: "Niger", code: "NE" },
        { name: "Nigeria", code: "NG" },
        { name: "Niue", code: "NU" },
        { name: "Norfolk Island", code: "NF" },
        { name: "Northern Mariana Islands", code: "MP" },
        { name: "Norway", code: "NO" },
        { name: "Oman", code: "OM" },
        { name: "Pakistan", code: "PK" },
        { name: "Palau", code: "PW" },
        { name: "Palestinian Territory, Occupied", code: "PS" },
        { name: "Panama", code: "PA" },
        { name: "Papua New Guinea", code: "PG" },
        { name: "Paraguay", code: "PY" },
        { name: "Peru", code: "PE" },
        { name: "Philippines", code: "PH" },
        { name: "Pitcairn", code: "PN" },
        { name: "Poland", code: "PL" },
        { name: "Portugal", code: "PT" },
        { name: "Puerto Rico", code: "PR" },
        { name: "Qatar", code: "QA" },
        { name: "Reunion", code: "RE" },
        { name: "Romania", code: "RO" },
        { name: "Russian Federation", code: "RU" },
        { name: "RWANDA", code: "RW" },
        { name: "Saint Helena", code: "SH" },
        { name: "Saint Kitts and Nevis", code: "KN" },
        { name: "Saint Lucia", code: "LC" },
        { name: "Saint Pierre and Miquelon", code: "PM" },
        { name: "Saint Vincent and the Grenadines", code: "VC" },
        { name: "Samoa", code: "WS" },
        { name: "San Marino", code: "SM" },
        { name: "Sao Tome and Principe", code: "ST" },
        { name: "Saudi Arabia", code: "SA" },
        { name: "Senegal", code: "SN" },
        { name: "Serbia and Montenegro", code: "CS" },
        { name: "Seychelles", code: "SC" },
        { name: "Sierra Leone", code: "SL" },
        { name: "Singapore", code: "SG" },
        { name: "Slovakia", code: "SK" },
        { name: "Slovenia", code: "SI" },
        { name: "Solomon Islands", code: "SB" },
        { name: "Somalia", code: "SO" },
        { name: "South Africa", code: "ZA" },
        { name: "South Georgia and the South Sandwich Islands", code: "GS" },
        { name: "Spain", code: "ES" },
        { name: "Sri Lanka", code: "LK" },
        { name: "Sudan", code: "SD" },
        { name: "Suriname", code: "SR" },
        { name: "Svalbard and Jan Mayen", code: "SJ" },
        { name: "Swaziland", code: "SZ" },
        { name: "Sweden", code: "SE" },
        { name: "Switzerland", code: "CH" },
        { name: "Syrian Arab Republic", code: "SY" },
        { name: "Taiwan, Province of China", code: "TW" },
        { name: "Tajikistan", code: "TJ" },
        { name: "Tanzania, United Republic of", code: "TZ" },
        { name: "Thailand", code: "TH" },
        { name: "Timor-Leste", code: "TL" },
        { name: "Togo", code: "TG" },
        { name: "Tokelau", code: "TK" },
        { name: "Tonga", code: "TO" },
        { name: "Trinidad and Tobago", code: "TT" },
        { name: "Tunisia", code: "TN" },
        { name: "Turkey", code: "TR" },
        { name: "Turkmenistan", code: "TM" },
        { name: "Turks and Caicos Islands", code: "TC" },
        { name: "Tuvalu", code: "TV" },
        { name: "Uganda", code: "UG" },
        { name: "Ukraine", code: "UA" },
        { name: "United Arab Emirates", code: "AE" },
        { name: "United Kingdom", code: "GB" },
        { name: "United States", code: "US" },
        { name: "United States Minor Outlying Islands", code: "UM" },
        { name: "Uruguay", code: "UY" },
        { name: "Uzbekistan", code: "UZ" },
        { name: "Vanuatu", code: "VU" },
        { name: "Venezuela", code: "VE" },
        { name: "Viet Nam", code: "VN" },
        { name: "Virgin Islands, British", code: "VG" },
        { name: "Virgin Islands, U.S.", code: "VI" },
        { name: "Wallis and Futuna", code: "WF" },
        { name: "Western Sahara", code: "EH" },
        { name: "Yemen", code: "YE" },
        { name: "Zambia", code: "ZM" },
        { name: "Zimbabwe", code: "ZW" }
      ]
    };
  },
  watch: {
    "row_co.af_code"(v) {
      this.row_co.af_code = this.row_co.af_code.trim();
      // console.log("row_co code affiliation", v);
    }
    // adressesDifferent: function(v) {
    //   this.row_co.co_adresses_different = v;
    // }
  },
  mounted() {
    this.getTvBrands();
    if (this.row_co.co_shipping_country != "FRA") this.shippingAbroad = true;
    if (this.row_co.co_invoice_country != "FRA") this.billingAbroad = true;
    if (this.row_co.co_invoice_tel)
      this.checkPhone(this.row_co.co_invoice_tel, "co_invoice_tel");
    if (this.row_co.co_shipping_tel)
      this.checkPhone(this.row_co.co_shipping_tel, "co_shipping_tel");

    if (this.affiliateCode && !this.row_co.af_code)
      this.row_co.af_code = this.affiliateCode;
  },
  computed: {
    affiliateCode() {
      return this.$store.state.affiliatedUser.af_code &&
        Object(this.$store.state.affiliatedUser.af_code).hasOwnProperty(
          "af_code"
        )
        ? this.$store.state.affiliatedUser.af_code.af_code
        : "";
    }
  },

  methods: {
    async checkAddress(where) {
      where == "invoice"
        ? (this.addressesList1 = true)
        : (this.addressesList2 = true);
      try {
        let response = await this.$axios.get(
          this.$config.server_url +
            "/web/2.0/contacts/address?co_webaccesstoken_temp=" +
            this.$store.state.co_webaccesstoken_temp +
            "&address=" +
            (where == "invoice"
              ? this.$refs.address_research_invoice.value
              : this.$refs.address_research_shipping.value)
        );
        // console.log("response :: ", response);

        if (where == "invoice") {
          this.addresses1 = [];
          response.data.data.forEach(address => {
            this.addresses1.push(address.properties);
          });
        } else if (where == "shipping") {
          this.addresses2 = [];
          response.data.data.forEach(address => {
            this.addresses2.push(address.properties);
          });
        }

        // console.log(" addresses : " + this.addresses);
      } catch (error) {
        console.warn(error);
      }
    },
    applyAddress(address, where) {
      if (where == "invoice") {
        this.row_co.co_invoice_zip = address.postcode;
        this.row_co.co_invoice_city = address.city;
        this.row_co.co_invoice_address1 = address.name;
        this.$refs.address_research_invoice.value = "";
        this.addressesList1 = false;
      } else if (where == "shipping") {
        this.row_co.co_shipping_zip = address.postcode;
        this.row_co.co_shipping_city = address.city;
        this.row_co.co_shipping_address1 = address.name;
        this.$refs.address_research_shipping.value = "";
        this.addressesList2 = false;
      }
      //event.target.parentElement.style.display = 'none';
    },
    async getTvBrands() {
      let response = await this.$axios.get(
        this.$config.server_url + "/web/2.0/tvbrands"
      );
      this.tvbrands = response.data.data;
      // boucler sur tvBrands / remplir le tab groupTV avec groupTv. ... / filtrer pour éviter les doublons
    },
    checkCondition(variant) {
      if (this.row_co.co_adresses_different && !variant) return false;
      else return true;
    },
    fillLoginInput(input) {
      this.row_co.co_login = input.target.value;
    },
    checkPhone(telephone, co_phoneField) {
      let result = phone(telephone, { validateMobilePrefix: false });
      console.log(result);
      this.$emit("checkphone", result.isValid, co_phoneField);
    }

    // changeTvBrands() {
    //   if (this.row_co.co_box_tvbrand == "other") {
    //   }
    // }
  }
};
</script>

<style lang="scss" scoped>
.addresses-list {
  background-color: white;
  color: black;
}
.addresses-list-element {
  padding: 10px;
  &:hover {
    background-color: rgb(226, 226, 226);
  }
}
input:disabled {
  background-color: rgb(218, 218, 218);
}
select:disabled {
  background-color: rgb(218, 218, 218);
}
</style>
