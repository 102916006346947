import axios from "axios";
// import config from "../config";
import store from "./store";
// import _ from "lodash";

const Utils = {
  openConfigurationSite(en_id, en_admincode, challenge) {
    // console.log(window.location.hostname);
    let url = "";
    if (window.location.hostname == "localhost") url = "http://localhost:4989";
    else if (window.location.hostname.indexOf(".dev.") > 0)
      url = "https://config-v2.dev.emotivi.io";
    else url = "https://config.emotivi.io";
    // window.open(url + "?en_id=" + en_id + "&challenge=" + challenge, "_blank");
    window.open(url + "/#/login/" + en_admincode, "_blank");
  },
  openWebappSite() {
    let url = "";
    if (window.location.hostname == "localhost") url = "http://localhost:4989";
    else if (window.location.hostname.indexOf(".dev.") > 0)
      url = "https://webapp-v2.dev.emotivi.io";
    else url = "https://webapp.emotivi.io";
    window.open(url + "", "_blank");
  },
  randomColor() {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },

  randomstring(keep = "", length = 12) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    if (keep) result = keep + "__" + result;
    // console.log('result',result);
    return result;
  },

  async wait(funcToTest, maxTime) {
    return new Promise(function(resolve, reject) {
      let timeoutTest, timeoutMaxTime;
      function test() {
        timeoutTest = window.setTimeout(() => {
          if (funcToTest()) {
            if (timeoutMaxTime) window.clearTimeout(timeoutMaxTime);
            return resolve(true);
          }
          test();
        }, 10);
      }
      timeoutMaxTime = window.setTimeout(() => {
        if (timeoutTest) window.clearTimeout(timeoutTest);
        console.warn("timeoutmax");
        resolve(false);
      }, maxTime);
      test();
    });
  },
  async doRequestIo(method, url, params) {
    try {
      let response = await axios[method](
        process.env.VUE_APP_BACKEND_URL + url,
        params ? params : null,
        {
          headers: {
            "x-auth-accesstoken": store.state.accesstoken,
            "Access-Control-Allow-Origin": "*"
          }
        }
      );
      return response.data;
    } catch (e) {
      console.error("Error Ajax REquest - :", e);
      return null;
    }
  }
};
export default Utils;
