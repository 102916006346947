var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("label", { staticClass: "m-form-checkbox", style: _vm.setstyle }, [
      _c("input", {
        attrs: { type: "checkbox", disabled: _vm.disabled, name: _vm.name2 },
        domProps: { checked: _vm.value },
        on: {
          input: function($event) {
            return _vm.setValue($event.target.checked)
          }
        }
      }),
      _vm._v(" " + _vm._s(_vm.label) + " ")
    ]),
    _vm.subText
      ? _c("div", { staticClass: "small-text" }, [_vm._v(_vm._s(_vm.subText))])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }