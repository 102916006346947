var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      staticClass: "text-center",
      staticStyle: { color: "white" },
      attrs: { name: "contact" }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-6 text-left" }, [
          _c("h2", { staticClass: "mb-3" }, [_vm._v("Adresse de facturation")]),
          _c("h5", [
            _vm._v(
              "Indiquer les coordonnées et l'adresse de facturation du client"
            )
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "co_login" } }, [_vm._v("EMAIL")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_invoice_email,
                  expression: "row_co.co_invoice_email"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                disabled: _vm.disableLogin,
                name: "co_invoice_email",
                type: "text",
                id: "co_invoice_email"
              },
              domProps: { value: _vm.row_co.co_invoice_email },
              on: {
                input: [
                  function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.row_co,
                      "co_invoice_email",
                      $event.target.value
                    )
                  },
                  _vm.fillLoginInput
                ]
              }
            }),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_login,
                  expression: "row_co.co_login"
                }
              ],
              attrs: {
                name: "co_invoice_email",
                type: "hidden",
                id: "co_login"
              },
              domProps: { value: _vm.row_co.co_login },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.row_co, "co_login", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "co_invoice_civility" } }, [
              _vm._v("CIVILITÉ")
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.row_co.co_invoice_civility,
                    expression: "row_co.co_invoice_civility"
                  }
                ],
                staticClass: "form-control",
                staticStyle: { border: "none", "border-radius": "0" },
                attrs: {
                  name: "co_invoice_civility",
                  id: "co_invoice_civility",
                  required: ""
                },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.row_co,
                      "co_invoice_civility",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c(
                  "option",
                  { attrs: { value: "", selected: "", disabled: "" } },
                  [_vm._v("Choissisez sa civilité")]
                ),
                _c("option", { attrs: { value: "M" } }, [_vm._v("M")]),
                _c("option", { attrs: { value: "Mme" } }, [_vm._v("Mme")])
              ]
            )
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "co_invoice_firstname" } }, [
              _vm._v("PRÉNOM")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_invoice_firstname,
                  expression: "row_co.co_invoice_firstname"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                name: "co_invoice_firstname",
                type: "text",
                id: "co_invoice_firstname"
              },
              domProps: { value: _vm.row_co.co_invoice_firstname },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.row_co,
                    "co_invoice_firstname",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "co_invoice_name" } }, [_vm._v("NOM")]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_invoice_name,
                  expression: "row_co.co_invoice_name"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                name: "co_invoice_name",
                type: "text",
                id: "co_invoice_name"
              },
              domProps: { value: _vm.row_co.co_invoice_name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.row_co, "co_invoice_name", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "co_invoice_tel" } }, [
              _vm._v("TÉLÉPHONE PORTABLE")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_invoice_tel,
                  expression: "row_co.co_invoice_tel"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                name: "co_invoice_tel",
                type: "text",
                id: "co_invoice_tel"
              },
              domProps: { value: _vm.row_co.co_invoice_tel },
              on: {
                change: function($event) {
                  return _vm.checkPhone($event.target.value, "co_invoice_tel")
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.row_co, "co_invoice_tel", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "" + _vm.$Utils.randomstring() } }, [
              _vm._v("RECHERCHER L'ADRESSE")
            ]),
            _c("input", {
              ref: "address_research_invoice",
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                autocomplete: "new-password",
                name: "" + _vm.$Utils.randomstring(),
                type: "text",
                placeholder: "Tapez l'adresse complète...",
                disabled: _vm.billingAbroad
              },
              on: {
                input: function($event) {
                  return _vm.checkAddress("invoice")
                }
              }
            }),
            _c(
              "div",
              {
                staticClass: "form-check d-flex",
                staticStyle: { margin: "15px 0" }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.billingAbroad,
                      expression: "billingAbroad"
                    }
                  ],
                  staticClass: "form-check-input col-1",
                  staticStyle: { height: "auto" },
                  attrs: { type: "checkbox", name: "billing_abroad_checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.billingAbroad)
                      ? _vm._i(_vm.billingAbroad, null) > -1
                      : _vm.billingAbroad
                  },
                  on: {
                    click: function($event) {
                      _vm.row_co.co_invoice_country = "FRA"
                    },
                    change: function($event) {
                      var $$a = _vm.billingAbroad,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.billingAbroad = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.billingAbroad = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.billingAbroad = $$c
                      }
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label col-11 ml-2",
                    attrs: { for: "billing_abroad_checkbox" }
                  },
                  [_vm._v(" L'adresse est à l'étranger ")]
                )
              ]
            ),
            _vm.addressesList1
              ? _c(
                  "div",
                  { staticClass: "addresses-list mb-2" },
                  _vm._l(_vm.addresses1, function(address) {
                    return _c(
                      "div",
                      {
                        key: address.id,
                        staticClass: "addresses-list-element",
                        on: {
                          click: function($event) {
                            return _vm.applyAddress(address, "invoice")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(address.label) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.row_co.co_invoice_address1,
                    expression: "row_co.co_invoice_address1"
                  }
                ],
                staticClass: "form-control mb-2 mt-3",
                staticStyle: { border: "none", "border-radius": "0" },
                attrs: {
                  name: "co_invoice_address1",
                  type: "text",
                  id: "co_invoice_address1",
                  placeholder: "Adresse",
                  disabled: !_vm.billingAbroad
                },
                domProps: { value: _vm.row_co.co_invoice_address1 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.row_co,
                      "co_invoice_address1",
                      $event.target.value
                    )
                  }
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.row_co.co_invoice_address2,
                    expression: "row_co.co_invoice_address2"
                  }
                ],
                staticClass: "form-control mb-2",
                staticStyle: { border: "none", "border-radius": "0" },
                attrs: {
                  name: "co_invoice_address2",
                  type: "text",
                  id: "co_invoice_address2",
                  placeholder: "Complément d'adresse",
                  disabled: !_vm.row_co.co_invoice_address1
                },
                domProps: { value: _vm.row_co.co_invoice_address2 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.row_co,
                      "co_invoice_address2",
                      $event.target.value
                    )
                  }
                }
              }),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.row_co.co_invoice_country,
                      expression: "row_co.co_invoice_country"
                    }
                  ],
                  ref: "selectBilling",
                  staticClass: "form-control mb-2",
                  staticStyle: { border: "none", "border-radius": "0" },
                  attrs: {
                    id: "co_invoice_country",
                    name: "co_invoice_country",
                    disabled: !_vm.billingAbroad
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.row_co,
                          "co_invoice_country",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        _vm.row_co.co_invoice_country == "FRA"
                          ? (_vm.billingAbroad = false)
                          : ""
                      }
                    ]
                  }
                },
                [
                  _c(
                    "option",
                    {
                      attrs: { value: "FRA", selected: "" },
                      on: {
                        click: function($event) {
                          _vm.row_co.co_invoice_country = "FRA"
                        }
                      }
                    },
                    [_vm._v(" France ")]
                  ),
                  _vm._l(_vm.countries, function(country) {
                    return _c(
                      "option",
                      { key: country.code, domProps: { value: country.code } },
                      [_vm._v(" " + _vm._s(country.name) + " ")]
                    )
                  })
                ],
                2
              ),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.row_co.co_invoice_zip,
                        expression: "row_co.co_invoice_zip"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: { border: "none", "border-radius": "0" },
                    attrs: {
                      name: "co_invoice_zip",
                      type: "text",
                      id: "co_invoice_zip",
                      placeholder: "Code postal",
                      disabled: !_vm.billingAbroad
                    },
                    domProps: { value: _vm.row_co.co_invoice_zip },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.row_co,
                          "co_invoice_zip",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "form-group col-md-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.row_co.co_invoice_city,
                        expression: "row_co.co_invoice_city"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: { border: "none", "border-radius": "0" },
                    attrs: {
                      name: "co_invoice_city",
                      type: "text",
                      id: "co_invoice_city",
                      placeholder: "Ville",
                      disabled: !_vm.billingAbroad
                    },
                    domProps: { value: _vm.row_co.co_invoice_city },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.row_co,
                          "co_invoice_city",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            ])
          ])
        ]),
        _c("div", { staticClass: "col-md-6 text-left" }, [
          _c("h2", { staticClass: "mb-3" }, [_vm._v("Adresse de livraison")]),
          _c("h5", [
            _vm._v(
              "Indiquer les coordonnées et l'adresse de livraison du client"
            )
          ]),
          _c(
            "div",
            {
              staticClass: "form-check d-flex mt-xl-4 mt-lg-5",
              staticStyle: { margin: "15px 0" }
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.row_co.co_adresses_different,
                    expression: "row_co.co_adresses_different"
                  }
                ],
                staticClass: "form-check-input col-1",
                staticStyle: { height: "auto" },
                attrs: {
                  type: "checkbox",
                  name: "co_adresses_different",
                  id: "co_adresses_different"
                },
                domProps: {
                  checked: Array.isArray(_vm.row_co.co_adresses_different)
                    ? _vm._i(_vm.row_co.co_adresses_different, null) > -1
                    : _vm.row_co.co_adresses_different
                },
                on: {
                  change: function($event) {
                    var $$a = _vm.row_co.co_adresses_different,
                      $$el = $event.target,
                      $$c = $$el.checked ? true : false
                    if (Array.isArray($$a)) {
                      var $$v = null,
                        $$i = _vm._i($$a, $$v)
                      if ($$el.checked) {
                        $$i < 0 &&
                          _vm.$set(
                            _vm.row_co,
                            "co_adresses_different",
                            $$a.concat([$$v])
                          )
                      } else {
                        $$i > -1 &&
                          _vm.$set(
                            _vm.row_co,
                            "co_adresses_different",
                            $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                          )
                      }
                    } else {
                      _vm.$set(_vm.row_co, "co_adresses_different", $$c)
                    }
                  }
                }
              }),
              _c(
                "label",
                {
                  staticClass: "form-check-label col-11",
                  attrs: { for: "co_adresses_different" }
                },
                [
                  _vm._v(
                    " ADRESSE DE LIVRAISON DIFFÉRENTE DE L'ADRESSE DE FACTURATION DU CLIENT "
                  )
                ]
              )
            ]
          ),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "co_shipping_civility" } }, [
              _vm._v("CIVILITÉ LIVRAISON")
            ]),
            _c(
              "select",
              {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.row_co.co_shipping_civility,
                    expression: "row_co.co_shipping_civility"
                  }
                ],
                staticClass: "form-control",
                staticStyle: { border: "none", "border-radius": "0" },
                attrs: {
                  name: "co_shipping_civility",
                  id: "co_shipping_civility",
                  disabled: !_vm.row_co.co_adresses_different
                },
                on: {
                  change: function($event) {
                    var $$selectedVal = Array.prototype.filter
                      .call($event.target.options, function(o) {
                        return o.selected
                      })
                      .map(function(o) {
                        var val = "_value" in o ? o._value : o.value
                        return val
                      })
                    _vm.$set(
                      _vm.row_co,
                      "co_shipping_civility",
                      $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                    )
                  }
                }
              },
              [
                _c("option", { attrs: { value: "M" } }, [_vm._v("M")]),
                _c("option", { attrs: { value: "Mme" } }, [_vm._v("Mme")])
              ]
            )
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("p", [
              _vm._v(
                ' Veuillez renseigner le nom de la société, si le client souhaite se faire livrer à son bureau, sinon laissez le champ "NOM DE LA SOCIÉTÉ" vide. '
              )
            ]),
            _c("label", { attrs: { for: "co_shipping_society_name" } }, [
              _vm._v("NOM DE LA SOCIÉTÉ")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_shipping_society_name,
                  expression: "row_co.co_shipping_society_name"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                name: "co_shipping_society_name",
                type: "text",
                id: "co_shipping_society_name",
                disabled: !_vm.row_co.co_adresses_different
              },
              domProps: { value: _vm.row_co.co_shipping_society_name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.row_co,
                    "co_shipping_society_name",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "co_shipping_firstname" } }, [
              _vm._v("PRÉNOM LIVRAISON")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_shipping_firstname,
                  expression: "row_co.co_shipping_firstname"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                name: "co_shipping_firstname",
                type: "text",
                id: "co_shipping_firstname",
                disabled: !_vm.row_co.co_adresses_different
              },
              domProps: { value: _vm.row_co.co_shipping_firstname },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.row_co,
                    "co_shipping_firstname",
                    $event.target.value
                  )
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "co_shipping_name" } }, [
              _vm._v("NOM LIVRAISON")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_shipping_name,
                  expression: "row_co.co_shipping_name"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                name: "co_shipping_name",
                type: "text",
                id: "co_shipping_name",
                disabled: !_vm.row_co.co_adresses_different
              },
              domProps: { value: _vm.row_co.co_shipping_name },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.row_co, "co_shipping_name", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "co_shipping_tel" } }, [
              _vm._v("TÉLÉPHONE PORTABLE LIVRAISON")
            ]),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_shipping_tel,
                  expression: "row_co.co_shipping_tel"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                name: "co_shipping_tel",
                type: "text",
                id: "co_shipping_tel",
                disabled: !_vm.row_co.co_adresses_different
              },
              domProps: { value: _vm.row_co.co_shipping_tel },
              on: {
                change: function($event) {
                  return _vm.checkPhone($event.target.value, "co_shipping_tel")
                },
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.row_co, "co_shipping_tel", $event.target.value)
                }
              }
            })
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("label", { attrs: { for: "address_research_shipping" } }, [
              _vm._v("ADRESSE LIVRAISON")
            ]),
            _c("input", {
              ref: "address_research_shipping",
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                autocomplete: "new-password",
                name: "" + _vm.$Utils.randomstring(),
                type: "text",
                placeholder: "Tapez l'adresse complète...",
                disabled: _vm.checkCondition(this.shippingAbroad)
              },
              on: {
                input: function($event) {
                  return _vm.checkAddress("shipping")
                }
              }
            }),
            _vm.addressesList2
              ? _c(
                  "div",
                  { staticClass: "addresses-list mb-2" },
                  _vm._l(_vm.addresses2, function(address) {
                    return _c(
                      "div",
                      {
                        key: address.id,
                        staticClass: "addresses-list-element",
                        on: {
                          click: function($event) {
                            return _vm.applyAddress(address, "shipping")
                          }
                        }
                      },
                      [_vm._v(" " + _vm._s(address.label) + " ")]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "form-check d-flex",
                staticStyle: { margin: "15px 0" }
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.shippingAbroad,
                      expression: "shippingAbroad"
                    }
                  ],
                  staticClass: "form-check-input col-1",
                  staticStyle: { height: "auto" },
                  attrs: {
                    type: "checkbox",
                    name: "shipping_abroad_checkbox",
                    disabled: !_vm.row_co.co_adresses_different
                  },
                  domProps: {
                    checked: Array.isArray(_vm.shippingAbroad)
                      ? _vm._i(_vm.shippingAbroad, null) > -1
                      : _vm.shippingAbroad
                  },
                  on: {
                    click: function($event) {
                      _vm.row_co.co_shipping_country = "FRA"
                    },
                    change: function($event) {
                      var $$a = _vm.shippingAbroad,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.shippingAbroad = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.shippingAbroad = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.shippingAbroad = $$c
                      }
                    }
                  }
                }),
                _c(
                  "label",
                  {
                    staticClass: "form-check-label col-11 ml-2",
                    attrs: { for: "shipping_abroad_checkbox" }
                  },
                  [_vm._v(" L'adresse est à l'étranger ")]
                )
              ]
            ),
            _c("div", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.row_co.co_shipping_address1,
                    expression: "row_co.co_shipping_address1"
                  }
                ],
                staticClass: "form-control mb-2 mt-3",
                staticStyle: { border: "none", "border-radius": "0" },
                attrs: {
                  name: "co_shipping_address1",
                  type: "text",
                  id: "co_shipping_address1",
                  placeholder: "Adresse 1",
                  disabled: _vm.checkCondition(!this.shippingAbroad)
                },
                domProps: { value: _vm.row_co.co_shipping_address1 },
                on: {
                  input: [
                    function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.row_co,
                        "co_shipping_address1",
                        $event.target.value
                      )
                    },
                    function($event) {
                      return _vm.checkAddress("shipping")
                    }
                  ]
                }
              }),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.row_co.co_shipping_address2,
                    expression: "row_co.co_shipping_address2"
                  }
                ],
                staticClass: "form-control mb-2",
                staticStyle: { border: "none", "border-radius": "0" },
                attrs: {
                  name: "co_shipping_address2",
                  type: "text",
                  id: "co_shipping_address2",
                  placeholder: "Adresse 2 (optionnelle)",
                  disabled: !_vm.row_co.co_adresses_different
                },
                domProps: { value: _vm.row_co.co_shipping_address2 },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(
                      _vm.row_co,
                      "co_shipping_address2",
                      $event.target.value
                    )
                  }
                }
              }),
              _c(
                "select",
                {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.row_co.co_shipping_country,
                      expression: "row_co.co_shipping_country"
                    }
                  ],
                  ref: "selectShipping",
                  staticClass: "form-control mb-2",
                  staticStyle: { border: "none", "border-radius": "0" },
                  attrs: {
                    name: "co_shipping_country",
                    id: "co_shipping_country",
                    disabled: _vm.checkCondition(!this.shippingAbroad)
                  },
                  on: {
                    change: [
                      function($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function(o) {
                            return o.selected
                          })
                          .map(function(o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.$set(
                          _vm.row_co,
                          "co_shipping_country",
                          $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        )
                      },
                      function($event) {
                        _vm.row_co.co_shipping_country == "FRA"
                          ? (_vm.shippingAbroad = false)
                          : ""
                      }
                    ]
                  }
                },
                [
                  _c("option", { attrs: { value: "FRA", selected: "" } }, [
                    _vm._v("France")
                  ]),
                  _vm._l(_vm.countries, function(country) {
                    return _c(
                      "option",
                      { key: country.code, domProps: { value: country.code } },
                      [_vm._v(" " + _vm._s(country.name) + " ")]
                    )
                  })
                ],
                2
              ),
              _c("div", { staticClass: "form-row" }, [
                _c("div", { staticClass: "form-group col-md-4" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.row_co.co_shipping_zip,
                        expression: "row_co.co_shipping_zip"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: { border: "none", "border-radius": "0" },
                    attrs: {
                      name: "co_shipping_zip",
                      type: "text",
                      id: "co_shipping_zip",
                      placeholder: "Code postal",
                      disabled: _vm.checkCondition(!this.shippingAbroad)
                    },
                    domProps: { value: _vm.row_co.co_shipping_zip },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.row_co,
                          "co_shipping_zip",
                          $event.target.value
                        )
                      }
                    }
                  })
                ]),
                _c("div", { staticClass: "form-group col-md-8" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.row_co.co_shipping_city,
                        expression: "row_co.co_shipping_city"
                      }
                    ],
                    staticClass: "form-control",
                    staticStyle: { border: "none", "border-radius": "0" },
                    attrs: {
                      name: "co_shipping_city",
                      type: "text",
                      id: "co_shipping_city",
                      placeholder: "Ville",
                      disabled: _vm.checkCondition(!this.shippingAbroad)
                    },
                    domProps: { value: _vm.row_co.co_shipping_city },
                    on: {
                      input: function($event) {
                        if ($event.target.composing) {
                          return
                        }
                        _vm.$set(
                          _vm.row_co,
                          "co_shipping_city",
                          $event.target.value
                        )
                      }
                    }
                  })
                ])
              ])
            ])
          ])
        ])
      ]),
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-xl-5 text-left" }, [
          _c("h4", {}, [
            _vm._v(
              " Quel type de connexion à internet compte utiliser le client ? "
            )
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_box_connection_type,
                  expression: "row_co.co_box_connection_type"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                id: "co_box_connection_type",
                name: "co_box_connection_type"
              },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.row_co,
                    "co_box_connection_type",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "", disabled: "", hidden: "" } }, [
                _vm._v("Choisir obligatoirement")
              ]),
              _c("option", { attrs: { value: "-" } }, [
                _vm._v("Il ne sait pas encore")
              ]),
              _c("option", { attrs: { value: "ETHERNET" } }, [
                _vm._v("Câble ethernet")
              ]),
              _c("option", { attrs: { value: "WIFI" } }, [_vm._v("WiFi")]),
              _c("option", { attrs: { value: "CLE4G" } }, [_vm._v("Clé 4G")])
            ]
          ),
          _c("h4", { staticClass: "mt-4" }, [
            _vm._v(
              " Installe-t-il le kit Emotivi dans un établissement de santé ? "
            )
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_box_inestablishment,
                  expression: "row_co.co_box_inestablishment"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: {
                id: "co_box_inestablishment",
                name: "co_box_inestablishment"
              },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.row_co,
                    "co_box_inestablishment",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "", disabled: "", hidden: "" } }, [
                _vm._v("Choisir obligatoirement")
              ]),
              _c("option", { attrs: { value: "no" } }, [
                _vm._v("Non, chez un particulier")
              ]),
              _c("option", { attrs: { value: "yes" } }, [
                _vm._v("Oui, dans un établissement de santé")
              ])
            ]
          ),
          _c("h4", { staticClass: "mt-4" }, [
            _vm._v(
              " Sur quelle marque de télévision sera installé le kit Emotivi ? "
            )
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_box_tvbrand,
                  expression: "row_co.co_box_tvbrand"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: { id: "co_box_tvbrand", name: "co_box_tvbrand" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.row_co,
                    "co_box_tvbrand",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "", disabled: "", hidden: "" } }, [
                _vm._v("Choisir obligatoirement")
              ]),
              _c("option", { attrs: { value: "other" } }, [_vm._v("Autre")]),
              _c("option", { attrs: { value: "-" } }, [
                _vm._v("-------------")
              ]),
              _vm._l(_vm.tvbrands, function(tvbrand) {
                return _c(
                  "option",
                  { key: tvbrand.key, domProps: { value: tvbrand.key } },
                  [_vm._v(" " + _vm._s(tvbrand.group) + " ")]
                )
              })
            ],
            2
          ),
          _vm.row_co.co_box_tvbrand == "other"
            ? _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.row_co.co_box_tvbrand_other,
                      expression: "row_co.co_box_tvbrand_other"
                    }
                  ],
                  staticClass: "form-control mt-1",
                  attrs: {
                    type: "text",
                    name: "co_box_tvbrand_other",
                    placeholder: "Indiquez ici le modèle de télévision"
                  },
                  domProps: { value: _vm.row_co.co_box_tvbrand_other },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.row_co,
                        "co_box_tvbrand_other",
                        $event.target.value
                      )
                    }
                  }
                }),
                _c("small", { staticClass: "form-text" }, [
                  _vm._v(
                    "Nous ne pouvons pas assurer la compatibilité parfaite de la télévision avec le kit Emotivi (vous serez intégralement remboursé en cas d'incompatibilité)."
                  )
                ])
              ])
            : _vm._e(),
          _c("h4", { staticClass: "mt-4" }, [
            _vm._v("Comment le client a découvert Emotivi ?")
          ]),
          _c(
            "select",
            {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.row_co.co_box_discover,
                  expression: "row_co.co_box_discover"
                }
              ],
              staticClass: "form-control",
              staticStyle: { border: "none", "border-radius": "0" },
              attrs: { id: "co_box_discover", name: "co_box_discover" },
              on: {
                change: function($event) {
                  var $$selectedVal = Array.prototype.filter
                    .call($event.target.options, function(o) {
                      return o.selected
                    })
                    .map(function(o) {
                      var val = "_value" in o ? o._value : o.value
                      return val
                    })
                  _vm.$set(
                    _vm.row_co,
                    "co_box_discover",
                    $event.target.multiple ? $$selectedVal : $$selectedVal[0]
                  )
                }
              }
            },
            [
              _c("option", { attrs: { value: "", disabled: "", hidden: "" } }, [
                _vm._v("Choisir obligatoirement")
              ]),
              _c("option", { attrs: { value: "GOOGLE" } }, [_vm._v("Google")]),
              _c("option", { attrs: { value: "BING" } }, [_vm._v("Bing")]),
              _c("option", { attrs: { value: "YAHOO" } }, [_vm._v("Yahoo")]),
              _c("option", { attrs: { value: "FACEBOOK" } }, [
                _vm._v("Facebook")
              ]),
              _c("option", { attrs: { value: "LINKEDIN" } }, [
                _vm._v("Linkedin")
              ]),
              _c("option", { attrs: { value: "PRESSE" } }, [_vm._v("Presse")]),
              _c("option", { attrs: { value: "BOUCHE" } }, [
                _vm._v("Bouche à oreille")
              ]),
              _c("option", { attrs: { value: "AUTRE" } }, [_vm._v("Autre")])
            ]
          ),
          _vm.row_co.co_box_discover == "AUTRE"
            ? _c("div", { staticClass: "form-group" }, [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.row_co.co_box_discover_other,
                      expression: "row_co.co_box_discover_other"
                    }
                  ],
                  staticClass: "form-control mt-1",
                  attrs: {
                    type: "text",
                    name: "co_box_discover_other",
                    placeholder:
                      "Indiquez ici comment avez-vous découvert Emotivi"
                  },
                  domProps: { value: _vm.row_co.co_box_discover_other },
                  on: {
                    input: function($event) {
                      if ($event.target.composing) {
                        return
                      }
                      _vm.$set(
                        _vm.row_co,
                        "co_box_discover_other",
                        $event.target.value
                      )
                    }
                  }
                })
              ])
            : _vm._e(),
          _c("h4", { staticClass: "mt-4" }, [
            _vm._v("Votre code d'affiliation")
          ]),
          _c("div", { staticClass: "form-group col-md-8" }, [
            _c("div", { staticClass: "row" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.row_co.af_code,
                    expression: "row_co.af_code"
                  }
                ],
                staticClass: "form-control",
                staticStyle: { border: "none", "border-radius": "0" },
                attrs: {
                  name: "af_code",
                  type: "text",
                  id: "af_code",
                  placeholder: "Code d'affiliation " + _vm.affiliateCode,
                  readonly: true
                },
                domProps: { value: _vm.row_co.af_code },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.$set(_vm.row_co, "af_code", $event.target.value)
                  }
                }
              })
            ])
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }