<template>
  <section>
    <div class="container pb-4">
      <div class="row">
        <div class="col-lg-4 flex-1" style="margin-top: 20px;">
          <div
            id="login_connect_webapp_tile"
            style="background-color: #3a3a3a; padding: 25px 25px; height:100%;"
          >
            <div style="margin-bottom: 15px;">
              <icon
                style="fill:white"
                name="comments"
                alt=""
                width="19%"
                height="66px"
              />
            </div>
            <h2 style="color: white; font-weight: bold; font-size: 30px;">
              COMMUNIQUER AVEC VOTRE SÉNIOR
            </h2>
            <div style="margin-top: 22px">
              <!-- <div class="form-group">
                     <label for="co_login">IDENTIFIANT SÉNIOR</label>
                     <input type="text" class="form-control" id="co_login" name="co_login" placeholder="Identifiant"
                        value="" required style="border: none; border-radius: 0;" />
                  </div>
                  <div class="form-group">
                     <label for="ev_pass">MOT DE PASSE</label>
                     <input type="text" class="form-control" id="ev_pass" name="ev_pass" placeholder="Identifiant"
                        value="" required style="border: none; border-radius: 0;" />
                  </div> -->
              <div class="d-flex flex-grow-1 mt-5 justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="connectWebapp"
                >
                  ACCÉDER A EMOTIVI
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 flex-1" style="margin-top: 20px;">
          <div
            id="login_connect_emotivi_tile"
            style="background-color: #159cac; padding: 25px 25px; height:100%;"
          >
            <div style="margin-bottom: 15px;">
              <img src="/images/ico-simplicite.png" alt="" width="19%" />
            </div>
            <h2 style="color: white; font-weight: bold; font-size: 30px;">
              GÉRER VOTRE ABONNEMENT
            </h2>
            <div style="margin-top: 20px">
              <div class="form-group">
                <label for="co_login">VOTRE EMAIL</label>
                <input
                  type="text"
                  class="form-control"
                  id="co_login"
                  name="co_login"
                  placeholder="Identifiant (eMail)"
                  v-model="co_login"
                  required
                  style="border: none; border-radius: 0;"
                />
              </div>
              <div class="form-group mt-4">
                <label for="co_password">VOTRE MOT DE PASSE</label>
                <input
                  type="password"
                  class="form-control"
                  id="co_password"
                  name="co_password"
                  placeholder="Mot de passe"
                  v-model="co_password"
                  required
                  style="border: none; border-radius: 0;"
                />
              </div>
              <div
                v-if="dialogErrTxt"
                class="alert alert-danger"
                v-html="dialogErrTxt"
              ></div>
              <!-- <div class="alert alert-success"></div> -->
              <div class="d-flex justify-content-end" style="margin-top:-10px">
                <a
                  @click="resetPassword()"
                  href="javascript:void(0);"
                  style="color:white;"
                >
                  Mot de passe oublié
                </a>
              </div>

              <div class="d-flex flex-grow-1 mt-2 justify-content-end">
                <button type="button" class="btn btn-primary" @click="login()">
                  SE CONNECTER
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-4 flex-1" style="margin-top: 20px;">
          <div
            id="login_register_tile"
            class="d-flex flex-column"
            style="background-color: #6ccdb2; padding: 25px 25px; height:100%;"
          >
            <div style="margin-bottom: 15px;">
              <img src="/images/ico-interface.png" alt="" width="18%" />
            </div>
            <h2 style="color: white; font-weight: bold; font-size: 30px;">
              COMMANDER VOTRE KIT EMOTIVI
            </h2>
            <div style="margin-top:22px">
              <div class="d-flex flex-grow-1 mt-5 justify-content-center">
                <button
                  class="btn btn-primary"
                  type="button"
                  @click="goToSubscription()"
                >
                  COMMANDER
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalResetPassword">
          Tester modal reset password
        </button> -->
        <div id="modalResetPassword" class="modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                  <div class="col-1">
                    <icon name="check-circle" scale="3"></icon>
                  </div>
                  <div class="col-9 ml-4">
                    Un email vous a été envoyé afin de changer votre mot de
                    passe.
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-center">
                <button class="btn-modal" type="button" data-dismiss="modal">
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "login",
  components: {},
  data() {
    return { co_login: "", co_password: "", dialogErrTxt: "" };
  },
  mounted() {},
  methods: {
    connectWebapp() {
      this.$Utils.openWebappSite();
    },
    async resetPassword() {
      this.dialogErrTxt = "";
      this.$$("#modalResetPassword").modal("hide");
      if (this.co_login) {
        try {
          let response = await this.$axios.post(
            this.$config.server_url + "/web/2.0/signin/forgetpassword",
            { co_login: this.co_login }
          );
          if (response.data.err) {
            try {
              let response2 = await this.$axios.post(
                "https://api.emotivi.fr/web/1.0/sendResetPasswordMail",
                { login: this.co_login, emotivi_web: true }
              );
              if (response2 && response2.data.email) {
              } else {
                this.dialogErrTxt =
                  "L'adresse email que vous avez rentré n'existe pas.";
                return;
              }
            } catch (error) {
              this.dialogErrTxt =
                "L'adresse email que vous avez rentré n'existe pas.";
              return;
            }
          }
          this.$$("#modalResetPassword").modal("show");
        } catch (err) {
          console.error("error", err);
          this.dialogErrTxt =
            "Un problème est survenu. Veuillez réessayer dans quelques minutes.";
        }
      } else {
        this.dialogErrTxt =
          "Veuillez rentrer votre adresse email pour changer votre mot de passe.";
      }
    },
    // gotoSubscription() {
    //   this.$router.push("/subscription");
    // },
    goToSubscription() {
      window.open(this.$config.web_url_subscription, "_self");
    },
    async newpassword() {
      //if (!this.challenge_newpassword) return;
      /*** vérification du mot de passe */
      if (!this.co_password1 || !this.co_password2) {
        this.dialogErrTxt = "Vous devez choisir un mot de passe";
        this.dialogErr = true;
      }
      if (this.co_password1 !== this.co_password2) {
        this.dialogErrTxt = "les mots de passe doivent être identiques";
        this.dialogErr = true;
      }
      if (!this.dialogErr) {
        let response = await this.$axios.post(
          this.$config.server_url +
            "/web/2.0/signin/forgetpassword/newpassword",
          {
            co_password: this.co_password1,
            co_id: this.co_id,
            challenge: this.challenge_newpassword
          }
        );
        if (response.data.err) {
          this.alert_txt_part4 = "Erreur";
        } else {
          this.currentPart = "part5";
        }
      }
    },
    async checkchallenge(co_id, challenge) {
      this.co_id = co_id;
      let response = await this.$axios.post(
        this.$config.server_url +
          "/web/2.0/signin/forgetpassword/checkchallenge",
        { co_id, challenge }
      );
      if (response.data.err) {
        this.alert_txt_part4 = "Erreur - Cette page n'est plus accessible";
      } else {
        // this.currentPart = "part3";
        this.challenge_newpassword = response.data.data.challenge;
      }
    },
    async sendPassword() {
      let response = await this.$axios.post(
        this.$config.server_url + "/web/2.0/signin/forgetpassword",
        { co_login: this.co_login }
      );
      if (response.data.err) {
        this.alert_txt_part2 =
          "Nous n'avons pas pu retrouver votre identifiant";
      } else {
        this.currentPart = "part3";
      }
    },
    // async loadAppData() {
    //   let response = await this.$axios.get(
    //     this.$config.server_url + "/api/1.0/app-datas",
    //     {}
    //   );
    //   this.societyName = response.data.data.societyName;
    //   this.logoUrl = response.data.data.logoUrl;
    // },
    async login() {
      this.dialogErrTxt = "";
      try {
        let response = await this.$axios.post(
          this.$config.server_url + "/web/2.0/signin",
          { co_login: this.co_login, co_password: this.co_password }
        );
        // console.log("response.data", response.data);
        if (response.data.err) {
          if (response.data.err.key == "no_caution_paid") {
            this.dialogErrTxt = `Vous n'avez pas fini l'inscription et le paiement. Veuillez reprendre l'inscription dans <a href="${this.$config.web_url_subscription}">Commander votre kit</a>`;
            return;
          }

          // check V1
          let responseV1 = await this.$axios.post(
            this.$config.server_url_v1 + "/web/1.0/login",
            { co_email_family: this.co_login, co_password: this.co_password }
          );
          if (responseV1.data) {
            //console.log("logged : ", responseV1.data);
            // if (response.data.co_refresh_token_emotivi) {
            //    localStorage.setItem('co_refresh_token_emotivi', response.data.co_refresh_token_emotivi);
            // }

            document.cookie =
              "domain=" +
              this.$config.web_v1 +
              ";co_refresh_token_emotivi=" +
              responseV1.data.co_refresh_token_emotivi;

            document.cookie =
              "domain=" +
              this.$config.web_v1 +
              ";co_token_emotivi=" +
              responseV1.data.co_token_emotivi;

            return window.open(this.$config.web_v1 + "/account", "_self");
          } else {
            this.dialogErrTxt =
              "Un problème est survenu. Votre identifiant ou votre mot de passe est incorrect.";
          }
          return;
        }
        // this.alert_txt = "Vous êtes connecté";
        // this.alert_type = "success";
        // this.$store.commit("set_token", response.data.accesstoken);
        // this.$store.commit("set_refreshtoken", response.data.refreshtoken);
        // this.$store.commit("set_user", response.data.data);
        this.$store.commit("set_connexion", {
          accesstoken: response.data.data.accesstoken,
          // refreshtoken: response.data.refreshtoken,
          user: response.data.data.row,
          preferences: response.data.data.preferences
        });
        //   this.$root.$children[0].showMenus();
        this.$router.push("/private/home");
      } catch (error) {
        console.error("error", error);
        this.dialogErrTxt =
          "Un problème est survenu. Votre identifiant ou votre mot de passe est incorrect.";

        // this.alert_txt = "Erreur!!!";
        // this.alert_type = "warning";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.img-fluid {
  width: 100%;
  height: auto;
}
.container2-form-login {
  width: 80%;
}
.links {
  align-items: flex-end;
}
.btn-modal {
  width: 70px;
  height: 30px;
  background-color: #1876eb;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  &:hover {
    background-color: #3482e0;
  }
}
</style>
