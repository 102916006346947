<template>
  <section>
    <div
      class="container pb-5 containercolored"
      style="background-color: rgb(24, 155, 176)"
    >
      <div class="row mb-3">
        <div class="col">
          <div class="row align-items-end">
            <div class="col-md-12 col-lg-5 pt-4">
              <div class="mb-3">
                <img
                  src="/images/ico-interface.png"
                  alt=""
                  style="width: 70px"
                />
              </div>
              <div>
                <h2>MES ABONNEMENTS</h2>
              </div>
            </div>

            <div class="col-4 col-sm-4 col-lg-2 text-center mb-3 mt-2">
              <h4
                class="part-subtitle-private"
                @click="$router.push('/private/account')"
              >
                MON<br />COMPTE
              </h4>
            </div>

            <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/box')"
              v-if="hasBoxes"
            >
              <h4 class="part-subtitle-private">MES KITS<br />EMOTIVI</h4>
            </div>
            <!-- <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/purchasekey4g')"
            >
              <h4 class="part-subtitle-private">COMMANDER<br />UNE CLE 4G</h4>
            </div> -->
            <!--div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/purchaserouter4g')"
            >
              <h4 class="part-subtitle-private">
                COMMANDER<br />UN ROUTEUR 4G
              </h4>
            </div-->
            <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/tv')"
              v-if="hasTvs"
            >
              <h4 class="part-subtitle-private">MES TVs<br />EMOTIVI</h4>
            </div>
            <!-- <div
              id="documentation"
              class="d-flex pt-4 flex-wrap col"
              style="top: 0; right: 0"
            >
              <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="/files/Mode_d_emploi_v2.15_8.12.21.pdf"
                  >Documentation installation
                </a>
              </div>
              <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="/files/Aide_utilisateur-Emotivi-20200220.pdf?v=1"
                  >Documentation utilisateur
                </a>
              </div>
              <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="https://www.youtube.com/watch?v=I3KLLo-sQzo"
                  >Configurer Emotivi
                </a>
              </div>
            </div> -->
            <!-- <div class="col-sm-4 col-lg-2 text-center mb-3">
              <h4
                class="part-subtitle-private"
                @click="$Utils.openConfigurationSite()"
              >
                CONFIGURATION<br />DE LA BOX
              </h4>
            </div> -->
          </div>
        </div>
      </div>

      <div class="row align-items-center" v-if="displaySelectEnduser">
        <div class="col-sm-9 col-md-9 col-lg-9 col-xl-9">
          <m-form-select
            :name="$Utils.randomstring('selectEndusers')"
            v-model="en_id"
            label="Pour quel kit Emotivi souhaitez-vous modifier le mode de paiement ?"
            :items="selectEndusers"
          ></m-form-select>
        </div>
        <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
          <button @click="proceedChangePayment" class="btn btn-secondary ml-3">
            VALIDER
          </button>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p
            v-show="$route.query.gocardlessSuccess"
            class="alert alert-success"
          >
            Votre IBAN a été enregistré avec succès. L'enregistrement de vos
            coordonnées bancaires peut prendre quelques minutes.
          </p>
          <p
            v-show="$route.query.gocardlessCancelled"
            class="alert alert-danger"
          >
            Votre IBAN n'a pas pu être enregistré suite à une erreur ou une
            annulation.
          </p>
          <h3 class="">
            <icon name="credit-card" scale="2" fill="white" />
            Mes paiements
          </h3>
          <div v-if="isLoading" class="text-center">
            <div class="spinner-border text-light" role="status">
              <span class="sr-only">Chargement...</span>
            </div>
          </div>
          <div v-else>
            <div class="alert alert-light">
              <p>Tous les paiements effectués.</p>
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th class="text-info" scope="col">Nom</th>
                    <th class="text-info" scope="col">Statut</th>
                    <th class="text-info" scope="col">Mode de paiement</th>
                    <th class="text-info" scope="col">Date</th>
                    <th class="text-info" scope="col">Total TTC</th>
                    <th
                      v-show="screenWidth > 767"
                      class="text-info text-right"
                      scope="col"
                    >
                      Facture
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(row_or, indexRow_or) in rows_or"
                    :key="indexRow_or"
                  >
                    <td class="text-secondary">
                      {{ row_or.or_name }}
                    </td>
                    <td class="text-secondary">
                      {{ getOrderStatus(row_or.or_status) }}
                    </td>
                    <td class="text-secondary">
                      {{ row_or.or_paymentmethod }}
                    </td>
                    <td class="text-secondary">
                      {{ $moment(row_or.or_date).format("DD/MM/YYYY") }}
                    </td>
                    <td class="text-secondary">
                      {{ Math.ceil((row_or.or_pricettc / 100) * 100) / 100 }}
                      €
                    </td>

                    <td
                      v-show="screenWidth > 767"
                      class="text-secondary text-right"
                    >
                      <button
                        class="col-12 col-sm-12 col-md-12 col-lg col-xl btn btn-primary"
                        style="color: white"
                        @click="printOrder(row_or, $event)"
                      >
                        <icon
                          name="print"
                          style="cursor: pointer; fill: white"
                        ></icon>
                        Facture
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div v-if="es_type != 3">
              <div
                v-for="(enduser, indexEnduser) in endusersData"
                :key="indexEnduser"
              >
                <div class="alert alert-light">
                  <h5>BOX - {{ enduser.row_en.en_fullname }}</h5>
                  <div v-if="enduser.charges && enduser.charges.length">
                    <div
                      class="row mb-3 flex-column"
                      v-if="enduser.charges.length > 0"
                    >
                      <div class="col d-flex flex-wrap">
                        <button
                          class="col-12 col-sm-12 col-md-12 col-lg col-xl btn btn-primary m-1"
                          @click="beforeStripePaymentMethodUpdate(enduser.row_en)"
                        >
                          METTRE À JOUR LE MOYEN DE PAIEMENT
                        </button>

                        <!--button
                          class="col-12 col-sm-12 col-md-12 col-lg col-xl btn btn-primary m-1"
                          @click="beforeChangePayment('rib', enduser.row_en)"
                        >
                          {{
                            enduser.rows_pm.find(
                              (row_pm) =>
                                row_pm.pm_active && row_pm.pm_method === "rib"
                            )
                              ? "MODIFIER MON IBAN"
                              : "PAYER PAR PRELEVEMENT BANCAIRE"
                          }}
                        </button-->


                        <!-- <button
                        v-if="
                          !enduser.rows_pm.find(
                            (row_pm) =>
                              row_pm.pm_active && row_pm.pm_method === 'rib'
                          )
                        "
                        class="
                          col-12 col-sm-12 col-md-12 col-lg col-xl
                          btn btn-primary
                          m-1
                        "
                        @click="beforeChangePayment('cb', enduser.row_en)"
                      >
                        MODIFIER LA CB
                      </button> -->
                        <button
                          class="col-12 col-sm-12 col-md-12 col-lg col-xl btn btn-primary m-1"
                          @click="openFormAttestationFiscale(enduser)"
                          v-if="taxPeriod"
                        >
                          DEMANDER UNE ATTESTATION FISCALE
                        </button>
                      </div>
                      <div class="col mt-2">
                        <p>
                          L'enregistrement de vos coordonnées bancaires peut
                          prendre quelques minutes.
                        </p>
                        <table class="table table-sm">
                          <thead>
                            <tr>
                              <th class="text-info" scope="col">
                                Date d'enregistrement
                              </th>
                              <th class="text-info" scope="col">Mode</th>
                              <th
                                v-show="screenWidth > 767"
                                class="text-info"
                                scope="col"
                              >
                                Détails
                              </th>
                              <th
                                v-show="screenWidth > 767"
                                class="text-info"
                                scope="col"
                              >
                                Statut
                              </th>
                              <th
                                v-show="screenWidth > 400"
                                class="text-info"
                                scope="col"
                              >
                                Nom
                              </th>
                              <th class="text-info" scope="col">Actif</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr
                              v-for="(pm, indexPm) in enduser.rows_pm"
                              :key="indexPm"
                            >
                              <td class="text-secondary">
                                <small>{{
                                  $moment(pm.createAt).isValid()
                                    ? $moment(pm.createdAt).format(
                                        "DD/MM/YYYY [à] HH:mm"
                                      )
                                    : ""
                                }}</small>
                              </td>
                              <td class="text-secondary">
                                <small>
                                  {{ getPaymentMethodName(pm.pm_method) }} - {{
                                    pm.pm_gocardless_ids 
                                      ? "GoCardless"
                                      : (pm.pm_stripe_ids 
                                        ? "Stripe"
                                        : "Banque")
                                  }}
                                </small>
                              </td>
                              <td
                                v-show="screenWidth > 767"
                                class="text-secondary"
                              >
                                <small>

                                  {{
                                    pm.pm_method_details &&
                                    pm.pm_method === "rib" &&
                                    pm.pm_method_details.last4
                                      ? "iban : ... " + pm.pm_method_details.last4
                                      : ""
                                  }}

                                  {{
                                    pm.pm_method_details &&
                                    pm.pm_method === "cb" &&
                                    pm.pm_method_details.last4
                                      ? pm.pm_method_details.brand +
                                        " : ... " +
                                        pm.pm_method_details.last4
                                      : ""
                                  }}

                                  {{
                                    pm.pm_method_details &&
                                    pm.pm_method === "cb" &&
                                    pm.pm_method_details.card &&
                                    pm.pm_method_details.card.last4
                                      ? pm.pm_method_details.card.brand +
                                        " : " +
                                        pm.pm_method_details.card.last4
                                      : ""
                                  }}

                                  {{
                                    pm.pm_method_details &&
                                    pm.pm_method === "rib" &&
                                    pm.pm_method_details.customer_bank_account
                                      ? pm.pm_method_details
                                          .customer_bank_account.bank_name +
                                        " - iban : ... " +
                                        pm.pm_method_details
                                          .customer_bank_account
                                          .account_number_ending
                                      : ""
                                  }}
                                  
                                  </small>
                              </td>
                              <td
                                v-show="screenWidth > 767"
                                class="text-secondary"
                              >
                                <small>{{
                                  paymentMethodsStatusText[pm.pm_status]
                                }}</small>
                              </td>
                              <td
                                v-show="screenWidth > 400"
                                class="text-secondary"
                              >
                                <small>{{
                                  pm.pm_rib_name ? pm.pm_rib_name : pm.pm_name
                                }}</small>
                              </td>
                              <td
                                class="font-weight-bold"
                                :style="
                                  pm.pm_active ? 'color:green' : 'color:red'
                                "
                              >
                                <small>{{
                                  pm.pm_active ? "Oui" : "Non"
                                }}</small>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div
                      class="row align-items-center"
                      v-if="displaySelectEnduser"
                    >
                      <div class="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                        <m-form-select
                          :name="$Utils.randomstring('selectEndusers')"
                          v-model="en_id"
                          label="Pour quel kit Emotivi souhaitez-vous modifier le mode de paiement ?"
                          :items="selectEndusers"
                        ></m-form-select>
                      </div>
                      <div class="col-sm-3 col-md-3 col-lg-3 col-xl-3">
                        <button
                          @click="proceedChangePayment"
                          class="btn btn-secondary ml-3"
                        >
                          VALIDER
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    v-if="!enduser.charges || !enduser.charges.length"
                    class="alert alert-danger"
                  >
                    Vous n'avez pas encore payé la caution
                    <button
                      class="btn btn-primary ml-4 uppercase"
                      type="button"
                      @click="payDeposit()"
                    >
                      PAYER LA CAUTION
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="es_type != 3">
            <h3>
              <!-- <icon name="arrow-right" scale="2" fill="white" /> -->
              <img
                style="filter: grayscale(25%) brightness(1.25)"
                src="/images/logo-emotivi-solo-small.png?v=1"
              />
              Mes abonnements
            </h3>
            <div v-if="isLoading" class="text-center">
              <div class="spinner-border text-light" role="status">
                <span class="sr-only">Chargement...</span>
              </div>
            </div>
            <div v-else>
              <div
                v-for="(enduser, indexEnduser) in endusersData"
                :key="indexEnduser"
              >
                <div class="alert alert-light">
                  <h5>BOX - {{ enduser.row_en.en_fullname }}</h5>
                  <div
                    v-if="
                      !enduser.subscriptions ||
                      enduser.subscriptions.length === 0
                    "
                    class="alert alert-info"
                  >
                    Votre abonnement n'a pas encore débuté. Il débutera un mois
                    après la première installation.
                  </div>
                  <table class="table table-sm" v-else>
                    <thead>
                      <tr>
                        <!-- <th scope="col">Description</th> -->
                        <th class="text-info" scope="col">Début abonnement</th>
                        <th class="text-info" scope="col">Fin abonnement</th>
                        <th
                          v-show="screenWidth > 400"
                          class="text-info"
                          scope="col"
                        >
                          Mode de paiement
                        </th>
                        <th class="text-info" scope="col">Prix</th>
                        <th
                          v-show="screenWidth > 767"
                          class="text-info text-right"
                          scope="col"
                        >
                          État de l'abonnement
                        </th>

                        <!-- <th>&nbsp;</th>
                  <th>&nbsp;</th> -->
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(sub, indexSub) in enduser.subscriptions"
                        :key="indexSub"
                      >
                        <!-- <td>{{ sub.description }}</td> -->
                        <td class="text-secondary">
                          {{
                            sub.su_startdate
                              ? $moment(sub.su_startdate).format("DD/MM/YYYY")
                              : $moment
                                  .unix(sub.start_date)
                                  .format("DD/MM/YYYY")
                          }}
                        </td>
                        <td class="text-secondary">
                          <span
                            v-if="
                              $moment(sub.su_enddate).isValid() ||
                              $moment.unix(sub.cancel_at).isValid()
                            "
                          >
                            {{
                              sub.su_enddate
                                ? $moment(sub.su_enddate).format("DD/MM/YYYY")
                                : $moment
                                    .unix(sub.cancel_at)
                                    .format("DD/MM/YYYY")
                            }}</span
                          >
                        </td>
                        <td v-show="screenWidth > 400" class="text-secondary">
                          <span v-if="sub.su_paymentmethod === 'rib'">SEPA</span>
                          <span v-if="sub.su_paymentmethod === 'cb'">CB</span>
                        </td>
                        <td class="text-secondary">
                          {{ Math.ceil((sub.plan.amount / 100) * 100) / 100 }}
                          €/mois
                        </td>
                        <td
                          v-show="screenWidth > 767"
                          class="text-secondary text-right"
                          v-if="sub.status"
                        >
                          <span v-if="sub.status === 'active'">En cours</span>
                          <span
                            class="text-danger"
                            v-else-if="sub.status === 'unpaid'"
                            >Impayé</span
                          >
                          <span
                            class="text-danger"
                            v-else-if="sub.status === 'past_due'"
                            >Impayé</span
                          >
                          <span
                            class="text-secondary"
                            v-else-if="sub.status === 'canceled'"
                            >Annulé</span
                          >
                          <span
                            class="text-secondary"
                            v-else-if="sub.status === 'ended'"
                            >Terminé</span
                          >
                          <span
                            class="text-warning"
                            v-else-if="sub.status === 'incomplete'"
                            >Incomplet</span
                          >
                          <span
                            class="text-warning"
                            v-else-if="sub.status === 'incomplete_expired'"
                            >Incomplet et a expiré</span
                          >
                          <span
                            style="color: lightblue"
                            v-else-if="sub.status === 'trialing'"
                            >Période d'essai</span
                          >
                        </td>
                        <td
                          v-show="screenWidth > 767"
                          class="text-secondary text-right"
                          v-else-if="sub.hasOwnProperty('su_state')"
                        >
                          <span
                            class="text-success"
                            v-if="sub.su_state === 2 && sub.su_active"
                            >En cours</span
                          >
                          <span
                            class="text-info"
                            v-else-if="sub.su_state === 2 && !sub.su_active"
                            >Programmé</span
                          >
                          <span
                            class="text-warning"
                            v-else-if="sub.su_state === 1"
                            >Annulé</span
                          >
                          <span class="text-danger" v-else>Arrêté</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="mb-4">
            <a
              class="pointer text-white font-weight-bold"
              href="/files/CGU-Emotivi-20201209.pdf"
              >Voir les CGU</a
            >
          </div>
        </div>
      </div>

      <div id="modalCancelSub" class="modal fade" role="dialog">
        <div class="modal-dialog modal-dialog-centered">
          <div class="modal-content">
            <div class="modal-header row justify-content-start m-1">
              <icon class="mr-2" name="user-times" scale="1.5"></icon>
              <h4>Annulation de votre abonnement</h4>
            </div>
            <div class="modal-body">
              Pour annuler l'abonnement, merci de nous contacter à
              contact@emotivi.io. Nous vous enverrons les instructions pour que
              vous puissiez nous renvoyer le kit Emotivi.
            </div>
            <div class="modal-footer justify-content-center">
              <button class="btn-modal" type="button" data-dismiss="modal">
                OK
              </button>
            </div>
          </div>
        </div>
      </div>

      <div
        ref="modalPaymentMethodUpdate"
        :style="dialogUpdate.display ? 'display:block' : 'display:none'"
        :class="dialogUpdate.display ? 'modal fade show' : 'modal fade'"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <form id="paymentForm">
              <div class="modal-header">
                <h5 class="modal-title">{{ dialogUpdate.title }}</h5>
              </div>
              <div class="modal-body">
                <div id="payment-element"></div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="dialogActionUpdate('close')"
                  :disabled="dialogUpdate.processing"
                >
                  {{ dialogUpdate.close }}
                </button>
                <button
                  type="submit"
                  class="btn btn-primary"
                  :disabled="dialogUpdate.processing"
                >
                  <Icon v-if="dialogUpdate.processing" name="hourglass-half" fill="white" spin />

                  {{ dialogUpdate.confirm }}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div
        ref="modalPaymentMethodUpdateConfirm"
        :style="dialogUpdateConfirm.display ? 'display:block' : 'display:none'"
        :class="dialogUpdateConfirm.display ? 'modal fade show' : 'modal fade'"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
           
              <div class="modal-header">
                <h5 class="modal-title">{{ dialogUpdateConfirm.title }}</h5>
              </div>
              <div class="modal-body text-center mt-3 mb-3">
                Votre moyen de paiement a été mis à jour avec succès !
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="dialogActionUpdate('close-confirm')"
                >
                  {{ dialogUpdateConfirm.close }}
                </button>
              </div>
          </div>
        </div>
      </div>

      <div
        ref="modalPaymentMethod"
        :style="dialog.display ? 'display:block' : 'display:none'"
        :class="dialog.display ? 'modal fade show' : 'modal fade'"
        tabindex="-1"
        role="dialog"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">{{ dialog.title }}</h5>
            </div>
            <div class="modal-body">
              <p>{{ dialog.body }}</p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                @click="dialogAction('close')"
              >
                {{ dialog.close }}
              </button>
              <button
                type="button"
                class="btn btn-primary"
                @click="dialogAction('confirm')"
              >
                {{ dialog.confirm }}
              </button>
            </div>
          </div>
        </div>
      </div>

      <AttestationFiscale
        v-model="displayFormAttestationFiscale"
        @winRequestAttestationCanceled="cancelWin"
        :en_id="en_id"
      >
      </AttestationFiscale>
    </div>
  </section>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import AttestationFiscale from "./AttestationFiscale.vue";
export default {
  name: "privatepayments",
  components: { AttestationFiscale },
  data() {
    return {
      stripe: undefined,
      stripeElements: undefined,
      stripeBillingDetails: undefined,
      dialogErrTxt: "",
      dialogOkTxt: "",
      row_co: {},
      endusersData: [],
      depositNotPaid: false,
      isLoading: false,
      en_id: 0,
      rows_or: [],
      displaySelectEnduser: false,
      changePaymentMethod: "",
      displayFormAttestationFiscale: false,
      taxPeriod: false,
      screenWidth: window.screen.width,
      dialog: {
        title: "",
        body: "",
        confirm: "Confirmer",
        close: "Annuler",
        display: false,
      },
      dialogUpdate: {
        title: "Mettre à jour le moyen de paiement",
        body: "",
        confirm: "Mettre à jour",
        close: "Annuler",
        processing: false,
        display: false,
      },
      dialogUpdateConfirm: {
        title: "Mettre à jour le moyen de paiement",
        close: "Fermer",
        display: false,
      },
      resolveDialog: null,
      es_type: null,
    };
  },
  computed: {
    selectEndusers() {
      return this.$store.state.user.endusers.map((enduser) => {
        return { text: enduser.en_fullname, value: enduser.en_id };
      });
    },
    enduserTotal() {
      return this.$store.state.user.endusers.length;
    },
    getUniqueEnduser() {
      return this.$store.state.user.endusers[0];
    },
    hasBoxes() {
      return this.$store.state.user.endusers &&
        Array.isArray(this.$store.state.user.endusers) &&
        this.$store.state.user.endusers.length
        ? this.$store.state.user.endusers.filter((enduser) => {
            return enduser.bo_id;
          }).length
        : 0;
    },
    hasTvs() {
      return this.$store.state.user.endusers &&
        Array.isArray(this.$store.state.user.endusers) &&
        this.$store.state.user.endusers.length
        ? this.$store.state.user.endusers.filter((enduser) => {
            return enduser.de_id;
          }).length
        : 0;
    },
    paymentMethodsStatusText() {
      return this.$store.state.paymentMethodsStatusText;
    },
    paymentsStatusText() {
      return this.$store.state.paymentsStatusText;
    },
  },
  mounted() {
    this.verifyPeriod();
    this.loadOrders();
    if (this.enduserTotal === 1) this.en_id = this.getUniqueEnduser.en_id;

		document
			.querySelector("#paymentForm")
			.addEventListener("submit", this.handleStripeSubmit);

    console.log("Unique Enduser", this.getUniqueEnduser);

  },
  created() {
    window.addEventListener("resize", this.getScreenSize);
  },
  destroyed() {
    window.removeEventListener("resize", this.getScreenSize);
  },
  // filters: {
  //   formatStripeDate: function(value) {
  //     if (!value) return "";
  //     return this.new Date(value * 1000);
  //   }
  // },
  methods: {
    // openPdfCgu() {
    //   window.open(this.$config.server_url + "/web/2.0/cgu", "_blank");
    // },

    getOrderStatus(status) {
      const name =  {
        PAID: "payé",
      }[status];
      return name || status;
    },

    getPaymentMethodName(type) {
      const name =  {
        cb: "CB",
        rib: "SEPA"
      }[type];
      return name || type;
    },

    proceedChangePayment() {
      if (!this.en_id) return;

      this.changePayment();

      /*if (this.changePaymentMethod === "cb") return this.changePayment();
      if (this.changePaymentMethod === "rib") {
        this.goCardLess();
      }*/

      // return this.$router.push({
      //   path: "/private/infobancaire",
      //   query: { en_id: this.en_id }
      // });
    },

    async beforeStripePaymentMethodUpdate(enduser) {

      if (enduser) {
        this.en_id = enduser.en_id;
      }

      if (this.en_id) {
        return this.changePayment();
      }

      this.displaySelectEnduser = true;
    },

    async changePayment() {

      let response = await this.$axios.get(
        this.$config.server_url +
          "/web/2.0/stripe/subscription/changepayment/" +
          this.en_id
      );

      const data = response.data.data

      if(data) {

        this.stripe = Stripe(data.stripe_apikeypublic);

        this.stripeElements = this.stripe.elements({
          appearance: {
            theme: 'stripe'
          },
          clientSecret: data.clientSecret
        });

        this.stripeBillingDetails = data.billingDetails;

        const paymentElement = this.stripeElements.create("payment", {
          fields: {
            billingDetails: 'never'
          },
          paymentMethodOrder: ["sepa_debit", "card"]
        });

        paymentElement.mount("#payment-element");


        this.dialogUpdate.display = true;
      }
    },

    async handleStripeSubmit(e) {

      e.preventDefault()

      this.dialogUpdate.processing = true;

      const {
        error
      } = await this.stripe.confirmSetup({
        elements: this.stripeElements,
        confirmParams: {
          payment_method_data: {
            billing_details: this.stripeBillingDetails
          },
        },
        redirect: 'if_required'
      });

      this.dialogUpdate.processing = false;

      console.log("🚀 ~ file: PrivatePayments.vue.eta ~ line 915 ~ handleSubmit ~ error", error);

      if(!error) {
        this.dialogUpdate.display = false;
        this.dialogUpdateConfirm.display = true;
      }
    },

    async beforeChangePayment(type, enduser) {
      if (enduser) {
        this.en_id = enduser.en_id;
        let completeDialogTxt =
          type === "rib"
            ? "Vous serez redirigé vers la page de notre partenaire GOCARDLESS."
            : "";
        if (
          !(await this.confirmDialog(
            "Modifier le mode de paiement",
            `Vous allez modifier le mode de paiement pour la box ${enduser.en_fullname}. Souhaitez vous continuer ? ${completeDialogTxt}`,
            "Oui",
            "Non"
          ))
        )
          return;
      }

      this.changePaymentMethod = type;

      if (this.en_id && type === "cb") {
        return this.changePayment();
      }
      if (this.en_id && type === "rib") {
        return this.goCardLess();
      }

      this.displaySelectEnduser = true;
    },

    async goCardLess() {
      try {
        let response = await this.$axios.post(
          this.$config.server_url + "/web/2.0/gocardless/paymentmethod/create",
          {
            en_id: this.en_id,
            co_id: this.$store.state.user.co_id,
          }
        );
        if (response.data.err) {
          if (response.data.err.key === "mandate_exist") {
            /** customer has already save his IBAN on gocardless */
            if (
              await this.confirmDialog(
                "Modifier l'IBAN",
                "Vous avez déjà enregistré vos coordonées bancaires auprès d'Emotivi. Voulez vous les mettre à jour ? Si oui, vous serez redirigé vers la page de notre partenaire GOCARDLESS et invité à refaire le parcours afin d'enregistrer votre nouvel IBAN.",
                "Oui",
                "Non"
              )
            ) {
              /** update customer payment method */
              let response2 = await this.$axios.post(
                this.$config.server_url +
                  "/web/2.0/gocardless/paymentmethod/update",
                {
                  en_id: this.en_id,
                  co_id: this.$store.state.user.co_id,
                }
              );
              if (response2.data.err) {
                alert(response2.data.err.message);
                return;
              }
              if (response2.data.data && response2.data.data.billingData)
                window.open(
                  response2.data.data.billingData.authorisation_url,
                  "_SELF"
                );
            }
            return;
          }
          alert(response.data.err.message);
          return;
        }
        if (response.data.data && response.data.data.billingData)
          window.open(
            response.data.data.billingData.authorisation_url,
            "_SELF"
          );
      } catch (error) {
        console.error(error);
      }
    },

    cancelSubscription(sub) {},
    // async configureBox(row_en) {
    //   // console.log("row_en", row_en);
    //   let response = await this.$axios.get(
    //     this.$config.server_url + "/web/2.0/getchallenge/" + row_en.en_id
    //   );
    //   if (response.data.err) return;
    //   this.$Utils.openConfigurationSite(
    //     row_en.en_id,
    //     row_en.en_admincode,
    //     response.data.data
    //   );
    // },
    async loadOrders() {
      this.isLoading = true;
      /** response data  : allEndusersPaymentsData : [ {...} ,{ rows_or [], rows_pm [], charges [], subscriptions [], row_en {} }, {...} ] */
      let response = await this.$axios.get(
        this.$config.server_url + "/web/2.0/orders"
      );
      if (!response || !response.data || !response.data.data)
        return (this.isLoading = false);

      this.endusersData = response.data.data.endusers;
      this.es_type = response.data.data.endusers[0].row_co.es_id.es_type;
      // console.log("this.endusersData ", this.endusersData);
      // console.log(" this.es_type", this.es_type);
      this.rows_or = response.data.data.orders.filter(
        (row_or) => row_or.or_status === "PAID"
      );

      /** disable alert deposit not paid - if payment exist */
      // if (this.charges.length) this.depositNotPaid = false;
      // else this.depositNotPaid = true;

      this.isLoading = false;
    },
    async payDeposit() {
      let response = await this.$axios.post(
        this.$config.server_url + "/web/2.0/subscription/paydeposit",
        { co_id: this.$store.state.user.co_id }
      );
      const stripe = await loadStripe(response.data.data.stripe_apikeypublic);
      stripe
        .redirectToCheckout({
          sessionId: response.data.data.CHECKOUT_SESSION_ID,
        })
        .then(function (result) {});
    },
    openFormAttestationFiscale(enduser) {
      this.end_id = enduser.en_id;
      this.displayFormAttestationFiscale = true;
    },
    cancelWin() {
      this.displayFormAttestationFiscale = false;
    },
    verifyPeriod() {
      /*let year = this.$moment().format("YYYY");
      if (
        this.$moment().isBetween(
          this.$moment(year + "-02-01"),
          this.$moment(year + "-06-30")
        )
      ) {*/
        this.taxPeriod = true;
      //}
    },
    getScreenSize(event) {
      this.screenWidth = event.target.innerWidth;
    },

    dialogActionUpdate(action) {
      if (action === "close") {
        this.dialogUpdate.display = false;
      }
      if (action === "close-confirm") {
        this.dialogUpdateConfirm.display = false;
        this.loadOrders();
      }
    },

    dialogAction(action) {
      if (action === "close") {
        this.dialog.display = false;
        this.resolveDialog.resolve(false);
      }
      if (action === "confirm") {
        this.dialog.display = false;
        this.resolveDialog.resolve(true);
      }
      this.resolveDialog = null;
    },
    confirmDialog(title, body, confirm, close) {
      this.resolveDialog = new this.Deferred();
      this.dialog.title = title;
      this.dialog.body = body;
      this.dialog.confirm = confirm;
      this.dialog.close = close;
      this.dialog.display = true;
      return this.resolveDialog.promise;
    },
    Deferred() {
      var self = this;
      this.promise = new Promise(function (resolve, reject) {
        self.reject = reject;
        self.resolve = resolve;
      });
    },
    async printOrder(item, $event) {
      $event.stopPropagation();
      window.open(
        this.$config.server_url +
          "/web/2.0/orders/" +
          item.or_id +
          "/pdf?d=" +
          new Date().getTime() +
          "&token=" +
          this.$store.state.accesstoken,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 50px;
  // padding-top: 40px;
}

h3 {
  color: white;
}

table {
  td {
    color: white;
    border: none;
  }

  th {
    color: white;
    border-bottom: none;
  }
}

.btn-modal {
  width: 100px;
  height: 40px;
  background-color: #1876eb;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  margin: 5px;

  &:hover {
    background-color: #3482e0;
  }
}
</style>
