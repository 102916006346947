var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "form-group" }, [
    _vm.label
      ? _c(
          "label",
          {
            staticClass: "label",
            class: _vm.labelClass,
            attrs: { for: _vm.id2 }
          },
          [_vm._v(_vm._s(_vm.label))]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "input-group" },
      [
        _vm.prependText
          ? _c("div", { staticClass: "input-group-prepend" }, [
              _c("span", {
                staticClass: "input-group-text",
                domProps: { innerHTML: _vm._s(_vm.prependText) }
              })
            ])
          : _vm._e(),
        _c("v-select", {
          ref: "myinput",
          staticStyle: { width: "100%" },
          attrs: { label: "text", options: _vm.items, disabled: _vm.disabled },
          on: { "search:focus": _vm.onfocus },
          model: {
            value: _vm.value2,
            callback: function($$v) {
              _vm.value2 = $$v
            },
            expression: "value2"
          }
        }),
        _vm.placeholderData
          ? _c("div", { staticClass: "placeholder" }, [
              _vm._v(_vm._s(_vm.placeholder))
            ])
          : _vm._e(),
        _vm.appendText || _vm.appendIcon
          ? _c("div", { staticClass: "input-group-prepend" }, [
              _vm.appendText
                ? _c("span", {
                    staticClass: "input-group-text",
                    domProps: { innerHTML: _vm._s(_vm.appendText) }
                  })
                : _vm._e(),
              _vm.appendIcon
                ? _c(
                    "span",
                    { staticClass: "input-group-text" },
                    [
                      _c("icon", {
                        staticClass: "icon-menu",
                        attrs: { name: _vm.appendIcon }
                      })
                    ],
                    1
                  )
                : _vm._e()
            ])
          : _vm._e(),
        _vm.errormsg2
          ? _c(
              "div",
              { staticClass: "invalid-feedback", attrs: { id: _vm.id2 } },
              [_vm._v(" " + _vm._s(_vm.errormsg2) + " ")]
            )
          : _vm._e()
      ],
      1
    ),
    _vm.subText
      ? _c("small", {
          staticClass: "sub-text form-text text-muted",
          domProps: { innerHTML: _vm._s(_vm.subText) }
        })
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }