<template>
  <section class="bg-gray-light">
    <div class="container p-0 pt-3">
      <h1>Prélèvement bancaire SEPA</h1>
      <div class="row mb-5">
        <div
          class="col-xl-8 offset-xl-2 col-sm-10 offset-sm-1"
          style="background-color: #6aceb1"
        >
          <div
            class="
              row
              justify-content-center
              align-items-center
              mt-3
              ml-1
              mr-1
              mb-5
            "
          >
            <div class="col-xl-1 col-lg-1 col-md-1 col-sm-2 text-center">
              <icon name="credit-card" scale="2.5" style="fill: white"></icon>
            </div>
            <div class="col-xl-11 col-lg-11 col-md-11 col-sm-10 text-center">
              <h3 class="m-0" style="color: white">
                Veuillez renseigner les coordonnées bancaires
              </h3>
            </div>
          </div>
          <form>
            <div class="row">
              <div
                :class="
                  `col-xl-${formcolxl} offset-xl-2 col-md-${formcolmd} offset-md-2 col-sm-${formcolsm} offset-sm-1 col-${formcol} offset-1`
                "
              >
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    style="width: auto"
                    type="checkbox"
                    v-model="row_co.co_rib"
                    id="defaultCheck1"
                  />
                  <label class="form-check-label" for="defaultCheck1">
                    Je souhaite payer par prélévement bancaire
                  </label>
                </div>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    style="width: auto"
                    type="checkbox"
                    v-model="row_co.co_rib_authorization"
                    id="defaultCheck2"
                    required
                  />
                  <label class="form-check-label" for="defaultCheck2">
                    Je valide le mandat autorisant Emotivi à présenter des
                    ordres de prélèvement sur le compte bancaire ouvert à mon
                    nom.
                  </label>
                </div>
              </div>
              <div
                class="
                  col-xl-4
                  offset-xl-2
                  col-md-4
                  offset-md-2
                  col-10
                  offset-1
                "
              >
                <div class="form-group">
                  <label class="" for="ibanField">IBAN</label>
                  <div class="input-group mb-2 mr-sm-2">
                    <!-- <div class="input-group-prepend">
                      <div class="input-group-text">FR</div>
                    </div> -->
                    <input
                      type="text"
                      class="form-control"
                      id="ibanField"
                      placeholder="IBAN"
                      v-model="row_co.co_rib_iban"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div
                class="
                  col-xl-4
                  offset-xl-2
                  col-md-4
                  offset-md-2
                  col-10
                  offset-1
                "
              >
                <div class="form-group">
                  <label class="" for="bicField">BIC</label>
                  <div class="input-group mb-2 mr-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      id="ibanField"
                      placeholder="BIC"
                      maxlength="11"
                      minlength="11"
                      v-model="row_co.co_rib_bic"
                      required
                    />
                  </div>
                </div>
              </div>
              <div
                class="
                  col-xl-4
                  offset-xl-0
                  col-md-4
                  offset-md-0
                  col-10
                  offset-1
                "
              >
                <div class="form-group">
                  <label class="" for="nameField">Titulaire du compte</label>
                  <div class="input-group mb-2 mr-sm-2">
                    <input
                      type="text"
                      class="form-control"
                      id="ibanField"
                      placeholder="Nom"
                      v-model="row_co.co_rib_name"
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col offset-xl-2 offset-1 mb-3">
                <div v-if="dialogErrTxt" class="alert alert-danger">
                  {{ dialogErrTxt }}
                </div>
                <div v-if="dialogOkTxt" class="alert alert-success">
                  {{ dialogOkTxt }}
                </div>
                <div class="d-flex">
                  <button
                    type="button"
                    class="btn btn-primary mb-2"
                    @click="
                      fromstripe ? saveAccountSubscription() : saveAccount()
                    "
                  >
                    {{ nocontact ? "VALIDER" : "ENREGISTRER" }}
                  </button>
                  <button
                    v-if="!fromstripe && !nocontact"
                    type="button"
                    class="btn btn-secondary mb-2 m-auto"
                    @click="cancelAccount"
                  >
                    RETOUR
                  </button>
                  <button
                    v-if="fromstripe"
                    type="button"
                    class="btn btn-secondary mb-2 m-auto"
                    @click="successStripePayment"
                  >
                    IGNORER
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import IbanValidation from "../plugins/ibanvalidation.js";
export default {
  name: "privateinfobancaire",
  props: {
    fromstripe: {
      default: false,
      type: Boolean
    },
    nocontact: {
      default: false,
      type: Boolean
    },
    formcol: {
      default: 10,
      type: Number
    },
    formcolsm: {
      default: 10,
      type: Number
    },
    formcolmd: {
      default: 8,
      type: Number
    },
    formcolxl: {
      default: 8,
      type: Number
    },
    en_id: {
      default: "",
      type: String
    },
    stripeChallenge: {
      default: "",
      type: String
    }
  },
  components: {},
  data() {
    return {
      dialogErrTxt: "",
      dialogOkTxt: "",
      row_co: {},
      endusers: [],
      parcels: [],
      depositNotPaid: false
    };
  },
  mounted() {
    if (this.fromstripe) this.loadAccountSubscription();
    else if (!this.nocontact) this.loadAccount();
  },
  methods: {
    async saveAccount() {
      this.dialogErrTxt = "";
      this.dialogOkTxt = "";
      if (!this.row_co.co_rib_authorization)
        this.dialogErrTxt =
          "Veuillez cocher la case autorisant Emotivi à prélever sur le compte bancaire.";
      if (!this.row_co.co_rib_iban)
        this.dialogErrTxt = "Veuillez saisir le compte IBAN";
      if (!this.row_co.co_rib_bic)
        this.dialogErrTxt = "Veuillez saisir le compte BIC";
      if (!this.row_co.co_rib_name)
        this.dialogErrTxt =
          "Veuillez saisir le nom du titulaire du compte bancaire";
      if (this.dialogErrTxt) return;

      // check IBAN validity

      let errorIBAN = this.checkIBAN();
      if (errorIBAN) {
        this.dialogErrTxt = "Votre IBAN est invalide. " + errorIBAN;
        return;
      }

      // check BIC validity

      if (!this.checkBIC()) {
        this.dialogErrTxt = "Votre BIC est invalide.";
        return;
      }

      /** return data in the nextStep event if nocontact prop is true */
      if (this.nocontact) {
        return this.$emit("nextStep", this.row_co);
      }

      let response = await this.$axios.put(
        this.$config.server_url + "/web/2.0/account",
        {
          row_co: this.row_co,
          en_id: this.en_id || this.$route.query.en_id
        }
      );

      if (response.data.err) {
        if (response.data.err.key == "rib_validation_mandatory")
          this.dialogErrTxt =
            "Veuillez cocher la case 'Je souhaite payer par prélévement bancaire'";
        else if (response.data.err.key == "rib_authentication_mandatory")
          this.dialogErrTxt =
            "Veuillez cocher la case autorisant Emotivi à prélever sur le compte bancaire.";
        else if (response.data.err.key == "rib_iban_mandatory")
          this.dialogErrTxt = "Veuillez saisir le compte IBAN";
        else if (response.data.err.key == "rib_bic_mandatory")
          this.dialogErrTxt = "Veuillez saisir le compte BIC";
        else if (response.data.err.key == "rib_name_mandatory")
          this.dialogErrTxt =
            "Veuillez saisir le nom du titulaire du compte bancaire";
        else if (response.data.err.key == "no_right_to_enduser")
          this.dialogErrTxt =
            "Vous n'êtes pas autorisé à modifier cet utilisateur";
        else this.dialogErrTxt = response.data.err.message;
        return;
      }

      if (response.data.data.billingData)
        window.open(response.data.data.billingData.authorisation_url, "_SELF");

      // this.dialogOkTxt =
      //   "Votre IBAN est validé et enregistré, vous allez être redirigé automatiquement.";
      // setTimeout(() => {
      //   this.$router.push("/private/payments");
      // }, 5000);

      // if (response.data.err) {
      //   if (response.data.err.key == "contact_login_exists_already")
      //     this.dialogErrTxt = "L'email existe déjà";
      //   else this.dialogErrTxt = response.data.err.message;
      //   return;
      // }
      // this.dialogOkTxt = "Le compte a été enregistré";
      // window.setTimeout(() => {
      //   this.dialogOkTxt = "";
      // }, 3000);
    },

    async saveAccountSubscription() {
      this.dialogErrTxt = "";
      this.dialogOkTxt = "";
      if (!this.row_co.co_rib_authorization)
        this.dialogErrTxt =
          "Veuillez cocher la case autorisant Emotivi à prélever sur le compte bancaire.";
      if (!this.row_co.co_rib_iban)
        this.dialogErrTxt = "Veuillez saisir le compte IBAN";
      if (!this.row_co.co_rib_bic)
        this.dialogErrTxt = "Veuillez saisir le compte BIC";
      if (!this.row_co.co_rib_name)
        this.dialogErrTxt =
          "Veuillez saisir le nom du titulaire du compte bancaire";
      if (this.dialogErrTxt) return;

      // check IBAN validity

      let errorIBAN = this.checkIBAN();
      if (errorIBAN) {
        this.dialogErrTxt = "Votre IBAN est invalide. " + errorIBAN;
        return;
      }

      // check BIC validity

      if (!this.checkBIC()) {
        this.dialogErrTxt = "Votre BIC est invalide.";
        return;
      }

      let response = await this.$axios.put(
        this.$config.server_url + "/web/2.1/subscription/step4",
        {
          row_co: this.row_co,
          en_id: this.en_id,
          challenge: this.stripeChallenge
        }
      );

      if (response.data.err) {
        if (response.data.err.key == "rib_validation_mandatory")
          this.dialogErrTxt =
            "Veuillez cocher la case 'Je souhaite payer par prélévement bancaire'";
        else if (response.data.err.key == "rib_authentication_mandatory")
          this.dialogErrTxt =
            "Veuillez cocher la case autorisant Emotivi à prélever sur le compte bancaire.";
        else if (response.data.err.key == "rib_iban_mandatory")
          this.dialogErrTxt = "Veuillez saisir le compte IBAN";
        else if (response.data.err.key == "rib_bic_mandatory")
          this.dialogErrTxt = "Veuillez saisir le compte BIC";
        else if (response.data.err.key == "rib_name_mandatory")
          this.dialogErrTxt =
            "Veuillez saisir le nom du titulaire du compte bancaire";
        else if (response.data.err.key == "no_right_to_enduser")
          this.dialogErrTxt =
            "Vous n'êtes pas autorisé à modifier cet utilisateur";
        else this.dialogErrTxt = response.data.err.message;
        return;
      }
      this.dialogOkTxt =
        "Votre IBAN est validé et enregistré, vous allez être redirigé automatiquement.";
      setTimeout(() => {
        this.$emit("nextStep");
      }, 5000);
    },
    async loadAccount() {
      let response = await this.$axios.get(
        this.$config.server_url + "/web/2.0/account"
      );
      // console.log("response.data.data", response.data.data);
      if (response.data.err) {
        console.error(response.data.err);
        return;
      }
      this.row_co = {
        co_id: response.data.data.row_co.co_id,
        co_rib: response.data.data.row_co.co_rib,
        co_rib_authorization: response.data.data.row_co.co_rib_authorization,
        co_rib_iban: response.data.data.row_co.co_rib_iban,
        co_rib_bic: response.data.data.row_co.co_rib_bic,
        co_rib_name: response.data.data.row_co.co_rib_name
      };
      this.parcels = response.data.data.parcels;
      this.endusers = response.data.data.endusers;
    },
    async loadAccountSubscription() {
      if (!this.$store.state.co_webaccesstoken_temp)
        return this.successStripePayment();
      let response = await this.$axios.get(
        this.$config.server_url + "/web/2.0/subscription/step4",
        {
          params: {
            co_webaccesstoken_temp: this.$store.state.co_webaccesstoken_temp
          }
        }
      );
      //console.log("response.data.data", response.data.data);
      if (response.data.err) {
        console.error(response.data.err);
        return;
      }
      this.row_co = {
        co_id: response.data.data.row_co.co_id,
        co_rib: response.data.data.row_co.co_rib,
        co_rib_authorization: response.data.data.row_co.co_rib_authorization,
        co_rib_iban: response.data.data.row_co.co_rib_iban,
        co_rib_bic: response.data.data.row_co.co_rib_bic,
        co_rib_name: response.data.data.row_co.co_rib_name,
        co_webaccesstoken_temp: this.$store.state.co_webaccesstoken_temp
      };
    },
    cancelAccount() {
      this.$router.push("/private/payments");
    },
    checkIBAN() {
      return IbanValidation.checkIBAN(this.row_co.co_rib_iban);
    },
    checkBIC() {
      return IbanValidation.checkBIC(this.row_co.co_rib_bic);
    },
    successStripePayment() {
      this.$emit("nextStep");
    }
  }
};
</script>

<style lang="scss" scoped></style>
