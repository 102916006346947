<template>
  <section>
    <div class="container pb-4">
      <div class="row">
        <div class="col-lg-4 flex-1" style="margin-top: 20px"></div>

        <div class="col-lg-4 flex-1" style="margin-top: 20px">
          <div
            id="login_connect_emotivi_tile"
            style="background-color: #159cac; padding: 25px 25px; height: 100%"
          >
            <div style="margin-bottom: 15px">
              <img src="/images/ico-simplicite.png" alt="" width="19%" />
            </div>
            <h2
              class="uppercase"
              style="color: white; font-weight: bold; font-size: 30px"
            >
              Réinitialiser votre mot de passe
            </h2>
            <div style="margin-top: 20px">
              <div v-if="!challengeError" class="form-group">
                <label class="uppercase" :for="`${userTypeId}_password1`"
                  >Nouveau mot de passe</label
                >
                <input
                  type="password"
                  class="form-control"
                  :id="`${userTypeId}_password1`"
                  :name="`${userTypeId}_password1`"
                  placeholder="Mot de passe"
                  v-model="password1"
                  required
                  style="border: none; border-radius: 0"
                  @input="verfiyFormatPassword"
                />
              </div>
              <div v-if="!challengeError" class="form-group mt-4">
                <label class="uppercase" :for="`${userTypeId}_password2`"
                  >Nouveau mot de passe</label
                >
                <input
                  type="password"
                  class="form-control"
                  :id="`${userTypeId}_password2`"
                  :name="`${userTypeId}_password2`"
                  placeholder="Répétez le mot de passe"
                  v-model="password2"
                  required
                  style="border: none; border-radius: 0"
                  @input="verifyPassword"
                />
              </div>
              <div v-if="dialogErr" class="alert alert-danger">
                {{ dialogErrTxt }}
              </div>

              <div
                v-if="!challengeError"
                class="d-flex flex-grow-1 mt-2 justify-content-end"
              >
                <button
                  type="button"
                  :class="classButtonReset"
                  :disabled="!isValid1 || !isValid2"
                  @click="newpassword()"
                >
                  REINITIALISER
                </button>
              </div>
            </div>
          </div>
        </div>

        <div></div>
        <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalResetPassword">
          Tester modal reset password
        </button> -->
        <div id="modalResetPassword" class="modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                  <div class="col-1">
                    <icon name="check-circle" scale="3"></icon>
                  </div>
                  <div class="col-9 ml-4">
                    Votre mot de passe a bien été modifié.
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-center">
                <button
                  class="btn-modal"
                  type="button"
                  data-dismiss="modal"
                  @click="goToLogin"
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "login",
  components: {},
  props: {
    user: {
      default: function() {
        return false;
      },
      type: Boolean
    }
  },
  data() {
    return {
      co_password1: "",
      co_password2: "",
      us_password1: "",
      us_password2: "",
      dialogErrTxt: "",
      dialogErr: false,
      challengeError: false,
      challenge_newpassword: "",
      datetime_newpassword: "",
      co_id: "",
      us_id: "",
      isValid: false,
      userTypeId: this.user ? "us" : "co",
      urlAffiliate: this.user ? "affiliate/" : "",
      urlLoginAffiliate: this.user ? "/affiliate" : "",
      isValid1: false,
      isValid2: false,
      classButtonReset: "btn btn-primary uppercase",
      delayVerify: null
    };
  },
  computed: {
    password1: {
      get() {
        return this[`${this.userTypeId}_password1`];
      },
      set(value) {
        this[`${this.userTypeId}_password1`] = value;
      }
    },
    password2: {
      get() {
        return this[`${this.userTypeId}_password2`];
      },
      set(value) {
        this[`${this.userTypeId}_password2`] = value;
      }
    }
  },
  mounted() {
    this.$nextTick().then(() => {
      this.checkchallenge(
        this.$route.params[`${this.userTypeId}_id`],
        decodeURIComponent(this.$route.params.challenge),
        this.$route.query.datetime
      );
    });
  },
  methods: {
    async checkchallenge(id, challenge, datetime) {
      this[`${this.userTypeId}_id`] = id;
      let response = await this.$axios.post(
        this.$config.server_url +
          `/web/2.0/signin/${this.urlAffiliate}forgetpassword/checkchallenge`,
        {
          [`${this.userTypeId}_id`]: this[`${this.userTypeId}_id`],
          challenge,
          datetime
        }
      );
      if (response.data.err) {
        this.challengeError = true;
        this.dialogErrTxt =
          response.data.err.key === "challenge_expired"
            ? "Désolé, cette page n'est plus accessible, veuillez recommencer la procédure de réinitialisation de mot de passe."
            : "Désolé, vous n'êtes pas autorisé à accéder à cette page";

        this.dialogErr = true;
      } else {
        this.challenge_newpassword = response.data.data.challenge;
        this.datetime_newpassword = response.data.data.datetime;
      }
    },
    async verfiyFormatPassword() {
      if (this.delayVerify) clearTimeout(this.delayVerify);
      this.delayVerify = window.setTimeout(async () => {
        this.dialogErrTxt = "";
        this.dialogErr = false;
        let response = await this.$axios.post(
          this.$config.server_url +
            "/web/2.0/signin/forgetpassword/verifyformatpassword",
          {
            [`${this.userTypeId}_password`]: this[
              `${this.userTypeId}_password1`
            ],
            [`${this.userTypeId}_id`]: this[`${this.userTypeId}_id`],
            challenge: this.challenge_newpassword,
            datetime: this.datetime_newpassword
          }
        );
        // console.log("response", response);
        if (response.data.data) {
          this.isValid1 = true;
          this.verifyPassword();
        } else if (response.data.err) {
          this.isValid1 = false;
          this.dialogErrTxt =
            response.data.err.key === "password_format_error"
              ? "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre."
              : response.data.err.key === "challenge_expired"
              ? "Désolé, cette page n'est plus accessible, veuillez recommencer la procédure de réinitialisation de mot de passe."
              : "Désolé, vous n'êtes pas autorisé à accéder à cette page";
          this.dialogErr = true;
        }
      }, 300);
    },
    async verifyPassword() {
      // this.dialogErr = false;

      if (
        this[`${this.userTypeId}_password1`] !==
          this[`${this.userTypeId}_password2`] ||
        !this[`${this.userTypeId}_password2`]
      ) {
        if (this.isValid1)
          this.dialogErrTxt = "Les mots de passe doivent être identiques";
        this.dialogErr = true;
        this.isValid2 = false;
      } else {
        if (!this.isValid1) {
          this.dialogErr = true;
        } else {
          this.dialogErrTxt = "";
          this.dialogErr = false;
        }
        this.isValid2 = true;
        // this.classButtonReset = "btn btn-primary uppercase";
      }
    },
    async newpassword() {
      this.dialogErr = false;
      if (!this.challenge_newpassword) return;
      /*** vérification du mot de passe */
      if (!this.dialogErr) {
        let response = await this.$axios.post(
          this.$config.server_url +
            `/web/2.0/signin/${this.urlAffiliate}forgetpassword/newpassword`,
          {
            [`${this.userTypeId}_password`]: this[
              `${this.userTypeId}_password1`
            ],
            [`${this.userTypeId}_id`]: this[`${this.userTypeId}_id`],
            challenge: this.challenge_newpassword,
            datetime: this.datetime_newpassword
          }
        );
        if (response.data.err) {
          this.dialogErrTxt =
            response.data.err.key === "password_format_error"
              ? "Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule et un chiffre"
              : response.data.err.key === "challenge_expired"
              ? "Désolé, cette page n'est plus accessible, veuillez recommencer la procédure de réinitialisation de mot de passe."
              : "Désolé, vous n'êtes pas autorisé à accéder à cette page";
          this.dialogErr = true;
        } else {
          this.$$("#modalResetPassword").modal("show");
        }
      }
    },
    goToLogin() {
      this.$router.push(`/login${this.urlLoginAffiliate}`);
    }
  }
};
</script>

<style lang="scss" scoped>
.img-fluid {
  width: 100%;
  height: auto;
}
.container2-form-login {
  width: 80%;
}
.links {
  align-items: flex-end;
}
.btn-modal {
  width: 70px;
  height: 30px;
  background-color: #1876eb;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  &:hover {
    background-color: #3482e0;
  }
}
</style>
