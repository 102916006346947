import Vue from "vue";
import VueRouter from "vue-router";

import VueMeta from "vue-meta";
Vue.use(VueMeta);

import store from "./store";
// import config from "../config.js";
import axios from "axios";

//import Home from "../views/Home.vue";
//import HomePublic from "../views/HomePublic.vue";
// import OfferList from "../views/OfferList.vue";
// import OfferDetails from "../views/OfferDetails.vue";
// import ImmoCaddy from "../views/ImmoCaddy.vue";
// import Account from "../views/Account.vue";
// import Contact from "../views/Contact.vue";
// import Whoweare from "../views/Whoweare.vue";
// import Sitemap from "../views/Sitemap.vue";
// import Partners from "../views/Partners.vue";
import Cgu from "../views/Cgu.vue";
import Login from "../views/Login.vue";
import ResetPassword from "../views/ResetPassword.vue";
//import Press from "../views/Press.vue";
import Support from "../views/Support.vue";
import Subscription from "../views/Subscription.vue";
import FromStripe from "../views/FromStripe.vue";
import PrivateAccount from "../views/PrivateAccount.vue";
import PrivatePayments from "../views/PrivatePayments.vue";
import PrivateBox from "../views/PrivateBox.vue";
import PrivateHome from "../views/PrivateHome.vue";
//import FormulaireContact from "../views/FormulaireContact.vue";
import NotFound from "../views/NotFound.vue";
import PrivateInfoBancaire from "../views/PrivateInfoBancaire.vue";
import SubscriptionAffiliate from "../views/SubscriptionAffiliate.vue";
import LoginAffiliate from "../views/LoginAffiliate.vue";
import PrivatePurchaseKey4g from "../views/PrivatePurchaseKey4g.vue";
import SubscriptionLimitedPeriod from "../views/SubscriptionLimitedPeriod.vue";
import PrivateTv from "../views/PrivateTv.vue";
import FromGocardless from "../views/FromGocardless.vue";
import PrivatePurchaseRouter4g from "../views/PrivatePurchaseRouter4g.vue";

// import ImmoHome from "../views/ImmoHome.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "homeredirect"
    // component: Home
    //redirect: "/home"
  },
  {
    path: "/home",
    name: "homeredirect"
    //component: Home,
    //meta: { readUrlSchool: true }
    // redirect: "/immo/isbatp/page/1"
  },
  {
    path: "/subscription",
    name: "subscription",
    component: Subscription,
    meta: { publicpage: true, title: "Commandez votre kit Emotivi" }
  },
  {
    path: "/subscription/affiliate",
    name: "subscriptionaffiliate",
    component: SubscriptionAffiliate,
    meta: {
      restricted: true,
      title: "Emotivi - espace revendeurs - inscription"
    }
  },
  {
    path: "/subscription/limitedperiod/:en_id",
    name: "subscriptionlimitedperiod",
    component: SubscriptionLimitedPeriod,
    meta: { publicpage: true }
  },
  {
    path: "/login/affiliate",
    name: "loginaffiliate",
    component: LoginAffiliate,
    meta: { title: "Emotivi - espace revendeurs - connexion" }
  },
  {
    path: "/fromstripe/:ok/:co_id/:challenge",
    name: "fromstripe",
    component: FromStripe,
    meta: { publicpage: true }
  },
  {
    path: "/subscription/fromstripe/:ok/:en_id/:challenge",
    name: "subscriptionfromstripe",
    props: { account: false },
    component: FromStripe,
    meta: { publicpage: true }
  },
  {
    path: "/fromstripe/:ok",
    name: "fromstripe",
    component: FromStripe,
    meta: { publicpage: true }
  },
  {
    path: "/support",
    name: "support",
    component: Support,
    meta: { publicpage: true, title: "Emotivi - support et FAQ" }
  },
  {
    path: "/press",
    name: "press",
    //component: Press,
    meta: { title: "Emotivi - qui sommes-nous ?" }
  },
  {
    path: "/contact",
    name: "formulairecontact"
    // component: FormulaireContact,
    // meta: { publicpage: true }
  },
  {
    path: "/resetpass/:co_id/:challenge",
    name: "resetpass",
    component: ResetPassword,
    props: { user: false },
    meta: { publicpage: true }
  },
  {
    path: "/resetpass/affiliate/:us_id/:challenge",
    name: "resetpassaffiliate",
    props: { user: true },
    component: ResetPassword,
    meta: { publicpage: true }
  },
  {
    path: "/cgu",
    name: "cgu",
    component: Cgu,
    meta: { publicpage: true }
  },

  {
    path: "/private/autolog/:co_id/:challenge",
    name: "subscriptionchallenge",
    component: PrivateHome,
    meta: { publicpage: false }
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: { publicpage: true, title: "Emotivi - mon compte" }
  },
  {
    path: "/private/home",
    name: "privatehome",
    component: PrivateHome,
    meta: { publicpage: false }
  },
  {
    path: "/private/account",
    name: "privateaccount",
    component: PrivateAccount,
    meta: { publicpage: false }
  },
  {
    path: "/private/payments",
    name: "privatepayments",
    component: PrivatePayments,
    meta: { publicpage: false }
  },
  {
    path: "/private/box",
    name: "privatebox",
    component: PrivateBox,
    meta: { publicpage: false }
  },
  {
    path: "/private/tv",
    name: "privatetv",
    component: PrivateTv,
    meta: { publicpage: false }
  },
  {
    path: "/private/infobancaire",
    name: "infobancaire",
    component: PrivateInfoBancaire,
    meta: { publicpage: false }
  },
  {
    path: "/private/purchasekey4g",
    name: "purchasekey4g",
    component: PrivatePurchaseKey4g,
    meta: { publicpage: false }
  },
  {
    path: "/private/purchaserouter4g",
    name: "purchaserouter4g",
    component: PrivatePurchaseRouter4g,
    meta: { publicpage: false }
  },
  {
    path: "/fromgocardless/:ok",
    name: "fromgocardless",
    component: FromGocardless,
    meta: { publicpage: true }
  },
  {
    path: "/fromgocardless/:ok/:co_id/:challenge",
    name: "fromgocardless",
    component: FromGocardless,
    meta: { publicpage: true }
  },

  {
    path: "*",
    name: "notfound",
    component: NotFound
  }
];

const router = new VueRouter({
  mode: "history",
  routes
});
router.traductionLoaded = false;

router.beforeEach(async (to, from, next) => {
  // filter by sub domain

  if (location.host.indexOf("account") >= 0 && to.path === "/") {
    return next({ path: "/login" });
  } else if (
    location.host.indexOf("subscription") >= 0 &&
    (to.path === "/" || to.path === "/subscription")
  ) {
    return window.open(Vue.prototype.$config.web_url_new_subscription, "_self");
  } else if (location.host.indexOf("support") >= 0 && to.path === "/")
    return next({ path: "/support" });

  if (to.name === "homeredirect")
    return window.open(Vue.prototype.$config.web_url, "_self");
  if (to.name === "press")
    return window.open(
      Vue.prototype.$config.web_url + "qui-sommes-nous.html",
      "_self"
    );
  if (to.name === "formulairecontact")
    return window.open(Vue.prototype.$config.web_url + "contact.html", "_self");

  /** manage page title if meta.title is set */
  const title = to.meta.title;
  // If the route has a title, set it as the page title of the document/page
  if (title) {
    document.title = title;
  }

  if (to.name === "subscriptionchallenge") {
    let response = await axios.post(
      Vue.prototype.$config.server_url +
      "/web/2.0/autosignin/" +
      to.params.co_id +
      "/" +
      to.params.challenge
    );
    //si challenge ok on set la connexion
    if (response.data.data.row && response.data.data.accesstoken) {
      store.commit("set_connexion", {
        accesstoken: response.data.data.accesstoken,
        user: response.data.data.row
      });
    }
  }

  if (to.name == "login") {
    let isLogged = await autolog();
    if (isLogged) router.push("/private/home");
  }
  if (to.name == "loginaffiliate") {
    let isLogged = await autologAffiliate();
    if (isLogged) router.push("/subscription/affiliate");
  }

  async function autolog() {
    //console.log("je passe", Vue.prototype.$config);
    if (!store.state.accesstoken) return false;
    try {
      let response = await axios.get(
        Vue.prototype.$config.server_url + "/web/2.0/autosignin",
        {
          accesstoken: store.state.accesstoken
        }
      );
      // console.log("autolog - response.data : ", response.data);
      if (response.data.err) {
        return false;
      }
      store.commit("set_connexion", {
        accesstoken: response.data.data.accesstoken,
        user: response.data.data.row
      });
      return true;
    } catch (error) {
      console.error("ko", error);
      return false;
    }
  }
  async function autologAffiliate() {
    //console.log("je passe", Vue.prototype.$config);
    if (!store.state.accesstoken) return false;
    try {
      let response = await axios.get(
        Vue.prototype.$config.server_url + "/web/2.0/affiliate/autosignin",
        {
          accesstoken: store.state.accesstoken
        }
      );
      // console.log("autolog - response.data : ", response.data);
      if (response.data.err) {
        return false;
      }
      store.commit("set_authentification", {
        accesstoken: response.data.data.accesstoken,
        affiliatedUser: response.data.data.row_us
      });
      return true;
    } catch (error) {
      console.error("ko", error);
      return false;
    }
  }

  if (!store.state.connected && store.state.user && store.state.user.co_id)
    await autolog();

  if (to.meta && to.meta.publicpage === false && !store.state.connected) {
    return next({ name: "home" });
  }
  if (
    !store.state.authenticated &&
    store.state.affiliatedUser &&
    store.state.affiliatedUser.us_id
  )
    await autologAffiliate();
  if (to.meta && to.meta.restricted && !store.state.authenticated) {
    return next({ name: "loginaffiliate" });
  }

  window.scrollTo(0, 0);

  return next();
});
gtag("set", "page", router.currentRoute.path);
gtag("send", "pageview");
fbq("trackCustom", router.currentRoute.path);

router.afterEach((to, from) => {
  gtag("set", "page", to.path);
  gtag("send", "pageview");
  fbq("trackCustom", to.path);
  function removeFreshworks() {
    // disable some feature and button for affiliate page
    if (
      (to.name === "subscriptionaffiliate" ||
        to.name === "loginaffiliate" ||
        store.state.authenticated) &&
      document.getElementById("freshworks-container") &&
      document.getElementById("navbar-toggler")
    ) {
      //remove freshworks button
      document.getElementById("freshworks-container").style.display = "none";
      // remove burger manu
      document.getElementById("navbar-toggler").style.display = "none";
    }
  }
  window.setTimeout(removeFreshworks, 100);
  window.setTimeout(removeFreshworks, 500);
});

export default router;
