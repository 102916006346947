<template>
  <div class="m-hour" style="width:520px">
    <div class="hours d-flex flex-column mb-2">
      <div class="titre">Les heures</div>

      <div class="d-flex flex-row flex-wrap mt-1">
        <div
          v-for="hour in tabHours"
          :key="hour"
          class="form-control btn-time"
          :class="{ selected: hourSelected === hour }"
          @click="setHour(hour)"
        >
          {{ hour }}
        </div>
      </div>
    </div>
    <div class="monutes d-flex flex-column">
      <div class="titre">Les minutes</div>
      <div class="d-flex flex-row flex-wrap mt-1">
        <div
          v-for="minute in tabMinutes"
          :key="minute"
          class="form-control btn-time"
          :class="{ selected: minuteSelected === minute }"
          @click="setMinute(minute)"
        >
          {{ minute }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//import moment from "moment";

export default {
  name: "mhour",
  props: {
    value: {
      default: function() {
        return "00:00";
      },
      type: String
    },
    intervalMinute: {
      default: function() {
        return 5;
      },
      type: Number
    }
  },
  data() {
    let tabMinutes = [];
    for (let i = 0; i < 60; i += this.intervalMinute) {
      tabMinutes.push(i < 10 ? "0" + i : i + "");
    }
    let tabHours = [];
    for (let i = 0; i < 24; i++) {
      tabHours.push(i < 10 ? "0" + i : i + "");
    }
    return {
      hourSelected: "00",
      minuteSelected: "00",
      tabHours,
      tabMinutes
    };
  },
  watch: {
    value(v) {
      this.setValue(v);
    }
  },
  mounted() {
    if (this.value) this.setValue(this.value);
  },
  methods: {
    setValue(val) {
      let tabhour = val.split(":");
      this.hourSelected = tabhour[0];
      this.minuteSelected = tabhour[1];
    },
    setHour(hour) {
      this.hourSelected = hour;
      this.setTime();
    },
    setMinute(minute) {
      this.minuteSelected = minute;
      this.setTime();
      this.$emit("setMinute", {
        hour: this.hourSelected,
        minutes: this.minuteSelected
      });
    },
    setTime() {
      this.$emit("setTime", {
        hour: this.hourSelected,
        minutes: this.minuteSelected
      });
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../../node_modules/bootstrap/scss/bootstrap.scss";

.icon-menu {
  cursor: pointer;
}
.m-hour {
  padding: 15px;
  .titre {
    border-bottom: solid 1px gray;
  }
  .btn-time {
    width: 40px;
    cursor: pointer;
  }
  .selected {
    background-color: $green;
  }
}
</style>
