var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _vm._m(0),
    _vm._m(1),
    _c(
      "div",
      {
        staticClass: "container pb-1",
        staticStyle: { "background-color": "#6ccdb2" },
        attrs: { id: "page_subscription" }
      },
      [
        _c("div", { staticClass: "row mb-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "row align-items-end" }, [
              _vm._m(2),
              _c(
                "div",
                {
                  staticClass:
                    "\n              offset-lg-2\n              col-4 col-sm-4 col-lg-2\n              text-center\n              align-items-end\n              d-flex\n              justify-content-center\n              mb-3\n              mt-5\n            "
                },
                [
                  _c("div", [
                    _c(
                      "h3",
                      {
                        class: {
                          "mb-3": true,
                          "part-subtitle": true,
                          over: _vm.part == "part1"
                        }
                      },
                      [_vm._v(" 1 ")]
                    ),
                    _c(
                      "h4",
                      {
                        class: {
                          small: _vm.screenWidth < 576,
                          "part-subtitle": true,
                          over: _vm.part == "part1"
                        }
                      },
                      [_vm._v(" VOTRE"), _c("br"), _vm._v("COMPTE ")]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "\n              col-4 col-sm-4 col-lg-2\n              text-center\n              align-items-end\n              d-flex\n              justify-content-center\n              mb-3\n            "
                },
                [
                  _c("div", { attrs: { id: "contact_selected" } }, [
                    _c(
                      "h3",
                      {
                        class: {
                          "mb-3": true,
                          "part-subtitle": true,
                          over: _vm.part == "part2"
                        }
                      },
                      [_vm._v(" 2 ")]
                    ),
                    _c(
                      "h4",
                      {
                        class: {
                          small: _vm.screenWidth < 576,
                          "part-subtitle": true,
                          over: _vm.part == "part2"
                        }
                      },
                      [_vm._v(" VOS"), _c("br"), _vm._v("COORDONNEES ")]
                    )
                  ])
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "\n              col-4 col-sm-4 col-lg-2\n              text-center\n              align-items-end\n              d-flex\n              justify-content-center\n              mb-3\n            "
                },
                [
                  _c("div", { attrs: { id: "confirm_selected" } }, [
                    _c(
                      "h3",
                      {
                        class: {
                          "mb-3": true,
                          "part-subtitle": true,
                          over: _vm.part == "part3"
                        }
                      },
                      [_vm._v(" 3 ")]
                    ),
                    _c(
                      "h4",
                      {
                        class: {
                          small: _vm.screenWidth < 576,
                          "part-subtitle": true,
                          over: _vm.part == "part3"
                        }
                      },
                      [
                        _vm._v(" CONFIRMATION"),
                        _c("br"),
                        _vm._v("DES DONNEES ")
                      ]
                    )
                  ])
                ]
              )
            ])
          ])
        ]),
        _vm.part == "part1"
          ? _c("div", { staticStyle: { display: "block" } }, [
              _vm._m(3),
              _c("form", { attrs: { name: "account", id: "form_account" } }, [
                _c("div", { staticClass: "row mt-3" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "co_email_family" } }, [
                        _vm._v("VOTRE EMAIL")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.co_login,
                            expression: "co_login"
                          }
                        ],
                        staticClass: "form-control",
                        staticStyle: { border: "none", "border-radius": "0" },
                        attrs: {
                          type: "email",
                          id: "co_login",
                          name: "co_login"
                        },
                        domProps: { value: _vm.co_login },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.co_login = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "co_password1" } }, [
                        _vm._v("VOTRE MOT DE PASSE")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.co_password1,
                            expression: "co_password1"
                          }
                        ],
                        staticClass: "form-control",
                        staticStyle: { border: "none", "border-radius": "0" },
                        attrs: {
                          type: "password",
                          id: "co_password1",
                          name: "co_password"
                        },
                        domProps: { value: _vm.co_password1 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.co_password1 = $event.target.value
                          }
                        }
                      })
                    ]),
                    _c("div", { staticClass: "form-group" }, [
                      _c("label", { attrs: { for: "co_password2" } }, [
                        _vm._v("CONFIRMEZ VOTRE MOT DE PASSE")
                      ]),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.co_password2,
                            expression: "co_password2"
                          }
                        ],
                        staticClass: "form-control",
                        staticStyle: { border: "none", "border-radius": "0" },
                        attrs: { type: "password", id: "co_password2" },
                        domProps: { value: _vm.co_password2 },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.co_password2 = $event.target.value
                          }
                        }
                      })
                    ]),
                    _vm.dialogErrTxt
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(" " + _vm._s(_vm.dialogErrTxt) + " ")
                        ])
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button", id: "btstep1" },
                        on: { click: _vm.post_step1 }
                      },
                      [_vm._v(" ETAPE SUIVANTE ")]
                    )
                  ]),
                  _vm._m(4)
                ])
              ])
            ])
          : _vm._e(),
        _vm.part == "part2"
          ? _c(
              "div",
              { staticStyle: { display: "block" } },
              [
                _c("form-contact", {
                  attrs: { row_co: _vm.row_co },
                  on: { checkphone: _vm.checkPhone }
                }),
                _c("div", { staticClass: "row mt-4 mb-5" }, [
                  _c("div", { staticClass: "col-md-4" }, [
                    _vm.dialogErrTxt
                      ? _c("div", { staticClass: "alert alert-danger" }, [
                          _vm._v(" " + _vm._s(_vm.dialogErrTxt) + " ")
                        ])
                      : _vm._e(),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.post_step2 }
                      },
                      [_vm._v(" ETAPE SUIVANTE")]
                    ),
                    _c("br"),
                    _c("br"),
                    _c(
                      "button",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.$store.state.co_webaccesstoken_temp,
                            expression: "$store.state.co_webaccesstoken_temp"
                          }
                        ],
                        staticClass: "btn btn-danger",
                        attrs: { type: "button" },
                        on: {
                          click: function($event) {
                            return _vm.resetLocalStorage()
                          }
                        }
                      },
                      [_vm._v(" RECOMMENCER MON INSCRIPTION ")]
                    )
                  ]),
                  _vm._m(5)
                ])
              ],
              1
            )
          : _vm._e(),
        _vm.part == "part4"
          ? _c(
              "div",
              {
                staticStyle: { display: "block" },
                attrs: { id: "sante_form" }
              },
              [
                _c("div", { staticClass: "row text-left" }, [
                  _c("div", { staticClass: "col-md" }, [
                    _vm._m(6),
                    _c("hr"),
                    _c("h4", [
                      _vm._v(
                        " Vous souhaitez installer le kit Emotivi dans un établissement de santé (Ehpad, maison de retraite) pour votre sénior ? "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " L'installation dans un établissement de santé est plus complexe qu'une installation chez un particulier car il faut des autorisations accordées par l'établissement. "
                      )
                    ]),
                    _c("p", [
                      _vm._v(
                        " Merci de demander au responsable de l’établissement de santé de nous contacter par email (contact@emotivi.io) en précisant : le nom et l’adresse de l’établissement, le nom du directeur ou responsable, son numéro de téléphone et le nom de votre sénior. "
                      )
                    ]),
                    _c("br"),
                    _c("br"),
                    _c("br"),
                    _c("br"),
                    _c(
                      "button",
                      {
                        staticClass: "btn btn-primary",
                        attrs: { type: "button" },
                        on: { click: _vm.get_step3 }
                      },
                      [_vm._v(" ETAPE SUIVANTE ")]
                    )
                  ])
                ])
              ]
            )
          : _vm._e(),
        _vm.part == "part3"
          ? _c("div", { staticStyle: { display: "block" } }, [
              _c("div", { staticClass: "row text-left" }, [
                _c("div", { staticClass: "col-md" }, [
                  _vm._m(7),
                  _c("hr"),
                  _vm._m(8),
                  _c("h4", [
                    _vm._v("Voici un récapitulatif de vos informations :")
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("h5", [
                        _vm._v(" Adresse de facturation "),
                        !_vm.row_co.co_adresses_different
                          ? _c("span", [_vm._v(" et de livraison")])
                          : _vm._e()
                      ]),
                      _vm._v(
                        " Votre prénom : " +
                          _vm._s(_vm.row_co.co_invoice_firstname)
                      ),
                      _c("br"),
                      _vm._v(
                        " Votre nom : " + _vm._s(_vm.row_co.co_invoice_name)
                      ),
                      _c("br"),
                      _vm._v(
                        " Votre téléphone : " +
                          _vm._s(_vm.row_co.co_invoice_tel)
                      ),
                      _c("br"),
                      _vm._v(" Votre adresse :"),
                      _c("br"),
                      _c("span", {
                        domProps: { innerHTML: _vm._s(_vm.adressfacturation) }
                      })
                    ]),
                    _vm.row_co.co_adresses_different
                      ? _c(
                          "div",
                          {
                            staticClass: "col",
                            attrs: { id: "confirm_livraison" }
                          },
                          [
                            _c("h5", [_vm._v("Adresse de livraison")]),
                            _vm._v(
                              " Prénom livraison : " +
                                _vm._s(_vm.row_co.co_shipping_firstname)
                            ),
                            _c("br"),
                            _vm._v(
                              " Nom livraison : " +
                                _vm._s(_vm.row_co.co_shipping_name)
                            ),
                            _c("br"),
                            _vm._v(
                              " Téléphone livraison : " +
                                _vm._s(_vm.row_co.co_shipping_tel)
                            ),
                            _c("br"),
                            _vm._v(" Adresse livraison :"),
                            _c("br"),
                            _c("span", {
                              domProps: {
                                innerHTML: _vm._s(_vm.adresslivraison)
                              }
                            })
                          ]
                        )
                      : _vm._e()
                  ]),
                  _c(
                    "a",
                    {
                      staticStyle: { "text-transform": "none" },
                      attrs: { href: "#" },
                      on: { click: _vm.get_step2 }
                    },
                    [_vm._v("Modifier les coordonnées")]
                  ),
                  _c("hr"),
                  _c("div", {
                    staticClass: "row",
                    staticStyle: { display: "block" },
                    attrs: { id: "recapChart" }
                  }),
                  _c("div"),
                  _c("div", { staticClass: "row mt-4 mb-5" }, [
                    _c("div", { staticClass: "col" }, [
                      _c("div", {}, [
                        _c("input", {
                          staticStyle: {
                            "margin-top": "-10px",
                            width: "auto",
                            height: "auto"
                          },
                          attrs: { type: "checkbox", id: "cguid" },
                          on: { change: _vm.cguAccept }
                        }),
                        _vm._v("  "),
                        _c(
                          "label",
                          {
                            staticClass: "mb-0",
                            staticStyle: { "font-size": "1rem" },
                            attrs: { for: "cguid" }
                          },
                          [_vm._v(" J'accepte les CGU ")]
                        ),
                        _vm._m(9)
                      ]),
                      _c("br"),
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-primary",
                          staticStyle: { width: "auto" },
                          attrs: {
                            type: "button",
                            id: "btnpay",
                            disabled: !_vm.cguAccepted
                          },
                          on: { click: _vm.post_step3 }
                        },
                        [
                          _vm._v(
                            " PROCÉDER AU PAIEMENT DE LA CAUTION - " +
                              _vm._s(_vm.subscriptionAmount / 100) +
                              " € "
                          )
                        ]
                      ),
                      _c("br"),
                      _c("br"),
                      _c("div", { staticClass: "alert alert-info col-7" }, [
                        _vm.row_co.co_box_connection_type === "CLE4G_EMOTIVI"
                          ? _c("p", [
                              _vm._v(
                                " Le paiement de 60€ correspond à l'achat de la clé 4G. "
                              )
                            ])
                          : _vm._e(),
                        _c("p", {
                          domProps: {
                            innerHTML: _vm._s(
                              _vm.$options.filters.nl2br(
                                _vm.offer.of_web_description
                              )
                            )
                          }
                        }),
                        _c("p", [
                          _vm._v(
                            " Vous trouverez les tarifs des composants du kit Emotivi en cas de perte ou détérioration en cliquant sur ce "
                          ),
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  _vm.$config.server_url +
                                  "/files/btoc/emotivi-tarifs-composants.pdf",
                                target: "_blank"
                              }
                            },
                            [_vm._v("lien")]
                          )
                        ])
                      ]),
                      _c("br"),
                      _c("br"),
                      _c("p")
                    ])
                  ]),
                  _c("div")
                ])
              ])
            ])
          : _vm._e()
      ]
    ),
    _vm._m(10)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "top" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col py-2" }, [
            _c("div", { staticClass: "d-flex justify-content-end" }, [
              _c("div", [
                _c("div", { staticClass: "question" }, [
                  _vm._v(" Une question ? "),
                  _c("strong", [
                    _c(
                      "a",
                      {
                        staticStyle: { color: "#ffffff" },
                        attrs: { href: "tel:+33493333887" }
                      },
                      [_vm._v("04 93 33 38 87")]
                    )
                  ]),
                  _vm._v(" nous serons ravis de vous conseiller ")
                ])
              ])
            ])
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "header" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row p-3" }, [
          _c(
            "div",
            { staticClass: "col d-flex flex-column justify-content-center" },
            [
              _c("h1", [_vm._v("Convaincu par Emotivi ?")]),
              _c("h2", { staticStyle: { color: "#159bac" } }, [
                _vm._v("Passez à l'émotion grand format")
              ]),
              _c("h3", { staticClass: "mb-4" }, [
                _vm._v(" Avec le crédit d'impôt, Emotivi c'est seulement"),
                _c("br"),
                _c("strong", [_vm._v("15 €/mois")]),
                _c("span", { staticClass: "minus" }, [
                  _vm._v(" ou 30€/mois sans aide ")
                ])
              ]),
              _c("div", [
                _c("strong", [
                  _vm._v("Essayez gratuitement Emotivi pendant 1 mois !")
                ])
              ]),
              _c("div", [
                _vm._v(
                  " Vous n'avez que la caution du kit à avancer qui vous sera remboursée dès retour de votre kit. "
                )
              ])
            ]
          ),
          _c("div", { staticClass: "col-md-4" }, [
            _c("img", {
              staticClass: "img-fluid",
              attrs: { src: "/images/visioconference-seniors.png", alt: "" }
            })
          ])
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12 col-lg-4 pt-4" }, [
      _c("div", { staticClass: "mb-3" }, [
        _c("img", {
          staticStyle: { width: "70px" },
          attrs: { src: "/images/ico-interface.png", alt: "" }
        })
      ]),
      _c("div", [
        _c("h2", [_vm._v("COMMANDER"), _c("br"), _vm._v("VOTRE KIT EMOTIVI")])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("h5", { staticStyle: { "max-width": "600px" } }, [
        _vm._v(
          " Créez votre compte qui vous permettra de recevoir votre kit EMOTIVI, gérer votre abonnement et gérer vos options "
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-9" }, [
      _c("p", {
        staticStyle: { color: "#a4145a" },
        attrs: { id: "email_already_exist" }
      })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col-md-9 align-items-end d-flex justify-content-start" },
      [
        _c("p", {
          staticStyle: { color: "#a4145a" },
          attrs: { id: "error_msg_contact" }
        })
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row text-center" }, [
      _c("div", { staticClass: "col" }, [_c("h2", [_vm._v("IMPORTANT")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "row text-center" }, [
      _c("div", { staticClass: "col" }, [_c("h2", [_vm._v("CONFIRMATION")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "mb-0" }, [
      _vm._v(" Votre compte Emotivi a été créé avec succès."),
      _c("br")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "mt-0" }, [
      _c(
        "a",
        {
          staticClass: "ml-4 small-text pointer",
          attrs: { href: "/files/CGU-Emotivi-20201209.pdf" }
        },
        [_vm._v("Voir les CGU")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("section", { staticClass: "footer" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row p-3" }, [
          _c("div", { staticClass: "col-6 col-md-4" }, [
            _c("h5", [_vm._v("Adresse")]),
            _vm._v(" LA.TEAM - Emotivi"),
            _c("br"),
            _vm._v(" « Le Thélème »"),
            _c("br"),
            _vm._v(" 1501, route des Dolines"),
            _c("br"),
            _vm._v(" 06560 Valbonne"),
            _c("br"),
            _c("h5", { staticClass: "mt-4" }, [
              _vm._v("Pour les professionnels")
            ]),
            _vm._v(" pro@emotivi.fr"),
            _c("br"),
            _vm._v(" Tél. : "),
            _c(
              "a",
              {
                staticStyle: { color: "#ffffff" },
                attrs: { href: "tel:+33423360314" }
              },
              [_vm._v("04 23 36 03 14")]
            ),
            _c("br"),
            _vm._v(" Du lundi au vendredi"),
            _c("br"),
            _vm._v(" Horaires : 9h - 18h"),
            _c("br")
          ]),
          _c("div", { staticClass: "col-6 col-md-4" }, [
            _c("h5", [_vm._v("Contact commercial")]),
            _vm._v(" contact@emotivi.fr"),
            _c("br"),
            _vm._v(" Tél. : "),
            _c(
              "a",
              {
                staticStyle: { color: "#ffffff" },
                attrs: { href: "tel:+33493333887" }
              },
              [_vm._v("04 93 33 38 87")]
            ),
            _c("br"),
            _vm._v(" Du lundi au vendredi"),
            _c("br"),
            _vm._v(" Horaires : 9h - 18h"),
            _c("br"),
            _c("h5", { staticClass: "mt-4" }, [_vm._v("Contact technique")]),
            _vm._v(" support@emotivi.fr"),
            _c("br"),
            _vm._v(" Tél. : "),
            _c(
              "a",
              {
                staticStyle: { color: "#ffffff" },
                attrs: { href: "tel:+33754354703" }
              },
              [_vm._v("07 54 35 47 03")]
            ),
            _c("br"),
            _vm._v(" Du lundi au vendredi"),
            _c("br"),
            _vm._v(" Horaires : 9h - 18h"),
            _c("br")
          ]),
          _c("div", { staticClass: "col-12 col-md-4" }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between flex-wrap p-3" },
              [
                _c("div", { staticClass: "me-3" }, [
                  _c("img", {
                    staticClass: "img-fluid",
                    staticStyle: { "max-width": "150px" },
                    attrs: { src: "/images/logo-bpi.png", alt: "" }
                  })
                ]),
                _c("div", [
                  _c("img", {
                    staticClass: "img-fluid",
                    staticStyle: { "max-width": "150px" },
                    attrs: { src: "/images/logo-ue-region.png", alt: "" }
                  })
                ])
              ]
            ),
            _c("h5", { staticClass: "mt-5" }, [_vm._v("Légales")]),
            _c(
              "a",
              {
                staticStyle: { color: "white" },
                attrs: {
                  href:
                    "https://emotivi.fr/mentions-legales-et-politique-de-confidentialite.html",
                  target: "_blank"
                }
              },
              [_vm._v("Mentions légales & Politique de confidentialité")]
            ),
            _c("br"),
            _c(
              "a",
              {
                staticStyle: { color: "white" },
                attrs: {
                  href: "https://emotivi.fr/cookies.html",
                  target: "_blank"
                }
              },
              [_vm._v("Cookies")]
            ),
            _c("br"),
            _c(
              "a",
              {
                staticStyle: { color: "white" },
                attrs: {
                  href: "https://emotivi.fr/images/CGU-Emotivi-20201209.pdf",
                  target: "_blank"
                }
              },
              [_vm._v("CGU")]
            ),
            _c("br")
          ])
        ])
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }