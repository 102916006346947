<template>
  <section class="bg-gray-light">
    <div class="container text-center p-0">
      <div class="notfound-box mt-3 mb-3">
        <div class="row">
          <div class="col">
            <icon name="exclamation-triangle" scale="5"></icon>
          </div>
        </div>
        <div class="row">
          <div class="col title-error">
            404
          </div>
        </div>
        <div class="row mb-3">
          <div class="col">
            La page que vous recherchez n'existe pas (ou plus).
          </div>
        </div>
        <div class="row">
          <div class="col">
            <button type="button" @click="redirect()" class="go-home">
              ACCUEIL
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "notfound",
  components: {},
  data() {
    return {};
  },
  mounted() {},
  methods: {
    redirect() {
      this.$router.push("/");
    }
  }
};
</script>

<style lang="scss" scoped>
.notfound-box {
  background-color: #853462;
  border-radius: 10px;
  color: white;
  padding: 10px;
}
.title-error {
  font-size: 100px;
}
.go-home {
  background-color: #68294c;
  color: white;
  border: none;
  height: 40px;
  width: 120px;
  border-radius: 10px;
  &:hover {
    background-color: #4d1e38;
  }
}
</style>
