<template>
  <section>
    <div class="container mb-5 mt-5 text-center" id="globalContainer">
      <h1 class="mb-1">Vous êtes maintenant connecté</h1>
      <p><i>Vous pouvez gérer votre compte à l'aide du menu ci-dessous.</i></p>
      <div class="row justify-content-center">
        <div
          class="col-xl-3 col-lg-3 cold-md-3 cold-sm-12 col-12 btn-account"
          @click="$router.push('/private/account')"
        >
          <div>
            <icon
              name="user-circle"
              scale="4"
              class="mt-4 mb-4"
              fill="white"
            ></icon>
          </div>
          <div>
            <h4>MON<br />COMPTE</h4>
          </div>
        </div>
        <div
          v-if="hasPayment"
          class="col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-12 col-12 btn-account"
          @click="$router.push('/private/payments')"
        >
          <div>
            <icon
              name="credit-card"
              scale="4.5"
              class="mt-4 mb-4"
              fill="white"
            ></icon>
          </div>
          <div>
            <h4>MES<br />PAIEMENTS</h4>
          </div>
        </div>
        <div
          v-if="hasBoxes"
          class="col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-12 col-12 btn-account"
          @click="$router.push('/private/box')"
        >
          <div>
            <icon
              name="box-open"
              scale="5"
              class="mt-4 mb-4"
              fill="white"
            ></icon>
          </div>
          <div>
            <h4>MES KITS<br />EMOTIVI</h4>
          </div>
        </div>
        <!-- <div
          class="col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-12 col-12 btn-account"
          @click="$router.push('/private/purchasekey4g')"
        >
          <div>
            <icon
              name="sim-card"
              scale="4"
              class="mt-4 mb-4"
              fill="white"
            ></icon>
          </div>
          <div>
            <h4>COMMANDER<br />UNE CLE 4G</h4>
          </div>
        </div> -->
        <!--div
          class="col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-12 col-12 btn-account"
          @click="$router.push('/private/purchaserouter4g')"
        >
          <div>
            <icon
              name="sim-card"
              scale="4"
              class="mt-4 mb-3"
              fill="white"
            ></icon>
          </div>
          <div>
            <h4>COMMANDER<br />UN ROUTEUR 4G</h4>
          </div>
        </div-->
        <div
          v-if="hasTvs"
          class="col-xl-3 offset-xl-1 col-lg-3 offset-lg-1 col-md-3 offset-md-1 col-sm-12 col-12 btn-account"
          @click="$router.push('/private/tv')"
        >
          <div>
            <icon name="tv" scale="5" class="mt-4 mb-4" fill="white"></icon>
          </div>
          <div>
            <h4>MES TVs<br />EMOTIVI</h4>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "privatehome",
  mounted() {

    console.log(this.row_co, this.endusers);

    this.$store.commit("set_co_webaccesstoken_temp", "");
  },
  computed: {
    hasPayment() {
      return this.$store.state.user.endusers &&
        Array.isArray(this.$store.state.user.endusers) &&
        this.$store.state.user.endusers.length
        ? true
        : false;
    },
    hasBoxes() {
      return this.$store.state.user.endusers &&
        Array.isArray(this.$store.state.user.endusers) &&
        this.$store.state.user.endusers.length
        ? this.$store.state.user.endusers.filter(enduser => {
            return enduser.bo_id;
          }).length
        : 0;
    },
    hasTvs() {
      return this.$store.state.user.endusers &&
        Array.isArray(this.$store.state.user.endusers) &&
        this.$store.state.user.endusers.length
        ? this.$store.state.user.endusers.filter(enduser => {
            return enduser.de_id;
          }).length
        : 0;
    }
  },
  methods: {}
};
</script>

<style lang="scss" scoped>
.btn-account {
  height: 175px;
  max-width: 175px;
  text-align: center;
  background-color: #179bb0;
  color: white;
  margin: 10px 15px;
}
</style>
