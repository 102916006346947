<template>
  <section>
    <div
      class="container pb-1"
      style="background-color: #6ccdb2"
      id="page_subscription"
    >
      <div class="row mb-5">
        <div class="col">
          <div class="row align-items-end">
            <div class="col-md-12 col-lg-5 col-xl-6 pt-4 align-self-start">
              <div class="mb-3">
                <img
                  src="/images/ico-interface.png"
                  alt=""
                  style="width: 70px"
                />
              </div>
              <div>
                <h2>ABONNEMENT EMOTIVI</h2>
                <div v-if="dialogErrTxt" class="alert alert-danger">
                  {{ dialogErrTxt }}
                </div>
                <div v-if="dialogTxt" class="alert alert-info">
                  {{ dialogTxt }}
                </div>
                <div
                  v-if="activeSubscription && activeSubscription.su_enddate"
                  class="alert alert-info"
                >
                  <span>Un abonnement est actuellement en cours jusqu'au </span
                  ><span class="text-info font-weight-bolder">{{
                    $moment(activeSubscription.su_enddate).format("DD-MM-YYYY")
                  }}</span
                  ><br /><span
                    >Veuillez choisir une date de démarrage supérieur pour le
                    renouvellement.</span
                  >
                </div>
                <div
                  v-if="
                    programmedSubscription && programmedSubscription.su_enddate
                  "
                  class="alert alert-info"
                >
                  <span>Un abonnement est programmé jusqu'au </span
                  ><span class="text-info font-weight-bolder">{{
                    $moment(programmedSubscription.su_enddate).format(
                      "DD-MM-YYYY"
                    )
                  }}</span
                  ><br /><span
                    >Veuillez choisir une date de démarrage supérieur pour le
                    nouvel abonnement.</span
                  >
                </div>
                <div class="form-group">
                  <label for="su_email">EMAIL</label>
                  <input
                    name="su_email"
                    type="text"
                    class="form-control"
                    id="su_email"
                    v-model="subscriptionData.su_email"
                    style="border: none; border-radius: 0"
                  />
                </div>
                <div class="form-group">
                  <label for="of_id">CHOISISSEZ VOTRE ABONNEMENT</label>
                  <select
                    name="of_id"
                    id="of_id"
                    v-model="subscriptionData.of_id"
                    class="form-control"
                    style="border: none; border-radius: 0"
                  >
                    <option
                      :value="offer.of_id"
                      v-for="offer in offers"
                      v-bind:key="offer.of_id"
                      >{{getLibelleAbonnement(offer)}}</option
                    >
                  </select>
                </div>
                <div v-if="subscriptionData.of_id">
                  <div
                    class="d-flex flex-column flex-sm-column flex-md-row flex-lg-row flex-xl-row justify-content-start align-items-center"
                  >
                    <div
                      class="d-flex justify-content-start align-items-center col-12 col-sm-12 col-md-4 col-lg-5 col-xl-5"
                    >
                      <span class="mr-2">Pour</span
                      ><input
                        v-model="subscriptionData.su_cyclenumber"
                        type="number"
                      /><span class="ml-2" v-if="getCycle('DAYS')">Jour(s)</span
                      ><span class="ml-2" v-else-if="getCycle('WEEKS')"
                        >Semaine(s)</span
                      >
                      <span class="ml-2" v-else>Mois</span>
                    </div>
                    <div
                      class="d-flex justify-content-start align-items-center col-12 col-sm-12 col-md-6 col-lg-7 col-xl-7"
                    >
                      <span class="">à partir du</span>
                      <m-form-date
                        class="col-5 col-sm-5 col-md-5 col-lg-7 col-xl-7 pr-0 pl-0 ml-2 mt-3 mr-lg-n4"
                        label=""
                        :name="$Utils.randomstring('su_startdate')"
                        formatInput="DD/MM/YYYY"
                        formatValue="YYYY-MM-DD HH:mm:ss"
                        v-model="subscriptionData.su_startdate"
                      ></m-form-date>
                    </div>
                  </div>
                  <div
                    v-if="subscriptionData.su_enddate"
                    class="form-group mt-4 "
                  >
                    <span>Jusqu'au :</span>
                    <span class="text-white font-weight-bold ml-2">{{
                      $moment(subscriptionData.su_enddate).format("DD-MM-YYYY")
                    }}</span>
                  </div>
                  <!-- <div class="form-group mt-4">
                    <label for="paymentMethod">MODE DE PAIEMENT</label>
                    <select
                      name="paymentMethod"
                      id="paymentMethod"
                      v-model="paymentMethod"
                      class="form-control"
                      style="border: none; border-radius: 0"
                    >
                      <option
                        :value="method.value"
                        v-for="method in paymentsMethods"
                        v-bind:key="method.value"
                        >{{ method.text }}</option
                      >
                    </select>
                  </div> -->
                  <div class="mt-5">
                    <button
                      @click="prepareCheckoutData()"
                      type="button"
                      class="btn btn-primary"
                    >
                      S'ABONNER
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="paymentMethod === 'rib'"
              class="col-md-12 col-lg-7 col-xl-6 pt-4"
            >
              <private-info-bancaire-vue
                :nocontact="true"
                :formcol="12"
                :formcolsm="12"
                :formcolmd="10"
                :formcolxl="10"
                @nextStep="getRibData"
              ></private-info-bancaire-vue>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";
import PrivateInfoBancaireVue from "./PrivateInfoBancaire.vue";
// Vue.component("v-select", vSelect);

export default {
  name: "subscriptionlimitedperiod",
  components: { PrivateInfoBancaireVue },
  data() {
    return {
      subscriptionAmount: 0,
      dialogErrTxt: "",
      dialogTxt: "",
      cguAccepted: false,
      offers: {},
      cycleName: {
        WEEKS: "semaine",
        DAYS: "jour",
        MONTHS: "mois"
      },
      paymentsMethods: [
        { text: "Carte bancaire", value: "cb" },
        { text: "Prélèvement SEPA", value: "rib" }
      ],
      subscriptionData: {
        su_email: "",
        su_startdate: this.$moment().format("YYYY-MM-DD HH:mm:ss"),
        of_id: 0,
        en_id: this.$route.params.en_id,
        su_cyclenumber: 1,
        su_enddate: null
      },
      paymentMethod: "cb",
      paymentData: {
        pm_rib: false,
        pm_rib_authorization: false,
        pm_rib_iban: "",
        pm_rib_bic: "",
        pm_rib_name: ""
      },
      renewSubscription: false,
      activeSubscription: null,
      programmedSubscription: null
    };
  },
  mounted() {
    this.getSubscriptionList();
  },
  watch: {
    ["subscriptionData.su_startdate"](value) {
      this.subscriptionData.su_enddate = this.$moment(value)
        .add(
          this.subscriptionData.su_cyclenumber * 1,
          this.getCycleName("key").toLowerCase()
        )
        .format("YYYY-MM-DD HH:mm:ss");
    },
    ["subscriptionData.su_cyclenumber"](value) {
      this.subscriptionData.su_enddate = this.$moment(
        this.subscriptionData.su_startdate
      )
        .add(value * 1, this.getCycleName("key").toLowerCase())
        .format("YYYY-MM-DD HH:mm:ss");
    },
    ["subscriptionData.of_id"](value) {
      this.subscriptionData.su_enddate = this.$moment(
        this.subscriptionData.su_startdate
      )
        .add(
          this.subscriptionData.su_cyclenumber * 1,
          this.getCycleName("key").toLowerCase()
        )
        .format("YYYY-MM-DD HH:mm:ss");
    }
  },
  methods: {
    cguAccept() {
      this.cguAccepted = !this.cguAccepted;
    },
    getCycle(cycle) {
      if (this.subscriptionData.of_id) {
        let selectedOffer = this.offers.filter(offer => {
          return offer.of_id === this.subscriptionData.of_id;
        });
        if (selectedOffer && selectedOffer.length)
          selectedOffer = selectedOffer[0];
        if (
          selectedOffer.offersproducts &&
          Array.isArray(selectedOffer.offersproducts) &&
          selectedOffer.offersproducts[0].ofpr_subscription_cycle_billing ===
            cycle
        )
          return true;
        else return false;
      }
    },
    getCycleName(key) {
      if (this.subscriptionData.of_id) {
        let selectedOffer = this.offers.filter(offer => {
          return offer.of_id === this.subscriptionData.of_id;
        });
        if (selectedOffer && selectedOffer.length)
          selectedOffer = selectedOffer[0];
        if (
          selectedOffer.offersproducts &&
          Array.isArray(selectedOffer.offersproducts) &&
          selectedOffer.offersproducts[0].ofpr_subscription_cycle_billing
        ) {
          if (key)
            return selectedOffer.offersproducts[0]
              .ofpr_subscription_cycle_billing;
          else
            return this.cycleName[
              selectedOffer.offersproducts[0].ofpr_subscription_cycle_billing
            ];
        }
      }
    },
    async getSubscriptionList() {
      let response = await this.$axios.get(
        this.$config.server_url + "/web/2.0/subscription/offers",
        { params: { of_key: "emotivi_tv_subscription" } }
      );
      if (response.data && response.data.err)
        return (this.dialogErrTxt = "Une erreur est survenue.");

      this.offers = response.data.data;
    },

    async prepareCheckoutData() {
      this.dialogErrTxt = "";

      /** http request to create subscription and prepare stripe checkout */
      let body = {
        subscriptionData: this.subscriptionData,
        paymentMethod: this.paymentMethod
      };
      if (this.paymentMethod === "rib") body.paymentData = this.paymentData;
      let response = await this.$axios.post(
        this.$config.server_url +
          "/web/2.0/subscription/limitedperiod/" +
          this.renewSubscription,
        body
      );

      /** manage errors */
      if (!response.data)
        return (this.dialogErrTxt = "Une erreur est survenue.");

      if (response.data.err) {
        if (response.data.err.key == "email_mandatory")
          this.dialogErrTxt = "Email obligatoire";
        else if (response.data.err.key == "date_mandatory")
          this.dialogErrTxt = "Date obligatoire";
        else if (response.data.err.key == "email_format_error")
          this.dialogErrTxt = "Format d'email non valide";
        else if (response.data.err.key == "cyclenumber_mandatory")
          this.dialogErrTxt = `Veuillez saisir le nombre de ${this.getCycleName()}`;
        else if (response.data.err.key == "paymentmethod_mandatory")
          this.dialogErrTxt = `Methode de paiement obligatoire`;
        else if (response.data.err.key == "offer_mandatory")
          this.dialogErrTxt =
            "Une erreur est survenue sur le choix de l'abonnement";
        else if (response.data.err.key == "rib_mandatory")
          this.dialogErrTxt =
            "Veuillez cocher la case : Je souhaite payer par prélévement bancaire.";
        else if (response.data.err.key == "rib_authorization_mandatory")
          this.dialogErrTxt =
            "Veuillez cocher la case : Je valide le mandat autorisant Emotivi à présenter des ordres de prélèvement sur le compte bancaire ouvert à mon nom.";
        else if (response.data.err.key == "rib_iban_mandatory")
          this.dialogErrTxt = "Veuillez renseigner l'IBAN.";
        else if (response.data.err.key == "rib_bic_mandatory")
          this.dialogErrTxt = "Veuillez renseigner le BIC.";
        else if (response.data.err.key == "rib_name_mandatory")
          this.dialogErrTxt = "Veuillez renseigner le titulaire du compte.";
        else if (
          response.data.err.key === "subscription_startdate_before_current_date"
        ) {
          this.dialogErrTxt =
            "Veuillez renseigner une date de démarrage supérieur ou égal à la date d'aujourd'hui.";
        } else this.dialogErrTxt = "Une erreur est survenue.";
        return;
      }

      /** if a subscription already exist and active */
      if (response.data.data.key === "subscription_active") {
        if (!this.renewSubscription) {
          let ok = window.confirm(
            "Un abonnement est déjà en cours, souhaitez vous le renouveler ? Il prendra effet à la fin de la date de l'abonnement en cours ou à la date de démarrage définie dans ce formulaire."
          );
          if (!ok) return;
          this.renewSubscription = true;
          this.activeSubscription = response.data.data.row_su;
          return;
        }
      }
      /** if a subscription already exist and programmed in the futur */
      if (response.data.data.key === "subscription_programmed") {
        if (!this.renewSubscription) {
          let ok = window.confirm(
            "Un abonnement est déjà programmé, souhaitez vous en programmer un nouveau ? Il prendra effet à la fin de la date du dernier abonnement enregistré ou à la date de démarrage définie dans ce formulaire."
          );
          if (!ok) return;
          this.renewSubscription = true;
          this.programmedSubscription = response.data.data.row_su;
          return;
        }
      }
      if (
        response.data.data.key === "subscription_renew_startdate_before_current"
      ) {
        this.dialogErrTxt =
          "La date de démarrage du renouvellement que vous avez saisi est inférieur à la date de fin de l'abonnement en cours.";
        return;
      }

      if (this.paymentMethod === "cb") {
        /** prepare stripe checkout and redirect to stripe checkout url */
        const stripe = await loadStripe(response.data.data.stripe_apikeypublic);
        stripe
          .redirectToCheckout({
            sessionId: response.data.data.CHECKOUT_SESSION_ID
          })
          .then(function(result) {
            console.warn(result);
          });
      }
      if (this.paymentMethod === "rib") {
        if (response.data.data.success_url)
          this.$router.push(response.data.data.success_url);
      }
    },
    getRibData(ribData) {
      this.paymentData.pm_rib = ribData.co_rib;
      this.paymentData.pm_rib_authorization = ribData.co_rib_authorization;
      this.paymentData.pm_rib_iban = ribData.co_rib_iban;
      this.paymentData.pm_rib_bic = ribData.co_rib_bic;
      this.paymentData.pm_rib_name = ribData.co_rib_name;
      this.dialogTxt = "Informations bancaires validées";
    },
    getLibelleAbonnement(offer) {
        let montantHT = offer.offersproducts[0].ofpr_subscription_price_ht;
        let montant = Math.round((parseFloat(montantHT) + (montantHT * 20) / 100) / 100) ;
         return offer.of_name + ' (' + montant + ' €)';
    }
}
};
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 50px;
  // padding-top: 40px;
}
</style>
