var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "bg-gray-light" }, [
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row my-3 justify-content-center" }, [
        _vm.ok
          ? _c(
              "div",
              {
                staticClass:
                  "col-xl-4 col-md-5 col-sm-8 col-8 tile-success text-center p-3"
              },
              [
                _c(
                  "div",
                  [
                    _c("icon", {
                      staticClass: "mb-2",
                      staticStyle: { fill: "white" },
                      attrs: { name: "check-circle", scale: "4" }
                    }),
                    _c(
                      "h3",
                      {
                        staticClass: "mb-3",
                        staticStyle: { "font-weight": "bold" }
                      },
                      [_vm._v("Paiement validé")]
                    ),
                    _vm.$store.state.authenticated
                      ? _c("p", [
                          _vm._v(
                            " Un email contenant toutes les informations et identifiants a été envoyé au client. "
                          )
                        ])
                      : !_vm.account
                      ? _c("p", [
                          _vm._v(
                            " Féliciation, vous pouvez désormais utiliser le service Emotivi et communiquer avec votre proche en cliquant sur le bouton suivant. "
                          )
                        ])
                      : _c("p", [
                          _vm._v(
                            " Un email contenant toutes vos informations et identifiants vous a été envoyé, vous pouvez maintenant accéder à votre compte. "
                          )
                        ]),
                    _vm.$store.state.authenticated
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.redirect("affiliate")
                              }
                            }
                          },
                          [_vm._v(" PASSER UNE NOUVELLE COMMANDE ")]
                        )
                      : !_vm.account
                      ? _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.redirect("noaccount")
                              }
                            }
                          },
                          [_vm._v(" ACCÉDER À EMOTIVI ")]
                        )
                      : _c(
                          "button",
                          {
                            staticClass: "btn btn-primary",
                            attrs: { type: "button" },
                            on: {
                              click: function($event) {
                                return _vm.redirect()
                              }
                            }
                          },
                          [_vm._v(" VOTRE COMPTE ")]
                        )
                  ],
                  1
                )
              ]
            )
          : _c(
              "div",
              {
                staticClass:
                  "col-xl-4 col-md-5 col-sm-8 col-8 tile-failed text-center p-3"
              },
              [
                _c("icon", {
                  staticClass: "mb-2",
                  attrs: { name: "exclamation-circle", scale: "4" }
                }),
                _c(
                  "h3",
                  {
                    staticClass: "mb-3",
                    staticStyle: { "font-weight": "bold" }
                  },
                  [_vm._v(" L'enregistrement de l'IBAN a échoué. ")]
                ),
                _c("p", [
                  _vm._v(
                    " Vous pouvez ignorer cette étape et enregistrer votre IBAN ultérieurement depuis votre espace personnel "
                  )
                ]),
                _c(
                  "button",
                  {
                    staticClass: "btn-retry",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.goCardLess()
                      }
                    }
                  },
                  [_vm._v(" RÉESSAYER ")]
                ),
                _c(
                  "button",
                  {
                    staticClass: "btn btn-primary",
                    attrs: { type: "button" },
                    on: {
                      click: function($event) {
                        return _vm.redirect()
                      }
                    }
                  },
                  [_vm._v(" VOTRE COMPTE ")]
                )
              ],
              1
            )
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }