import Vue from "vue";
import moment from "moment";
import _ from "lodash";
import store from "./store";
// import axios from "axios";
// import config from "../config";
var numeral = require("numeral");
Vue.filter("formatsearch", function(t) {
  let txt = [];
  let filter = store.state.offersfilters;
  // txt += "<p>";
  // console.log("filter", filter);

  if (filter.sale && !filter.rent) txt.push(t("translation.Vente"));
  if (filter.rent && !filter.sale) txt.push(t("translation.Location"));
  if (filter.monaco && !filter.france) txt.push("Monaco");
  if (filter.france && !filter.monaco) txt.push(t("translation.Cote_dazur"));
  let typesTxt = [];
  _.each(filter.typesobj, (val, key) => {
    if (val) {
      let v = _.find(store.state.items.items_of_type, {
        text: key
      });
      if (v) {
        if (v.text === "Local comm.") typesTxt.push("Local comm.");
        else typesTxt.push(t("translation." + v.text) + " ");
      }
    }
  });
  if (typesTxt.length) txt.push(typesTxt.join(", "));

  let roomTxt = [];
  if (filter.room_1) roomTxt.push(t("translation.Studio"));
  if (filter.room_2) roomTxt.push("T2");
  if (filter.room_3) roomTxt.push("T3");
  if (filter.room_4) roomTxt.push("T4");
  if (filter.room_5) roomTxt.push("T5+");
  if (roomTxt.length) txt.push(roomTxt.join(", "));

  if (filter.budgetmax * 1 < 40000000 || filter.budgetmin * 1 > 0) {
    let budgetTxt = "";
    budgetTxt += t("translation.budget") + " : ";
    if (filter.budgetmax * 1 < 40000000 && filter.budgetmin * 1 > 0) {
      budgetTxt +=
        Vue.filter("formatPrice")(filter.budgetmin) +
        " - " +
        Vue.filter("formatPrice")(filter.budgetmax);
    }
    // console.log("filter.budgetmax", filter.budgetmax, filter.budgetmin);
    if (filter.budgetmax * 1 < 40000000 && filter.budgetmin * 1 === 0) {
      budgetTxt += " &lt; " + Vue.filter("formatPrice")(filter.budgetmax);
    }
    if (filter.budgetmax === 40000000 && filter.budgetmin > 0) {
      budgetTxt += " &gt; " + Vue.filter("formatPrice")(filter.budgetmin);
    }
    txt.push(budgetTxt);
  }
  // txt += "</p>";
  return txt.join(" / ");
});

/* Vue.filter("formatsearch", function() {
  let txt = "";
  let filter = store.state.offersfilters;
  if (filter.rent && filter.sale) txt += "location et vente ";
  if (filter.sale && !filter.rent) txt += "Vente ";
  if (!filter.sale && filter.rent) txt += "location ";

  _.each(filter.typesobj, (val, key) => {
    if (val) {
      let v = _.find(store.state.items.items_of_type, {
        text: key
      });
      if (v) txt += v.text + " ";
    }
  });

  if (filter.room_1) txt += " studio ";
  if (filter.room_2) txt += "T2 ";
  if (filter.room_3) txt += "T3 ";
  if (filter.room_4) txt += "T4 ";
  if (filter.room_5) txt += "T5 ";

  if (filter.monaco && !filter.france) txt += "à Monaco";
  if (filter.france && !filter.monaco) txt += "sur la cote d'azur";
  if (filter.france && filter.monaco) txt += "à Monaco et sur la cote d'azur";
  if (filter.budgetmax) txt += " - budget maximum " + filter.budgetmax + "€";
  console.log("filter", filter);
  return txt;
}); */
/* Vue.filter("formatsearchtitle", function(total) {
  let filter = store.state.offersfilters;
  let txt = "";
  if (
    !filter.rent &&
    !filter.sale &&
    !filter.france &&
    !filter.monaco &&
    !filter.budgetmax
  ) {
    txt += "<p class='mb-0'>" + total + " offre";
    if (total > 1) txt += "s";
  } else {
    txt += "<p class='mb-0'>" + total;
    if ((filter.rent && filter.sale) || (!filter.rent && !filter.sale)) {
      txt += " location";
      if (total > 1) txt += "s ";
      else txt += " ";
      txt += " et vente";
      if (total > 1) txt += "s ";
      else txt += " ";
    }
    if (filter.sale && !filter.rent) {
      txt += " vente";
      if (total > 1) txt += "s ";
      else txt += " ";
    }
    if (!filter.sale && filter.rent) {
      txt += " location";
      if (total > 1) txt += "s ";
      else txt += " ";
    }
    if (filter.monaco || filter.france) txt += " - ";
    if (filter.monaco && !filter.france) txt += "Monaco";
    if (filter.france && !filter.monaco) txt += "Cote d'azur";
    if (filter.france && filter.monaco) txt += "Monaco et Cote d'azur";
    txt += "</p>";
  }
  return txt;
}); */
// Vue.filter("formatsearchsoustitle", function() {
//   let filter = store.state.offersfilters;
//   let txt = "";
//   txt += "<p>";
//   _.each(filter.typesobj, (val, key) => {
//     if (val) {
//       let v = _.find(store.state.items.items_of_type, {
//         text: key
//       });
//       if (v) txt += v.text + " ";
//     }
//   });
//   if (filter.room_1) txt += " studio ";
//   if (filter.room_2) txt += "T2 ";
//   if (filter.room_3) txt += "T3 ";
//   if (filter.room_4) txt += "T4 ";
//   if (filter.room_5) txt += "T5 ";
//   if (filter.budgetmax) txt += "budget : " + filter.budgetmax + "€";
//   txt += "</p>";

//   return txt;
// });
Vue.filter("lowerCase", function(val) {
  return val.toLowerCase();
});
Vue.filter("formatPurcent", function(val) {
  return numeral((val * 1) / 100).format("0,0 %") + "";
});
Vue.filter("formatFromTo", function(valFrom, valTo) {
  let mF = moment(new Date(valFrom));
  let mT = moment(new Date(valTo));
  return "De " + mF.format("HH[H]mm") + " à " + mT.format("HH[H]mm");
});
Vue.filter("formatDate", function(val) {
  let v = "";
  if (_.isString(val)) {
    let m = moment(val);
    if (m.isValid()) v = m.format("DD/MM/YYYY");
  }
  if (moment.isMoment(val)) v = val.format("DD/MM/YYYY");
  else if (moment.isDate(val)) v = moment(val).format("DD/MM/YYYY");
  return v;
});
Vue.filter("formatDateTime", function(val) {
  let v = "";
  if (_.isString(val)) {
    let m = moment(val);
    if (m.isValid()) v = m.format("DD/MM/YYYY [à] HH[H]mm");
  }
  if (moment.isMoment(val)) v = val.format("DD/MM/YYYY [à] HH[H]mm");
  if (moment.isDate(val)) v = moment(val).format("DD/MM/YYYY [à] HH[H]mm");
  return v;
});
Vue.filter("formatDateAgo", function(val) {
  let v = "";
  if (_.isString(val)) {
    let m = moment(val);
    if (m.isValid()) v = m.fromNow();
  }
  if (moment.isMoment(val)) v = val.fromNow();
  if (moment.isDate(val)) v = moment(val).fromNow();
  return v;
});
Vue.filter("formatPrice", function(val, suffixe = " €") {
  val = val * 1;
  if (val >= 1000000)
    return numeral(val / 1000000).format("0,0.0") + "M" + suffixe;
  if (val >= 1000) return numeral(val / 1000).format("0,0.0") + "K" + suffixe;
  return numeral(val).format("0,0") + suffixe;
});
Vue.filter("formatNumber", function(val) {
  val = val * 1;
  return numeral(val).format("0,0");
});
Vue.filter("formatDuration", function(val) {
  if (val * 1 > 3600 * 24)
    return moment.duration(val * 1, "seconds").humanize();
  let h = moment.duration(val * 1, "seconds").hours() * 1;
  let m = Math.ceil((val * 1 - h * 3600) / 60);
  let txt = "";
  if (h > 0) txt += h + "H ";
  txt += m + "mn";
  return txt;
});
Vue.filter("formatDistance", function(val) {
  val = val * 1;
  if (val >= 1000) return numeral(val / 1000).format("0,0.0") + "Km";
  return numeral(val).format("0,0") + "m";
});
Vue.filter("formatSurface", function(val, whithoutSup) {
  let more = " m<sup>2</sup>";
  if (whithoutSup) more = "m2";
  return numeral(val * 1).format("0,0") + more;
});
Vue.filter("formatYesNo", function(val) {
  return val ? "Oui" : "Non";
});
Vue.filter("formatFromArray", function(val, myarray, mydefault) {
  let f = _.result(_.find(myarray, { value: val }), "text", mydefault);
  return f;
});
Vue.filter("formatAcStatus", function(ac_status) {
  let items_ac_status = [
    { value: "waiting", text: "En attente" },
    { value: "in_progress", text: "En cours de traitement" },
    { value: "accepted", text: "Accepté par le propriétaire" },
    { value: "refused", text: "Refusé par le propriétaire" }
  ];
  return _.result(
    _.find(items_ac_status, { value: ac_status }),
    "text",
    "En attente"
  );
});

Vue.filter("formatOfferSurface", function(row_of, whithoutSup) {
  let txt = Vue.filter("formatSurface")(row_of.of_surface, whithoutSup);
  if (row_of.of_surface_approximate) txt += " (approximatif)";
  return txt;
});

Vue.filter("contactIcon", function(row_co) {
  let ico = "mdi-account";
  if (row_co.co_type !== "contact") ico = "mdi-account-card-details";
  else if (row_co.co_issyndic) ico = "mdi-crane";
  else if (row_co.co_isrenter) ico = "mdi-owl";
  else if (row_co.co_isowner) ico = "mdi-castle";
  else if (row_co.co_isagency) ico = "mdi-home-thermometer";
  else if (row_co.co_isagent) ico = "mdi-account-key";
  else if (row_co.co_issociety) ico = "mdi-domain";
  return ico;
});

Vue.filter("formatOfferName", function(
  row_of,
  brBeforePrice = false,
  bold = false,
  $t,
  $tc
) {
  if (!row_of) return "";
  let txt = [];

  if (row_of.bu_id && row_of.bu_id.bu_city) {
    let city = row_of.bu_id.bu_city.toUpperCase();
    if (city === "MONACO") txt.push("Monaco");
    if (city !== "MONACO") txt.push($t("translation.Cote_dazur"));
  }

  if (row_of.of_rent) txt.push($t("translation['À vendre']"));
  if (row_of.of_sell) txt.push($t("translation['À louer']"));
  //if (row_of.of_ref) txt.push(row_of.of_ref);
  /* if (row_of.bu_id && row_of.bu_id.bu_name) {
    let bu_name = row_of.bu_id.bu_name;
    if (bold) bu_name = "<b>" + bu_name + "</b>";
    txt.push(bu_name);
  } */
  if (row_of.of_type) {
    let t = _.result(
      _.find(store.state.items.items_of_type, {
        options: row_of.of_type + ""
      }),
      "text"
    );
    if (t) txt.push($t("translation." + t));
  }
  if (row_of.of_nbpieces) txt.push(Vue.filter("formatPieces")(row_of, $t, $tc));
  if (row_of.of_surface)
    txt.push(Vue.filter("formatOfferSurface")(row_of, true));
  // if (row_of.of_name_fr) txt.push(row_of.of_name_fr);
  //let ps = Vue.filter("formatOfferPrices")(row_of, " | ", brBeforePrice);
  // if (brBeforePrice) {
  //   return txt.join(" | ") + "<br>" + ps;
  // }
  //if (ps) txt.push(ps);
  // console.log("txt", txt);
  return txt.join(" | ");
});
Vue.filter("formatOfferPrices", function(
  row_of,
  sep = " / ",
  withBold = false
) {
  let txt = [],
    b1 = withBold ? "<b>" : "",
    b2 = withBold ? "</b>" : "";
  if (row_of.of_sell) {
    if (row_of.of_sell_uponrequest) txt.push("A vendre : Prix sur demande");
    else if (row_of.of_sell_sum)
      txt.push(
        "A vendre : " + b1 + Vue.filter("formatPrice")(row_of.of_sell_sum) + b2
      );
  }
  if (row_of.of_rent) {
    if (row_of.of_rent_uponrequest) txt.push("A louer : Prix sur demande");
    else {
      if (row_of.of_rent_sum)
        txt.push(
          "A louer : " +
            b1 +
            Vue.filter("formatPrice")(row_of.of_rent_sum, " €/mois") +
            b2
        );
      if (row_of.of_rent_sum_year)
        txt.push(
          "A louer : " +
            b1 +
            Vue.filter("formatPrice")(row_of.of_rent_sum_year, " €/an") +
            b2
        );
    }
  }
  return txt.join(sep);
});
Vue.filter("formatAge", function(contact) {
  if (!contact) return;
  let age = "";
  if (
    contact.patient &&
    contact.patient.pa_birthdate &&
    contact.patient.pa_birthdate !== "0000-00-00"
  ) {
    age = moment().diff(moment(contact.patient.pa_birthdate), "years") + " ans";
  }
  return age;
});
Vue.filter("formatPieces", function(row_of, $t, $tc) {
  let txt = $t("translation['Nb pièces non définis']");
  if (row_of.of_nbpieces == 1 && row_of.of_type == 1) txt = "Studio";
  else
    txt =
      row_of.of_nbpieces +
      " " +
      $tc("translation.piece", row_of.of_nbpieces * 1);
  // else if (row_of.of_nbpieces == 1) txt = "1 " + $t("translation.pièce");
  // else if (row_of.of_nbpieces)
  //   txt = row_of.of_nbpieces + " " + $t("translation.pièces");
  return txt;
});
Vue.filter("formatContactNameSimple", function(contact) {
  if (!contact || !contact.co_name) return;
  return (
    contact.co_name.toUpperCase() +
    " " +
    contact.co_firstname.charAt(0).toUpperCase() +
    contact.co_firstname.substr(1)
  );
});
Vue.filter("formatFolderName", function(row_fd) {
  if (!row_fd) return;
  let ret = "";
  if (row_fd.co_id_patient) {
    ret += Vue.filter("formatContactNameSimple")(row_fd.co_id_patient);
  }
  return ret;
});
Vue.filter("formatboolean", function(value, trueTxt, falseTxt) {
  return value ? trueTxt : falseTxt;
});
Vue.filter("formatvalue", function(value, typeToFindIn, lowercase, plurial) {
  if (!store.state[typeToFindIn]) return "";
  if (!value) return "";
  let text;
  text = _.result(
    _.find(store.state[typeToFindIn], { value: value }),
    "text",
    "erreur"
  );
  if (lowercase === "lowercase") {
    text = text.toLowerCase();
  }
  if (plurial === "plurial") {
    text = text + "s";
  }
  return text;
});
Vue.filter("formatMessageRoomType", function(value) {
  if (!value) return "";
  let text;
  let tabValue = value.split("-");
  let typeToFormat;
  for (let i = 0; i < tabValue.length; i++) {
    const val = tabValue[i];
    if (val !== store.state.user.co_type) typeToFormat = val;
  }
  text = Vue.filter("formatvalue")(
    typeToFormat,
    "items_type_contacts",
    "uppercase",
    "singular"
  );
  return text;
});
Vue.filter("formatIntervenantName", function(
  row_co,
  useBold = false,
  blackSociety = false
) {
  if (_.isPlainObject(useBold)) useBold = false;
  if (!row_co || !_.isPlainObject(row_co)) return "";
  let txt = [];
  if (row_co.co_name)
    txt.push(
      (useBold ? "<b>" : "") +
        row_co.co_name.toUpperCase() +
        (useBold ? "</b>" : "")
    );
  if (row_co.co_firstname) txt.push(row_co.co_firstname);
  row_co.completeName = txt.join(" ");

  let res = row_co.completeName;
  if (row_co.co_post) res += " - " + row_co.co_post;
  if (
    (row_co.co_issociety || row_co.co_isagency || row_co.co_issyndic) &&
    blackSociety
  ) {
    res = `<span class='span-society'>${res}</span>`;
  }
  if (row_co.co_type != "contact" && blackSociety) {
    res = `<span class='span-user'>${res}</span>`;
  }
  return res;
});
Vue.filter("formatContactName", function(
  row_co,
  useBold = false,
  blackSociety = false
) {
  if (_.isPlainObject(useBold)) useBold = false;
  if (!row_co || !_.isPlainObject(row_co)) return "";
  let txt = [];
  if (row_co.co_name)
    txt.push(
      (useBold ? "<b>" : "") +
        row_co.co_name.toUpperCase() +
        (useBold ? "</b>" : "")
    );
  if (row_co.co_firstname) txt.push(row_co.co_firstname);
  row_co.completeName = txt.join(" ");
  let res = row_co.completeName;
  if (
    (row_co.co_issociety || row_co.co_isagency || row_co.co_issyndic) &&
    blackSociety
  ) {
    res = `<span class='span-society'>${res}</span>`;
  }
  if (row_co.co_type != "contact" && blackSociety) {
    res = `<span class='span-user'>${res}</span>`;
  }
  return res;
});
Vue.filter("formatActionName", function(row_ac) {
  if (!row_ac) return "";
  let txt = [];
  if (row_ac.createdAt)
    txt.push(moment(row_ac.createdAt).format("DD/MM/YYYY [à] HH[H]mm"));
  //if (row_co) txt.push(Vue.filter("formatContactName")(row_co.data.data));
  if (row_ac.ac_comment)
    txt.push(
      row_ac.ac_comment.slice(
        0,
        row_ac.ac_comment.length > 40 ? row_ac.ac_comment.length : 40
      ) + "..."
    );
  return txt.join(" / ");
});

Vue.filter("formatEmail", function(txt) {
  return `<a href="mailto:${txt}">${txt}</a>`;
});
Vue.filter("formatEventName", function(row_ev) {
  // console.log("row_ev", row_ev);
  let txt = "";
  txt +=
    "Du " +
    moment(row_ev.ev_dtstart).format("DD/MM/YYYY [à] HH[H]mm") +
    " au " +
    moment(row_ev.ev_dtend).format("DD/MM/YYYY [à] HH[H]mm") +
    " : " +
    row_ev.ev_summary;
  return txt;
});

Vue.filter("formatTel", function(txt) {
  return `<a href="tel:${txt}">${txt}</a>`;
});
Vue.filter("formatWeb", function(txt) {
  return `<a href="${txt}" target="_blank">${txt}</a>`;
});

Vue.filter("nl2br", function(str) {
  let breakTag = "<br />";
  return (str + "").replace(
    /([^>\r\n]?)(\r\n|\n\r|\r|\n)/g,
    "$1" + breakTag + "$2"
  );
});

Vue.filter("formatContactAdress", function(row_co, sep) {
  if (!sep) sep = "<br>";
  let txt = [];
  if (row_co.co_address1) txt.push(row_co.co_address1);
  if (row_co.co_address2) txt.push(row_co.co_address2);
  if (row_co.co_address3) txt.push(row_co.co_address3);
  if (row_co.co_zip && row_co.co_city)
    txt.push(row_co.co_zip + " " + row_co.co_city);
  if (row_co.co_country) txt.push(row_co.co_country);
  return `<a href="https://www.google.fr/maps?q=${encodeURI(
    txt.join(", ")
  )}" target="_blank">${txt.join(sep)}</a>`;
});

Vue.filter("plural", function(nb, singular, plural) {
  let res = nb + "";
  if (!singular) return res;
  else if (nb > 1 && plural && plural.indexOf("%n") >= 0)
    res = plural.replace(/%n/g, nb + "");
  else if (nb > 1 && plural && plural.indexOf("%n") < 0)
    res = nb + " " + plural;
  else if (nb > 1 && !plural) res = nb + " " + singular + "s";
  else if (nb <= 1 && singular.indexOf("%n") >= 0)
    res = singular.replace(/%n/g, nb + "");
  else if (nb <= 1 && singular.indexOf("%n") < 0) res = nb + " " + singular;
  return res;
});
