var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm._m(0),
    _c(
      "section",
      { staticStyle: { cursor: "default", "user-select": "none" } },
      [
        _c(
          "div",
          {
            staticClass: "container mb-5 mt-3",
            attrs: { id: "globalContainer" }
          },
          [
            _c(
              "div",
              { staticClass: "row justify-content-center" },
              _vm._l(_vm.folders, function(folder) {
                return _c("div", { key: folder.id }, [
                  _c("div", { staticClass: "col m-2" }, [
                    _c(
                      "div",
                      {
                        staticClass: "tile",
                        style: { backgroundColor: folder.tileColor },
                        on: {
                          click: function($event) {
                            return _vm.showFolder(folder.id)
                          }
                        }
                      },
                      [
                        _c("icon", {
                          staticClass: "mb-3",
                          attrs: { name: folder.tileIcon, scale: "4" }
                        }),
                        _c(
                          "h4",
                          {
                            staticStyle: {
                              "text-align": "center",
                              padding: "0 10px"
                            }
                          },
                          [_vm._v(" " + _vm._s(folder.name) + " ")]
                        )
                      ],
                      1
                    )
                  ])
                ])
              }),
              0
            )
          ]
        ),
        _c(
          "div",
          {
            staticClass: "container",
            staticStyle: { display: "none" },
            attrs: { id: "folderContainer" }
          },
          [
            _c("div", { staticClass: "row" }, [
              _c(
                "div",
                {
                  staticClass: "col supportBox",
                  style: { backgroundColor: _vm.currentFolder.tileColor }
                },
                [
                  _c(
                    "div",
                    { staticClass: "row align-items-center mt-3 mb-4 ml-2" },
                    [
                      _c("icon", {
                        staticClass: "m-2",
                        attrs: { name: "arrow-left", scale: "2" },
                        on: {
                          click: function($event) {
                            return _vm.hideFolder()
                          }
                        }
                      }),
                      _c("icon", {
                        staticClass: "m-2",
                        attrs: { name: _vm.currentFolder.tileIcon, scale: "2" }
                      }),
                      _c("h2", { staticClass: "m-2" }, [
                        _vm._v(_vm._s(_vm.currentFolder.name))
                      ])
                    ],
                    1
                  ),
                  _vm._l(_vm.currentFolder.articles, function(article) {
                    return _c("div", { key: article.id }, [
                      _c(
                        "div",
                        {
                          staticClass: "row questionBox",
                          attrs: { id: article.id },
                          on: {
                            click: function($event) {
                              return _vm.showAnswer(article.id)
                            }
                          }
                        },
                        [
                          _c("div", { staticClass: "col-10" }, [
                            _vm._v(_vm._s(article.title))
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "col-2",
                              staticStyle: {
                                display: "flex",
                                flex: "1",
                                "justify-content": "flex-end",
                                "align-items": "center"
                              },
                              attrs: { id: "arrow" }
                            },
                            [
                              _c("icon", {
                                attrs: { name: "angle-down", scale: "1.4" }
                              })
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "answerBox",
                              staticStyle: { display: "none" }
                            },
                            [
                              _vm._v(
                                " " + _vm._s(article.description_text) + " "
                              )
                            ]
                          )
                        ]
                      )
                    ])
                  })
                ],
                2
              )
            ])
          ]
        ),
        _vm._m(1),
        _vm._m(2)
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "section",
      { staticClass: "bg-gray", attrs: { id: "part_presentation" } },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-md" }, [
              _c("h3", [_vm._v("SUPPORT")]),
              _c("h1", [_vm._v("LES QUESTIONS FRÉQUENTES")])
            ])
          ])
        ])
      ]
    )
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container mb-5" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col", staticStyle: { "text-align": "center" } },
          [
            _c("h3", { staticClass: "mb-2" }, [_vm._v("Documentation")]),
            _c("div", { staticClass: "row justify-content-center" }, [
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary m-1",
                    attrs: {
                      target: "_blank",
                      href: "/files/Modedemploi_version_web.pdf"
                    }
                  },
                  [_vm._v("DOCUMENTATION INSTALLATION ")]
                )
              ]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary m-1",
                    attrs: {
                      target: "_blank",
                      href: "/files/Emotivi_Aide_Utilisateur.pdf?v=1"
                    }
                  },
                  [_vm._v("DOCUMENTATION UTILISATEUR ")]
                )
              ]),
              _c("div", [
                _c(
                  "a",
                  {
                    staticClass: "btn btn-primary m-1",
                    attrs: {
                      target: "_blank",
                      href: "/files/Modedemploi_routeur_4G.pdf"
                    }
                  },
                  [_vm._v("DOCUMENTATION ROUTEUR 4G ")]
                )
              ])
            ])
          ]
        )
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "container mb-5" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col", staticStyle: { "text-align": "center" } },
          [
            _c("h3", { staticClass: "mb-2" }, [
              _vm._v("Vous avez une autre question ?")
            ]),
            _c(
              "button",
              {
                staticClass: "btn btn-xl btn-primary",
                attrs: { type: "button", onclick: "FreshworksWidget('open')" }
              },
              [_vm._v(" Contactez-nous ")]
            )
          ]
        )
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }