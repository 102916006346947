<template>
  <div
    class="modal show"
    :style="'display:block;background-color:#3333337a;'"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      style="max-width: 75%"
      class="modal-dialog modal-dialog-scrollable"
      role="document"
    >
      <div class="modal-content">
        <div class="modal-header d-flex flex-row align-items-center">
          <div>
            <h1>Modification identifiants</h1>
          </div>
        </div>
        <div class="modal-body">
          <section class="bg-gray-light">
            <div class="container p-0 pt-3">
              <div class="row mb-5">
                <div
                  class="col-xl-10 col-sm-10 offset-sm-1"
                  style="background-color: #6aceb1"
                >
                  <div
                    class="row justify-content-center align-items-center mt-3 ml-1 mr-1 mb-3"
                  >
                    <div
                      class="col-xl-1 col-lg-1 col-md-1 col-sm-2 text-center"
                    >
                      <icon name="pen" scale="2.5" fill="white"></icon>
                    </div>
                    <div
                      class="col-xl-11 col-lg-11 col-md-11 col-sm-10 text-center"
                    >
                      <h3 class="m-0" style="color: white">
                        Identifiants de votre kit Emotivi
                        <span class="text-info">{{ webappLogin }}</span>
                      </h3>
                    </div>
                  </div>
                  <div
                    class="row justify-content-center align-items-center mt-3 ml-1 mr-1 mb-3"
                  >
                    <div class="col-12">
                      <div
                        v-if="dialogErrTxt && dialogErr"
                        class="alert alert-danger"
                      >
                        {{ dialogErrTxt }}
                      </div>
                      <div v-if="dialogOkTxt" class="alert alert-success">
                        {{ dialogOkTxt }}
                      </div>
                      <div class="d-flex flex-column">
                        <h5>Webapp</h5>

                        <div class="row alert alert-info mt-2">
                          <div class="col-4">
                            <m-form-text
                              label="Identifiant"
                              placeholder="Identifiant"
                              label-position="top"
                              v-model="row_en.en_webapp_login"
                              name="identifiant"
                              autocomplete
                            ></m-form-text>
                          </div>
                          <div class="col-4">
                            <m-form-text
                              label="Mot de passe"
                              type="password"
                              placeholder="Mot de passe"
                              label-position="top"
                              v-model="row_en.en_webapp_password"
                              name="Mot de passe"
                              autocomplete
                            ></m-form-text>
                          </div>
                          <div class="col-4">
                            <m-form-text
                              label="Répétez le mot de passe"
                              type="password"
                              placeholder="Mot de passe"
                              label-position="top"
                              v-model="newmp"
                              name="Mot de passe"
                              autocomplete
                            ></m-form-text>
                          </div>
                        </div>
                        <hr />
                      </div>
                      <div class="d-flex flex-column">
                        <h5>Configuration</h5>

                        <div class="alert alert-info mt-2">
                          <m-form-text
                            label="Code Administrateur"
                            placeholder="Code Admin"
                            label-position="top"
                            v-model="row_en.en_admincode"
                            name="code_admin"
                            autocomplete
                          ></m-form-text>
                        </div>
                        <hr />
                      </div>
                      <div class="d-flex flex-column">
                        <h5>
                          Confirmer votre mot de passe avant d'enregistrer
                        </h5>

                        <div class="alert alert-info mt-2">
                          <m-form-text
                            label="Mot de passe actuel"
                            type="password"
                            placeholder="Mot de passe"
                            label-position="top"
                            v-model="accountpswd"
                            name="Mot de passe"
                            autocomplete
                          ></m-form-text>
                        </div>
                        <hr />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div class="modal-footer d-flex flex-row justify-content-between">
          <div></div>
          <div>
            <button type="button" class="btn btn-secondary" @click="close">
              FERMER
            </button>
            <button type="button" class="btn btn-primary ml-2" @click="save()">
              ENREGISTRER
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "editidentifiants",
  props: {
    row_en: {
      default: function () {
        return {};
      },
      type: Object,
    },
  },
  computed: {
    webappLogin() {
      return this.row_en.en_webapp_login;
    },
  },
  watch: {},
  data() {
    return {
      dialogErrTxt: "",
      dialogOkTxt: "",
      newmp: "",
      dialogErr: false,
      accountpswd: "",
    };
  },
  async mounted() {},
  methods: {
    close() {
      this.$emit("closeEditIdentifiants");
    },
    async save() {
      let err = [];
      if (
        this.newmp &&
        this.row_en.bo_id.bo_hardwareinfo.en_webapp_password != this.newmp
      ) {
        err.push("Les mots de passe ne correspondent pas");
        this.dialogErrTxt = "Les mots de passe ne correspondent pas";
      }
      if (err.length) {
        this.dialogErr = true;
      } else {
        let response = await this.$axios.put(
          this.$config.server_url + "/web/2.0/enduser/" + this.row_en.en_id,
          {
            admincode: this.row_en.en_admincode,
            webapp_login: this.row_en.en_webapp_login,
            webapp_password: this.row_en.en_webapp_password,
            co_password: this.accountpswd,
          }
        );
        if (response.data.data == "error") {
          this.dialogOkTxt = "";
          this.dialogErrTxt =
            "Une erreur est survenue, les identifiants n'ont pas pu être sauvegardées.";
          this.dialogErr = true;

          return;
        } else if (response.data.data == "wrong_password") {
          this.dialogOkTxt = "";
          this.dialogErrTxt = "Le mot de passe actuel renseigné est incorrect.";
          this.dialogErr = true;
          return;
        }
        this.dialogOkTxt = "Identifiants sauvegardés avec succès.";
        this.dialogErrTxt = "";
        this.$emit("closeEditIdentifiants");
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
h5 {
  color: #91295a;
  margin-bottom: 1rem;
  font-weight: bold;
}
</style>
  