<template>
  <div>
    <section class="bg-gray" id="part_presentation">
      <div class="container">
        <div class="row">
          <div class="col-md">
            <h3>SUPPORT</h3>
            <h1>LES QUESTIONS FRÉQUENTES</h1>
          </div>
        </div>
      </div>
    </section>
    <section style="cursor: default; user-select: none">
      <div class="container mb-5 mt-3" id="globalContainer">
        <div class="row justify-content-center">
          <div v-for="folder in folders" :key="folder.id">
            <div class="col m-2">
              <div
                class="tile"
                :style="{ backgroundColor: folder.tileColor }"
                @click="showFolder(folder.id)"
              >
                <icon :name="folder.tileIcon" scale="4" class="mb-3"></icon>
                <h4 style="text-align: center; padding: 0 10px">
                  {{ folder.name }}
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container" id="folderContainer" style="display: none">
        <div class="row">
          <div
            class="col supportBox"
            :style="{ backgroundColor: currentFolder.tileColor }"
          >
            <div class="row align-items-center mt-3 mb-4 ml-2">
              <icon
                name="arrow-left"
                scale="2"
                class="m-2"
                @click="hideFolder()"
              ></icon>
              <icon :name="currentFolder.tileIcon" scale="2" class="m-2"></icon>
              <h2 class="m-2">{{ currentFolder.name }}</h2>
            </div>
            <div v-for="article in currentFolder.articles" :key="article.id">
              <div
                class="row questionBox"
                @click="showAnswer(article.id)"
                :id="article.id"
              >
                <div class="col-10">{{ article.title }}</div>
                <div
                  class="col-2"
                  style="
                    display: flex;
                    flex: 1;
                    justify-content: flex-end;
                    align-items: center;
                  "
                  id="arrow"
                >
                  <icon name="angle-down" scale="1.4"></icon>
                </div>
                <div class="answerBox" style="display: none">
                  {{ article.description_text }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container mb-5">
        <div class="row">
          <div class="col" style="text-align: center">
            <h3 class="mb-2">Documentation</h3>
            <div class="row justify-content-center">
              <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="/files/Modedemploi_version_web.pdf"
                  >DOCUMENTATION INSTALLATION
                </a>
              </div>
              <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="/files/Emotivi_Aide_Utilisateur.pdf?v=1"
                  >DOCUMENTATION UTILISATEUR
                </a>
              </div>
              <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="/files/Modedemploi_routeur_4G.pdf"
                  >DOCUMENTATION ROUTEUR 4G
                </a>
              </div>
              <!-- <div>
                <a
                  class="btn btn-primary m-1"
                  target="_blank"
                  href="https://www.youtube.com/watch?v=I3KLLo-sQzo"
                  >Comment configurer votre box ?
                </a>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="container mb-5">
        <div class="row">
          <div class="col" style="text-align: center">
            <h3 class="mb-2">Vous avez une autre question ?</h3>
            <button
              type="button"
              class="btn btn-xl btn-primary"
              onclick="FreshworksWidget('open')"
            >
              Contactez-nous
            </button>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  name: "support",
  components: {},
  data() {
    return {
      currentFolder: 0,
      folders: []
    };
  },
  async mounted() {
    let data = await this.$axios.get(
      this.$config.server_url + "/web/2.0/support" // Temporaire, juste pour récup la faq de freshdesk
    );
    this.folders = data.data;
    // console.log(this.folders);
  },
  methods: {
    setCurrentFolder(id) {
      this.folders.forEach(folder => {
        if (folder.id == id) {
          this.currentFolder = folder;
        }
      });
    },
    showFolder(id) {
      this.setCurrentFolder(id);
      this.$$("#globalContainer").fadeOut(() => {
        this.$$("#globalContainer").css("display", "none");
        this.$$("#folderContainer").fadeIn();
      });
    },
    hideFolder() {
      this.$$("#folderContainer").fadeOut(() => {
        this.$$("#folderContainer").css("display", "none");
        this.$$("#globalContainer").fadeIn();
      });
    },
    showAnswer(id) {
      let child = this.$$("#" + id).children(".answerBox");
      let arrow = this.$$("#" + id)
        .children("#arrow")
        .children();
      if (this.$$(child)[0].style.display == "none") {
        this.$$(child).slideDown();
        this.$$(arrow)[0].classList.add("reverseArrow");
      } else {
        this.$$(child).slideUp();
        this.$$(arrow)[0].classList.remove("reverseArrow");
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.tile {
  height: 220px;
  width: 220px;
  margin: auto;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  cursor: pointer;
  padding: 0 20px;
}
.supportBox {
  margin: 0;
  color: white;
}
.questionBox {
  height: auto;
  display: flex;
  flex-direction: row;
  font-size: 20px;
  align-items: center;
  padding: 15px 30px;
  cursor: pointer;
}
.answerBox {
  margin: 10px 0 0 20px;
  font-size: 15px;
  padding: 0 20px;
  display: flex;
}
.reverseArrow {
  transform: rotate(180deg);
}
// @media (min-width: 768px) {
//   .supportBox {
//     margin: 10%;
//   }
// }
</style>
