var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex" },
    [
      _c("m-form-date", {
        staticClass: "flex-fill",
        attrs: {
          label: _vm.label,
          name: _vm.nameDate,
          formatInput: "DD/MM/YYYY",
          disabled: _vm.disabled
        },
        model: {
          value: _vm.date,
          callback: function($$v) {
            _vm.date = $$v
          },
          expression: "date"
        }
      }),
      _c("m-form-hour", {
        staticClass: "flex-fill",
        attrs: { name: _vm.nameHour, disabled: _vm.disabled },
        model: {
          value: _vm.time,
          callback: function($$v) {
            _vm.time = $$v
          },
          expression: "time"
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }