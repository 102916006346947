export default {
  en: {
    currency: {
      style: "currency",
      currency: "USD"
    }
  },
  "ja-JP": {
    currency: {
      style: "currency",
      currency: "JPY",
      currencyDisplay: "symbol"
    }
  },
  fr: {
    currency: {
      style: "currency",
      currency: "EUR"
    }
  }
};
