var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "modal show",
      style: "display:block;background-color:#3333337a;",
      attrs: { tabindex: "-1", role: "dialog", "aria-hidden": "true" }
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-scrollable",
          staticStyle: { "max-width": "75%" },
          attrs: { role: "document" }
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _c("div", { staticClass: "modal-body" }, [
              _c("section", { staticClass: "bg-gray-light" }, [
                _c("div", { staticClass: "container p-0 pt-3" }, [
                  _c("div", { staticClass: "row mb-5" }, [
                    _c(
                      "div",
                      {
                        staticClass: "col-xl-10 col-sm-10 offset-sm-1",
                        staticStyle: { "background-color": "#6aceb1" }
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "row justify-content-center align-items-center mt-3 ml-1 mr-1 mb-3"
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-xl-1 col-lg-1 col-md-1 col-sm-2 text-center"
                              },
                              [
                                _c("icon", {
                                  attrs: {
                                    name: "pen",
                                    scale: "2.5",
                                    fill: "white"
                                  }
                                })
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "col-xl-11 col-lg-11 col-md-11 col-sm-10 text-center"
                              },
                              [
                                _c(
                                  "h3",
                                  {
                                    staticClass: "m-0",
                                    staticStyle: { color: "white" }
                                  },
                                  [
                                    _vm._v(
                                      " Les options de votre kit Emotivi "
                                    ),
                                    _c("span", { staticClass: "text-info" }, [
                                      _vm._v(_vm._s(_vm.webappLogin))
                                    ])
                                  ]
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "row justify-content-center align-items-center mt-3 ml-1 mr-1 mb-3"
                          },
                          [
                            _c("div", { staticClass: "col-12" }, [
                              _vm.dialogErrTxt && !_vm.dialogErr
                                ? _c(
                                    "div",
                                    { staticClass: "alert alert-danger" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.dialogErrTxt) + " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm.dialogOkTxt
                                ? _c(
                                    "div",
                                    { staticClass: "alert alert-success" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(_vm.dialogOkTxt) + " "
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _c("h5", [_vm._v("Décrochage automatique")]),
                                _c(
                                  "div",
                                  { staticClass: "alert alert-info mt-2" },
                                  [
                                    _c("m-form-checkbox", {
                                      staticClass: "mr-3",
                                      attrs: {
                                        label:
                                          " Décrochage automatique activé pour les membres de la\n                            famille",
                                        name: _vm.$Utils.randomstring(
                                          "hangupOther"
                                        ),
                                        setstyle:
                                          "white-space: normal !important"
                                      },
                                      model: {
                                        value: _vm.hangupOther,
                                        callback: function($$v) {
                                          _vm.hangupOther = $$v
                                        },
                                        expression: "hangupOther"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c("hr")
                              ]),
                              _c("div", { staticClass: "d-flex flex-column" }, [
                                _vm.isBoxCompatible(
                                  "options_bistri_display_preview"
                                )
                                  ? _c(
                                      "div",
                                      [
                                        _c("hr"),
                                        _c("h5", [
                                          _vm._v("Options durant l'appel vidéo")
                                        ]),
                                        _c("m-form-checkbox", {
                                          staticClass: "mr-3",
                                          attrs: {
                                            label:
                                              "Cliquez ici pour activer l'aperçu de la caméra sur le téléviseur de votre ou parent lors d'un appel vidéo",
                                            name: _vm.$Utils.randomstring(
                                              "options_bistri_display_preview"
                                            ),
                                            setstyle:
                                              "white-space: normal !important"
                                          },
                                          model: {
                                            value: _vm.screenDisplayPreview,
                                            callback: function($$v) {
                                              _vm.screenDisplayPreview = $$v
                                            },
                                            expression: "screenDisplayPreview"
                                          }
                                        }),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value: _vm.screenDisplayPreview,
                                                expression:
                                                  "screenDisplayPreview"
                                              }
                                            ],
                                            staticClass: "alert alert-info"
                                          },
                                          [
                                            _c(
                                              "label",
                                              {
                                                staticClass: "mb-2",
                                                attrs: { for: "screenPreview" }
                                              },
                                              [
                                                _vm._v(
                                                  "Sélectionner l'emplacement de l'aperçu :"
                                                )
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              {
                                                staticClass: "screenPreview",
                                                attrs: { id: "screenpreview" }
                                              },
                                              [
                                                _c("icon", {
                                                  staticClass:
                                                    "screenPreviewTv",
                                                  attrs: {
                                                    width: "100%",
                                                    height: "100%",
                                                    name: "tv",
                                                    fill: "lightgray"
                                                  }
                                                }),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "screenPreviewChild mt-3"
                                                  },
                                                  [
                                                    _c("m-form-checkbox", {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        name: _vm.$Utils.randomstring(
                                                          "topleft"
                                                        )
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.screenPreviewTopLeft,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.screenPreviewTopLeft = $$v
                                                        },
                                                        expression:
                                                          "screenPreviewTopLeft"
                                                      }
                                                    }),
                                                    _c("m-form-checkbox", {
                                                      staticClass: "ml-3",
                                                      attrs: {
                                                        name: _vm.$Utils.randomstring(
                                                          "topright"
                                                        )
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.screenPreviewTopRight,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.screenPreviewTopRight = $$v
                                                        },
                                                        expression:
                                                          "screenPreviewTopRight"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "screenPreviewChild"
                                                  },
                                                  [
                                                    _c("m-form-checkbox", {
                                                      staticClass: "mr-3",
                                                      attrs: {
                                                        name: _vm.$Utils.randomstring(
                                                          "bottomleft"
                                                        )
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.screenPreviewBottomLeft,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.screenPreviewBottomLeft = $$v
                                                        },
                                                        expression:
                                                          "screenPreviewBottomLeft"
                                                      }
                                                    }),
                                                    _c("m-form-checkbox", {
                                                      staticClass: "ml-3",
                                                      attrs: {
                                                        name: _vm.$Utils.randomstring(
                                                          "bottomright"
                                                        )
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.screenPreviewBottomRight,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.screenPreviewBottomRight = $$v
                                                        },
                                                        expression:
                                                          "screenPreviewBottomRight"
                                                      }
                                                    })
                                                  ],
                                                  1
                                                )
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "mt-2" },
                                              [
                                                _c("m-form-select", {
                                                  attrs: {
                                                    label: "Taille de l'aperçu",
                                                    "label-position": "top",
                                                    name: _vm.$Utils.randomstring(
                                                      "screenPreviewSize"
                                                    ),
                                                    items:
                                                      _vm.screenPreviewSizes
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.screenPreviewSize,
                                                    callback: function($$v) {
                                                      _vm.screenPreviewSize = $$v
                                                    },
                                                    expression:
                                                      "screenPreviewSize"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _c(
                                  "div",
                                  { staticClass: "d-flex flex-column" },
                                  [
                                    _c("h5", [
                                      _vm._v("Gestion des notifications")
                                    ]),
                                    _c(
                                      "div",
                                      { staticClass: "alert alert-info mt-2" },
                                      [
                                        _c(
                                          "p",
                                          { staticClass: "section-subtitle" },
                                          [_vm._v("Pour les messages")]
                                        ),
                                        _c("m-form-checkbox", {
                                          staticClass: "mr-3",
                                          attrs: {
                                            label:
                                              "Désactiver la notification lumineuse",
                                            name: _vm.$Utils.randomstring(
                                              "ledsDisableDuration"
                                            ),
                                            setstyle:
                                              "white-space: normal !important"
                                          },
                                          model: {
                                            value: _vm.ledsDisableDuration,
                                            callback: function($$v) {
                                              _vm.ledsDisableDuration = $$v
                                            },
                                            expression: "ledsDisableDuration"
                                          }
                                        }),
                                        _c("m-form-hour", {
                                          staticClass: "mx-1",
                                          staticStyle: {
                                            "overflow-wrap": "normal"
                                          },
                                          attrs: {
                                            label:
                                              "Durée maximale de la notification lumineuse (heures:minutes)",
                                            name: _vm.$Utils.randomstring(
                                              "ledsDuration"
                                            )
                                          },
                                          model: {
                                            value: _vm.ledsDurationFormatted,
                                            callback: function($$v) {
                                              _vm.ledsDurationFormatted = $$v
                                            },
                                            expression: "ledsDurationFormatted"
                                          }
                                        })
                                      ],
                                      1
                                    ),
                                    _vm.isBoxCompatible(
                                      "options_leds_disabled"
                                    ) &&
                                    _vm.isBoxCompatible(
                                      "options_value_ringing_number"
                                    )
                                      ? _c(
                                          "div",
                                          {
                                            staticClass: "alert alert-info mt-2"
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "section-subtitle"
                                              },
                                              [_vm._v("Pour les appels")]
                                            ),
                                            _c("m-form-checkbox", {
                                              staticClass: "mr-3",
                                              attrs: {
                                                label:
                                                  "Désactiver la notification lumineuse",
                                                name: _vm.$Utils.randomstring(
                                                  "ledsDisabled"
                                                ),
                                                setstyle:
                                                  "white-space: normal !important"
                                              },
                                              model: {
                                                value: _vm.ledsDisabled,
                                                callback: function($$v) {
                                                  _vm.ledsDisabled = $$v
                                                },
                                                expression: "ledsDisabled"
                                              }
                                            }),
                                            _c("m-form-checkbox", {
                                              staticClass: "mr-3",
                                              attrs: {
                                                label: "Désactiver la sonnerie",
                                                name: _vm.$Utils.randomstring(
                                                  "ringingDisable"
                                                ),
                                                setstyle:
                                                  "white-space: normal !important"
                                              },
                                              model: {
                                                value: _vm.ringingDisable,
                                                callback: function($$v) {
                                                  _vm.ringingDisable = $$v
                                                },
                                                expression: "ringingDisable"
                                              }
                                            }),
                                            _c("m-form-text", {
                                              staticClass: "mr-3",
                                              attrs: {
                                                label: "Nombre de sonnerie",
                                                type: "number",
                                                name: _vm.$Utils.randomstring(
                                                  "ringingNumber"
                                                )
                                              },
                                              model: {
                                                value: _vm.ringingNumber,
                                                callback: function($$v) {
                                                  _vm.ringingNumber = $$v
                                                },
                                                expression: "ringingNumber"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e()
                                  ]
                                ),
                                _vm.isBoxCompatible(
                                  "options_value_button_functionality"
                                ) && this.enableButtonFunctionality
                                  ? _c(
                                      "div",
                                      { staticClass: "d-flex flex-column" },
                                      [
                                        _c("h5", [_vm._v("Gestion du bouton")]),
                                        _c("p", [
                                          _vm._v(
                                            " Lors d'un clic simple sur le bouton, vous pouvez choisir ici l'action qui sera réalisée. "
                                          )
                                        ]),
                                        _c("div", { staticClass: "col-12" }, [
                                          _vm.dialogErr
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "alert alert-danger"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(_vm.dialogErrTxt) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "alert alert-info mt-2"
                                          },
                                          [
                                            _c(
                                              "p",
                                              {
                                                staticClass: "section-subtitle"
                                              },
                                              [
                                                _vm._v(
                                                  " Comportement par défaut "
                                                )
                                              ]
                                            ),
                                            _c("m-form-combobox", {
                                              staticClass:
                                                "flex-grow-1 basis-50",
                                              attrs: {
                                                label:
                                                  "Comportement par défaut",
                                                "label-position": "top",
                                                name: _vm.$Utils.randomstring(
                                                  "bf_id"
                                                ),
                                                autoSelectOnFocus: true,
                                                "store-url":
                                                  _vm.$config.server_url +
                                                  "/web/2.0/buttonFunctionalities/" +
                                                  this.row_en.en_id,
                                                "store-params": {
                                                  bf_id: _vm.row_bf.bf_id
                                                },
                                                "item-value": "bf_name",
                                                "item-text": "bf_name"
                                              },
                                              model: {
                                                value: _vm.row_bf,
                                                callback: function($$v) {
                                                  _vm.row_bf = $$v
                                                },
                                                expression: "row_bf"
                                              }
                                            }),
                                            _vm.row_bf.pc_id != "none"
                                              ? _c(
                                                  "div",
                                                  { staticClass: "col-12" },
                                                  [
                                                    _c(
                                                      "label",
                                                      { attrs: { for: "" } },
                                                      [
                                                        _vm._v(
                                                          "Message qui sera envoyé:"
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "alert alert-success col-12"
                                                      },
                                                      [
                                                        _c(
                                                          "span",
                                                          {
                                                            staticStyle: {
                                                              "word-break":
                                                                "break-word"
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                _vm.decryptMessage(
                                                                  _vm.row_bf
                                                                    .bf_message
                                                                )
                                                              )
                                                            )
                                                          ]
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.row_bf.pc_id != "none"
                                              ? _c("m-form-text", {
                                                  attrs: {
                                                    label: _vm.label,
                                                    type: "text",
                                                    placeholder:
                                                      _vm.placeholder,
                                                    name: _vm.$Utils.randomstring(
                                                      "communication"
                                                    )
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.row_bfd.communication,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        _vm.row_bfd,
                                                        "communication",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "row_bfd.communication"
                                                  }
                                                })
                                              : _vm._e()
                                          ],
                                          1
                                        )
                                      ]
                                    )
                                  : _vm._e()
                              ])
                            ])
                          ]
                        )
                      ]
                    )
                  ])
                ])
              ])
            ]),
            _c(
              "div",
              {
                staticClass:
                  "modal-footer d-flex flex-row justify-content-between"
              },
              [
                _c("div"),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-secondary",
                      attrs: { type: "button" },
                      on: { click: _vm.close }
                    },
                    [_vm._v(" FERMER ")]
                  ),
                  _c(
                    "button",
                    {
                      staticClass: "btn btn-primary ml-2",
                      attrs: { type: "button" },
                      on: {
                        click: function($event) {
                          return _vm.save()
                        }
                      }
                    },
                    [_vm._v(" ENREGISTRER ")]
                  )
                ])
              ]
            )
          ])
        ]
      )
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "modal-header d-flex flex-row align-items-center" },
      [_c("div", [_c("h1", [_vm._v("Les options")])])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }