var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", [
    _c(
      "div",
      {
        staticClass: "container pb-5 containercolored",
        staticStyle: { "background-color": "rgb(24, 155, 176)" }
      },
      [
        _c("div", { staticClass: "row mb-5" }, [
          _c("div", { staticClass: "col" }, [
            _c("div", { staticClass: "row align-items-end" }, [
              _vm._m(0),
              _c(
                "div",
                {
                  staticClass: "col-4 col-sm-4 col-lg-2 text-center mb-3 mt-2"
                },
                [
                  _c(
                    "h4",
                    {
                      staticClass: "part-subtitle-private",
                      on: {
                        click: function($event) {
                          return _vm.$router.push("/private/account")
                        }
                      }
                    },
                    [_vm._v(" MON"), _c("br"), _vm._v("COMPTE ")]
                  )
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "col-4 col-sm-4 col-lg-2 text-center mb-3",
                  on: {
                    click: function($event) {
                      return _vm.$router.push("/private/payments")
                    }
                  }
                },
                [_vm._m(1)]
              )
            ]),
            _c(
              "div",
              {
                staticClass: "d-flex pt-4 flex-wrap col",
                staticStyle: { top: "0", right: "0" },
                attrs: { id: "documentation" }
              },
              [
                _vm._m(2),
                _vm._m(3),
                _vm._m(4),
                _vm.depositNotPaid
                  ? _c("div", { staticClass: "m-1" }, [
                      _c(
                        "button",
                        {
                          staticClass: "btn btn-danger ml-4 uppercase",
                          attrs: { type: "button" },
                          on: {
                            click: function($event) {
                              return _vm.payDeposit()
                            }
                          }
                        },
                        [_vm._v(" VOUS N'AVEZ PAS ENCORE PAYÉ LA CAUTION ")]
                      )
                    ])
                  : _vm._e(),
                _vm.displayBoxOptions
                  ? _c("box-options", {
                      staticClass: "m-auto w-100",
                      attrs: { row_en: _vm.selectedEnduser },
                      on: {
                        closeBoxOptions: function($event) {
                          return _vm.displayOptions()
                        }
                      }
                    })
                  : _vm._e(),
                _vm.displayEditIdentifiants
                  ? _c("edit-identifiants", {
                      staticClass: "m-auto w-100",
                      attrs: { row_en: _vm.selectedEnduser },
                      on: {
                        closeEditIdentifiants: function($event) {
                          return _vm.displayEditId()
                        }
                      }
                    })
                  : _vm._e()
              ],
              1
            )
          ])
        ]),
        _c("div", { staticStyle: { display: "block" } }, [
          _c("div", { staticClass: "row", staticStyle: { color: "white" } }, [
            _c("div", { staticClass: "col" }, [
              _c(
                "h3",
                [
                  _c("icon", {
                    attrs: { name: "arrow-right", scale: "2", fill: "white" }
                  }),
                  _vm._v(" Mes boxes ")
                ],
                1
              ),
              _c("table", { staticClass: "table table-sm" }, [
                _vm._m(5),
                _c(
                  "tbody",
                  _vm._l(_vm.getEndusersWithBoxes, function(row_en, indexEn) {
                    return _c("tr", { key: indexEn }, [
                      _c("div", { staticClass: "row ml-2" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6 p-0"
                          },
                          [
                            row_en.en_state_subscription === "SUSPENDED"
                              ? _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .$moment(row_en.createdAt)
                                          .format("DD/MM/YYYY [à] HH:mm")
                                      ) +
                                      " "
                                  ),
                                  _c("br"),
                                  _c(
                                    "span",
                                    {
                                      staticStyle: {
                                        color: "red",
                                        "font-size": "smaller",
                                        "font-weight": "bold"
                                      }
                                    },
                                    [_vm._v("Abonnement suspendu")]
                                  )
                                ])
                              : _c("td", [
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        _vm
                                          .$moment(row_en.createdAt)
                                          .format("DD/MM/YYYY [à] HH:mm")
                                      ) +
                                      " "
                                  )
                                ])
                          ]
                        ),
                        _vm._m(6, true),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col col-lg-3 col-md-4 col-sm-4 col-xs-4 col-12"
                          },
                          [
                            _c(
                              "td",
                              {},
                              [
                                _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      "user-select": "none"
                                    },
                                    on: {
                                      click: function($event) {
                                        return _vm.showWebappLoginDiv(indexEn)
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(" Vos identifiants "),
                                    _c("icon", {
                                      attrs: {
                                        id: "angleIcon" + indexEn,
                                        name: "angle-down",
                                        scale: "1.2",
                                        fill: "white"
                                      }
                                    })
                                  ],
                                  1
                                ),
                                _c(
                                  "transition",
                                  { attrs: { name: "webapplogindiv" } },
                                  [
                                    _vm.showWebappLogin[indexEn]
                                      ? _c("div", [
                                          _c("i", [
                                            _c("b", [_vm._v("Identifiant :")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(row_en.en_webapp_login) +
                                                " "
                                            ),
                                            _c("br"),
                                            _c("b", [_vm._v("Mot de passe :")]),
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  row_en.en_webapp_password
                                                )
                                            )
                                          ]),
                                          row_en.bo_id
                                            ? _c(
                                                "button",
                                                {
                                                  staticClass:
                                                    "btn btn-primary btn-sm mr-3",
                                                  attrs: { type: "button" },
                                                  on: {
                                                    click: function($event) {
                                                      return _vm.launchWebapp(
                                                        row_en
                                                      )
                                                    }
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    " SE CONNECTER (sur la webapp) "
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ])
                                      : _vm._e()
                                  ]
                                )
                              ],
                              1
                            )
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-2 col-md-6 col-sm-4 col-xs-4 col-12 p-0"
                          },
                          [
                            _c("td", {}, [
                              row_en.bo_id
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-primary btn-sm mr-3",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.configureBox(row_en)
                                        }
                                      }
                                    },
                                    [_vm._v(" CONFIGURER LE KIT ")]
                                  )
                                : _vm._e(),
                              row_en.bo_id
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-primary btn-sm mr-3 mt-2",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.displayEditId(row_en)
                                        }
                                      }
                                    },
                                    [_vm._v(" MODIFIER LES IDENTIFIANTS ")]
                                  )
                                : _vm._e()
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-2 col-md-6 col-sm-4 col-xs-4 col-12 p-0"
                          },
                          [
                            _c("td", { staticClass: "text-center" }, [
                              row_en.bo_id
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-primary btn-sm mr-3",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.redemBox(row_en)
                                        }
                                      }
                                    },
                                    [_vm._v(" REDEMARRER LE KIT ")]
                                  )
                                : _vm._e()
                            ])
                          ]
                        ),
                        _c(
                          "div",
                          {
                            staticClass:
                              "col-lg-2 col-md-6 col-sm-4 col-xs-4 col-12 p-0"
                          },
                          [
                            _c("td", { staticClass: "text-center" }, [
                              row_en.bo_id &&
                              _vm.isBoxCompatible(
                                row_en,
                                "options_value_leds_duration"
                              )
                                ? _c(
                                    "button",
                                    {
                                      staticClass:
                                        "btn btn-primary btn-sm mr-3",
                                      attrs: { type: "button" },
                                      on: {
                                        click: function($event) {
                                          return _vm.displayOptions(row_en)
                                        }
                                      }
                                    },
                                    [_vm._v(" LES OPTIONS ")]
                                  )
                                : _vm._e()
                            ])
                          ]
                        )
                      ])
                    ])
                  }),
                  0
                )
              ])
            ])
          ])
        ])
      ]
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "col-md-12 col-lg-5 pt-4" }, [
      _c("div", { staticClass: "mb-3" }, [
        _c("img", {
          staticStyle: { width: "70px" },
          attrs: { src: "/images/ico-interface.png", alt: "" }
        })
      ]),
      _c("div", [_c("h2", [_vm._v("MES KITS"), _c("br"), _vm._v("EMOTIVI")])])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("h4", { staticClass: "part-subtitle-private" }, [
      _vm._v("MES"),
      _c("br"),
      _vm._v("PAIEMENTS")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticClass: "btn btn-primary m-1",
          attrs: {
            target: "_blank",
            href: "/files/Modedemploi_version_web.pdf"
          }
        },
        [_vm._v("DOCUMENTATION INSTALLATION ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticClass: "btn btn-primary m-1",
          attrs: {
            target: "_blank",
            href: "/files/Emotivi_Aide_Utilisateur.pdf"
          }
        },
        [_vm._v("DOCUMENTATION UTILISATEUR ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c(
        "a",
        {
          staticClass: "btn btn-primary m-1",
          attrs: { target: "_blank", href: "/files/Modedemploi_routeur_4G.pdf" }
        },
        [_vm._v("DOCUMENTATION ROUTEUR 4G ")]
      )
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("div", { staticClass: "row ml-1 mr-1" }, [
          _c(
            "th",
            { staticClass: "col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6" },
            [_vm._v(" Commandé le ")]
          ),
          _c(
            "th",
            { staticClass: "col-lg-2 col-md-4 col-sm-6 col-xs-6 col-6" },
            [_vm._v(" Description ")]
          ),
          _c(
            "th",
            {
              staticClass:
                "col-lg-2 col-md-4 d-lg-block d-md-block d-sm-none d-xs-none d-none"
            },
            [_vm._v(" Webapp ")]
          ),
          _c(
            "th",
            {
              staticClass:
                "col-lg-3 d-lg-block d-md-none d-sm-none d-xs-none d-none"
            },
            [_vm._v("   ")]
          ),
          _c(
            "th",
            {
              staticClass:
                "col-lg-3 d-lg-block d-md-none d-sm-none d-xs-none d-none"
            },
            [_vm._v("   ")]
          )
        ])
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "col col-lg-1 col-md-4 col-sm-6 col-xs-6 col-6 p-0" },
      [_c("td", [_vm._v("Kit Emotivi")])]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }