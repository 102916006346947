<template>
  <section>
    <h1>Bonjour cher Partenaire</h1>
    <div class="container pb-4">
      <div class="row">
        <div
          class="col-lg-8 flex-1"
          style="margin-top: 50px; margin-left:auto; margin-right:auto"
        >
          <div
            id="login_register_tile"
            class="d-flex flex-column"
            style="background-color: #6ccdb2; padding: 25px 25px; height:100%;"
          >
            <div style="margin-bottom: 15px;">
              <img src="/images/ico-interface.png" alt="" width="10%" />
            </div>
            <h2 style="color: white; font-weight: bold; font-size: 30px;">
              VEUILLEZ VOUS IDENTIFIER
            </h2>
            <div class="form-group">
              <label for="us_login">VOTRE EMAIL</label>
              <input
                type="email"
                class="form-control"
                id="us_login"
                name="us_login"
                v-model="us_login"
                style="border: none; border-radius: 0"
              />
            </div>
            <div class="form-group">
              <label for="us_password">VOTRE MOT DE PASSE</label>
              <input
                type="password"
                class="form-control"
                id="us_password"
                name="us_password"
                v-model="us_password"
                style="border: none; border-radius: 0"
              />
            </div>
            <div class="d-flex justify-content-end" style="margin-top:-10px">
              <a
                @click="resetPassword()"
                href="javascript:void(0);"
                style="color:white;"
              >
                Mot de passe oublié
              </a>
            </div>
            <div
              v-if="dialogErrTxt"
              class="alert alert-danger"
              v-html="dialogErrTxt"
            ></div>

            <div class="flex-grow-1 align-items-center d-flex">
              <button class="btn btn-primary" type="button" @click="login()">
                S'IDENTIFIER
              </button>
            </div>
          </div>
        </div>
        <!-- <button type="button" class="btn btn-primary" data-toggle="modal" data-target="#modalResetPassword">
          Tester modal reset password
        </button> -->
        <div id="modalResetPassword" class="modal fade" role="dialog">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
              <div class="modal-body">
                <div class="row">
                  <div class="col-1">
                    <icon name="check-circle" scale="3"></icon>
                  </div>
                  <div class="col-9 ml-4">
                    Un email vous a été envoyé afin de changer votre mot de
                    passe.
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-center">
                <button class="btn-modal" type="button" data-dismiss="modal">
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
  name: "loginaffiliate",
  components: {},
  metaInfo() {
    return {
      title: "Emotivi - connexion",
      meta: [
        { name: "description", content: "Login for affiliate users" },
        { property: "og:title", content: "Login for affiliate users" },
        { property: "og:site_name", content: "Emotivi" },
        { property: "og:type", content: "website" },
        { name: "robots", content: "noindex" }
      ]
    };
  },
  data() {
    return { us_login: "", us_password: "", dialogErrTxt: "" };
  },
  mounted() {},
  methods: {
    async login() {
      this.dialogErrTxt = "";
      try {
        let response = await this.$axios.post(
          this.$config.server_url + "/web/2.0/affiliate/signin",
          { us_login: this.us_login, us_password: this.us_password }
        );
        if (response.data.err) {
          this.dialogErrTxt =
            "Un problème est survenu. Votre identifiant ou votre mot de passe est incorrect.";

          return;
        }
        this.$store.commit("set_authentification", {
          accesstoken: response.data.data.accesstoken,
          affiliatedUser: response.data.data.row_us
        });

        this.$router.push("/subscription/affiliate");
      } catch (error) {
        console.error("error", error);
        this.dialogErrTxt =
          "Un problème est survenu. Votre identifiant ou votre mot de passe est incorrect.";
      }
    },

    async resetPassword() {
      this.dialogErrTxt = "";
      this.$$("#modalResetPassword").modal("hide");
      if (this.us_login) {
        try {
          let response = await this.$axios.post(
            this.$config.server_url +
              "/web/2.0/signin/affiliate/forgetpassword",
            { us_login: this.us_login }
          );
          if (response.data.err) {
            this.dialogErrTxt =
              "L'adresse email que vous avez rentré n'existe pas.";
            return;
          }
          this.$$("#modalResetPassword").modal("show");
        } catch (err) {
          console.error("error", err);
          this.dialogErrTxt =
            "Un problème est survenu. Veuillez réessayer dans quelques minutes.";
        }
      } else {
        this.dialogErrTxt =
          "Veuillez rentrer votre adresse email pour changer votre mot de passe.";
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.img-fluid {
  width: 100%;
  height: auto;
}
.container2-form-login {
  width: 80%;
}
.links {
  align-items: flex-end;
}
.btn-modal {
  width: 70px;
  height: 30px;
  background-color: #1876eb;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 18px;
  &:hover {
    background-color: #3482e0;
  }
}
</style>
