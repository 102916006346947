<template>
  <section>
    <div
      class="container pb-5 containercolored"
      style="background-color: rgb(24, 155, 176)"
    >
      <div class="row mb-3">
        <div class="col">
          <div class="row align-items-end">
            <!-- <div class="col-md-12 col-lg-5 pt-4">
              <div class="mb-3">
                <img
                  src="/images/ico-interface.png"
                  alt=""
                  style="width: 70px"
                />
              </div>
              <div>
                <h2>COMMANDER UNE CLE 4G</h2>
              </div>
            </div> -->

            <div class="col-4 col-sm-4 col-lg-2 text-center mb-3 mt-2">
              <h4
                class="part-subtitle-private"
                @click="$router.push('/private/account')"
              >
                MON<br />COMPTE
              </h4>
            </div>
            <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/payments')"
            >
              <h4 class="part-subtitle-private">MES<br />PAIEMENTS</h4>
            </div>
            <div
              class="col-4 col-sm-4 col-lg-2 text-center mb-3"
              @click="$router.push('/private/box')"
            >
              <h4 class="part-subtitle-private">MES KITS<br />EMOTIVI</h4>
            </div>
          </div>
        </div>
      </div>
      <div v-if="this.enduserNumber > 1" class="row">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <m-form-select
            :name="$Utils.randomstring('selectEndusers')"
            v-model="en_id"
            label="Pour quel kit Emotivi souhaitez vous utiliser la clé 4G ?"
            :items="selectEndusers"
          ></m-form-select>
        </div>

        <div
          v-if="en_id"
          en_id
          class="col-sm-12 col-md-12 col-lg-6 col-xl-6 d-flex"
        >
          <!-- <button
            class="btn btn-primary mr-3"
            @click="$router.push('/private/infobancaire')"
          >
            Payer par prélévement bancaire
          </button> -->
          <button class="btn btn-primary mr-3" @click="prepareCheckout()">
            PAYER PAR CB
          </button>
        </div>
      </div>
      <div
        class="row justify-content-center"
        v-else-if="this.enduserNumber === 1"
      >
        <div class="spinner-border text-light mr-5" role="status"></div>
        <span class="text-white">Chargement de la page de paiement...</span>
      </div>
    </div>
  </section>
</template>

<script>
import { loadStripe } from "@stripe/stripe-js";

export default {
  name: "privatepurchasekey4g",
  components: {},
  data() {
    return {
      dialogErrTxt: "",
      dialogOkTxt: "",
      row_co: {},
      en_id: 0,

      depositNotPaid: false,
      isLoading: false,
    };
  },
  computed: {
    selectEndusers() {
      return this.$store.state.user.endusers.map((enduser) => {
        return { text: enduser.en_fullname, value: enduser.en_id };
      });
    },
    enduserNumber() {
      return this.$store.state.user.endusers.length;
    },
    getUniqueEnduser() {
      return this.$store.state.user.endusers[0];
    },
  },
  mounted() {
    if (this.enduserNumber === 1) this.en_id = this.getUniqueEnduser.en_id;
    if (this.en_id) this.prepareCheckout();
  },
  // filters: {

  methods: {
    async prepareCheckout() {
      let response = await this.$axios.get(
        this.$config.server_url + "/web/2.0/stripe/purchase/key4g/" + this.en_id
      );
      const stripe = await loadStripe(response.data.data.stripe_apikeypublic);
      stripe
        .redirectToCheckout({
          sessionId: response.data.data.CHECKOUT_SESSION_ID,
        })
        .then(function (result) {});
    },
  },
};
</script>

<style lang="scss" scoped>
section {
  padding-bottom: 50px;
  // padding-top: 40px;
}
h3 {
  color: white;
}
table {
  td {
    color: white;
  }
  th {
    color: white;
  }
}
.btn-modal {
  width: 100px;
  height: 40px;
  background-color: #1876eb;
  border: none;
  color: white;
  border-radius: 5px;
  font-size: 20px;
  margin: 5px;
  &:hover {
    background-color: #3482e0;
  }
}
</style>
